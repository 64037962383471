import { PublicKey } from "@solana/web3.js";
import * as anchor from "@coral-xyz/anchor";
import Publisher from "../bindings/publisher";
import { Dax } from "../dax";
import { keccak_256 } from 'js-sha3';
import { ConnectedSolanaWallet, SignMessageModalUIOptions } from "@privy-io/react-auth";
import Advertiser, { CreativeAsset, CreativeStatus, Language } from "../bindings/advertiser";

// const ROLLUP_URL = 'https://faithful-sashenka-dax-protocol-37ee3c00.koyeb.app';
// const ROLLUP_URL = 'http://localhost:8080';
const ROLLUP_URL = 'https://rollup.adx.so';

const sendTransaction = async (
    data: any,
) => {
    console.log(data);
    const response =await fetch(`${ROLLUP_URL}/submit`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    if (response.ok) {
        const responseData = await response.text();
        console.log(responseData);
    } else {
        throw new Error('Failed to send transaction');
    }
}

const fetchAccount = async (
    account: PublicKey,
) => {
    console.log(account.toBase58());
    const response = await fetch(`${ROLLUP_URL}/account_shared_data/${account.toBase58()}`);
    if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        return responseData;
    } else {
        return null;
    }
}

export const initProperty = async (
    wallet: ConnectedSolanaWallet,
    publisher: Publisher,
    publisher_pubkey: string,
    propertyId: number, 
    url: string,
    nonce: number = 1
) : Promise<string> => {
    const expectedPropertyStateAfterUpdate = {
        publisher: new PublicKey(publisher_pubkey),
        id: propertyId,
        url: url,
        nonce: nonce
    } as anchor.IdlAccounts<Dax>["property"];

    const expectedPropertyStateAfterUpdateBytes = (await publisher.rollupProgram.coder.accounts
        .encode("property", expectedPropertyStateAfterUpdate)).subarray(8); // Remove account discriminator
    const expectedPropertyStateHash = Buffer.from(keccak_256(expectedPropertyStateAfterUpdateBytes), 'hex');
    const provider = await wallet.getProvider();
    const { signature } = await provider.request({
        method: 'signMessage',
        params: {
            message: expectedPropertyStateHash.toString(),
        }
    });
    const publisher_signature = Buffer.from(signature, 'base64').toJSON().data;
    
    const initPropertyTx ={
        "InitProperty": {
            "publisher": new PublicKey(publisher_pubkey).toBuffer().toJSON().data,
            "property_id": propertyId,
            "domain": url,
            "expected_property_state_hash": expectedPropertyStateHash.toJSON().data,
            "publisher_signature": publisher_signature
        }
    };
    await sendTransaction(initPropertyTx);
    return signature;
}

export const initOrUpdateBidderDelegate = async (
    wallet: ConnectedSolanaWallet,
    advertiser: Advertiser,
    delegate: PublicKey,
    revoke: boolean,
) => {
    const bidderDelegatePubkey = advertiser.deriveBidderDelegatePubkey(new PublicKey(wallet.address), delegate);
    const bidderDelegateAccount = await fetchAccount(bidderDelegatePubkey);
    console.log(bidderDelegateAccount);
    let nonce = 1;
    if (bidderDelegateAccount && bidderDelegateAccount.data.length > 0) {
        const bidderDelegate = advertiser.rollupProgram.coder.accounts.decode("bidderDelegate", Buffer.from(bidderDelegateAccount.data));
        nonce = bidderDelegate.nonce + 1
    }
    console.log(nonce);
      const expectedStateAfterUpdate = {
          advertiser: new PublicKey(wallet.address),
          delegate: revoke ? anchor.web3.SystemProgram.programId : delegate,
          nonce: nonce
      } as anchor.IdlAccounts<Dax>["bidderDelegate"];
      console.log(expectedStateAfterUpdate);
      const expectedStateAfterUpdateBytes = (await advertiser.rollupProgram.coder.accounts
          .encode("bidderDelegate", expectedStateAfterUpdate)).subarray(8); // Remove account discriminator
      const expectedStateHash = Buffer.from(keccak_256(expectedStateAfterUpdateBytes), 'hex');
      const provider = await wallet.getProvider();
      const { signature } = await provider.request({
        method: 'signMessage',
        params: {
            message: expectedStateHash.toString(),
        }
    });
    const advertiser_signature = Buffer.from(signature, 'base64').toJSON().data;

    const initBidderDelegateTx = {
        "InitBidderDelegate": {
            "advertiser": new PublicKey(wallet.address).toBuffer().toJSON().data,
            "delegate": delegate.toBuffer().toJSON().data,
            "expected_bidder_delegate_state_hash": expectedStateHash.toJSON().data,
            "advertiser_signature": advertiser_signature
        }
    };
    await sendTransaction(initBidderDelegateTx);
    return signature;
}

export const initOrUpdateCreative = async (
    wallet: ConnectedSolanaWallet,
    advertiser: Advertiser,
    creativeId: number,
    asset: CreativeAsset,
    status: CreativeStatus,
    ctaUrl: string,
    languages: Language[],
) => {
    const creativePubkey = advertiser.deriveCreativePubkey(new PublicKey(wallet.address), creativeId);
    const creativeAccount = await fetchAccount(creativePubkey);
    let nonce = 1;
    if (creativeAccount && creativeAccount.data.length > 0) {
        const creative = advertiser.rollupProgram.coder.accounts.decode("creative", Buffer.from(creativeAccount.data));
        console.log(creative);
        nonce = creative.nonce + 1
    }
    console.log(nonce);

    const noncebytes = new anchor.BN(nonce!).toArrayLike(Buffer, "le", 2);
    // const nonceSignature = Buffer.from(
    //       nacl.sign.detached(
    //         noncebytes, 
    //         this.keypair.secretKey
    //   )
    // );
    // return [noncebytes, nonceSignature]
    const provider = await wallet.getProvider();
    const { signature } = await provider.request({
        method: 'signMessage',
        params: {
            message: noncebytes.toString(),
        }
    });
    const nonceSignature = Buffer.from(signature, 'base64').toJSON().data;
    let statusString = "Active";
    if ('uninitialized' in status) {
        statusString = "Uninitialized";
    } else if ('active' in status) {
        statusString = "Active";
    } else if ('suspended' in status) {
        statusString = "Suspended";
    }

    const initOrUpdateCreativeTx = {
        "InitOrUpdateCreative": {
            "advertiser": new PublicKey(wallet.address).toBuffer().toJSON().data,
            "nonce_bytes": noncebytes.toJSON().data,
            "nonce_signature": nonceSignature,
            "creative_id": creativeId,
            "status": statusString,
            "asset": asset,
            "cta_url": ctaUrl,
            "languages": ["En", "Cn"]
        }
    }
    console.log(JSON.stringify(initOrUpdateCreativeTx));
    await sendTransaction(initOrUpdateCreativeTx);
    return signature;
}

export const initOrUpdateCampaign = async (
    wallet: ConnectedSolanaWallet,
    advertiser: Advertiser,
    campaignData: any,
    campaignId: number,
) => {
    console.log(campaignData, campaignId);
    const campaignPubkey = advertiser.deriveCampaignPubkey(new PublicKey(wallet.address), campaignId);
    const campaignAccount = await fetchAccount(campaignPubkey);
    let nonce = 1;
    if (campaignAccount && campaignAccount.data.length > 0) {
        const campaign = advertiser.rollupProgram.coder.accounts.decode("campaign", Buffer.from(campaignAccount.data));
        nonce = campaign.nonce + 1
    }
    console.log(nonce);

    const noncebytes = new anchor.BN(nonce!).toArrayLike(Buffer, "le", 2);
    const provider = await wallet.getProvider();
    const { signature } = await provider.request({
        method: 'signMessage',
        params: {
            message: noncebytes.toString(),
        }
    });
    const nonceSignature = Buffer.from(signature, 'base64').toJSON().data;

    let objectiveString = null;
    if (campaignData.objective) {
        if ('undefined' in campaignData.objective) {
            objectiveString = "Undefined";
        } else if ('impressions' in campaignData.objective) {
            objectiveString = "Impressions";
        } else if ('conversions' in campaignData.objective) {
            objectiveString = "Conversions";
        } 
    }
    console.log(objectiveString);

    let statusString = null;
    if (campaignData.status) {
        if ('uninitialized' in campaignData.status) {
            statusString = "Uninitialized";
        } else if ('active' in campaignData.status) {
            statusString = "Active";
        } else if ('paused' in campaignData.status) {
            statusString = "Paused";
        } else if ('completed' in campaignData.status) {
            statusString = "Completed";
        } else if ('cancelled' in campaignData.status) {
            statusString = "Cancelled";
        }
    }
    console.log(statusString);

    let budget = null;
    if (campaignData.budget) {
        if ('daily' in campaignData.budget) {
            budget = {
                "Daily": {
                    "max_spend_per_day": campaignData.budget.daily.maxSpendPerDay.toNumber()
                }
            }
        } else if ('total' in campaignData.budget) {
            budget = {
                "Total": {
                    "campaign_total": campaignData.budget.total.campaignTotal
                }
            }
        }
    }
    console.log(budget);

    let bidding = null;
    if (campaignData.bidding) {
        if ('undefined' in campaignData.bidding) {
            bidding = "Undefined";
        } else if ('costPerImpression' in campaignData.bidding) {
            bidding = {
                "CostPerImpression": {
                    "amount": campaignData.bidding.costPerImpression.amount.toNumber(),
                }
            }
        } 
    }
    console.log(bidding);

    const imageCreatives = (campaignData.imageCreatives || []).map((c: any) => new PublicKey(c).toBuffer().toJSON().data);
    const videoCreatives = (campaignData.videoCreatives || []).map((c: any) => new PublicKey(c).toBuffer().toJSON().data);
    const copyCreatives = (campaignData.copyCreatives || []).map((c: any) => new PublicKey(c).toBuffer().toJSON().data);

    const initOrUpdateCampaignTx = {
        "InitOrUpdateCampaign": {   
            "advertiser": new PublicKey(wallet.address).toBuffer().toJSON().data,
            "nonce_bytes": noncebytes.toJSON().data,
            "nonce_signature": nonceSignature,
            "campaign_id": campaignId,
            "name": campaignData.name || null,
            "objective": objectiveString || null,
            "status": statusString || null,
            "start_timestamp": campaignData.start_timestamp || null,
            "end_timestamp": campaignData.end_timestamp || null,
            "budget": budget || null,
            "bidding": bidding || null,
            "age_range": campaignData.age_range || null,
            "net_worth_range": campaignData.net_worth_range || null,
            "income_range": campaignData.income_range || null,
            "gender_inclusions": campaignData.gender_inclusions || null,
            "gender_exclusions": campaignData.gender_exclusions || null,
            "device_type_inclusions": campaignData.device_type_inclusions || null,
            "device_type_exclusions": campaignData.device_type_exclusions || null,
            "category_inclusions": campaignData.category_inclusions || null,
            "category_exclusions": campaignData.category_exclusions || null,
            "keyword_inclusions": campaignData.keyword_inclusions || null,
            "keyword_exclusions": campaignData.keyword_exclusions || null,
            "image_creatives": imageCreatives || null,
            "video_creatives": videoCreatives || null,
            "copy_creatives": copyCreatives || null,
        }
    }
    console.log(JSON.stringify(initOrUpdateCampaignTx));
    await sendTransaction(initOrUpdateCampaignTx);
    return signature;
}