import React from 'react';
import MacOSWindowStack from './MacOSWindowStack';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

const Explorer: React.FC = () => {
  const windows = [
    {
      heading: "AdX Explorer",
      content: <img className="w-full h-full object-cover" src="screen-explorer.png" alt="AdX Explorer" />,
      width: '70%',
      height: '70%',
      top: '0%',
      left: '0%',
      zIndex: 2
    },
    {
      heading: "Solana Explorer",
      content: <img className="w-full h-full object-cover" src="screen-solana-explorer.png" alt="Solana Explorer" />,
      width: '70%',
      height: '60%',
      top: '30%',
      left: '30%',
      zIndex: 1
    }
  ];

  return (
    <div className="flex flex-col lg:flex-row w-full h-full gap-8 md:gap-5">
      {/* Second column (2/3 width on desktop, full width and first on mobile) */}
      <div className="w-full lg:w-2/3 h-full flex items-center justify-center order-1 md:order-2">
        <div className="w-full h-full">
          <MacOSWindowStack windows={windows} />
        </div>
      </div>

      {/* First column (1/3 width on desktop, full width and second on mobile) */}
      <div className="w-full lg:w-1/3 flex flex-col justify-center pr-8 order-2 md:order-1 mt-8 lg:mt-0">
        <h2 className="text-4xl md:text-5xl font-medium mb-4">Transparency & Auditability</h2>
        <div className="text-lg lg:text-xl whitespace-pre-line opacity-80 space-y-3">
          <p>
            Every auction and real-time bid can be verified on the AdX Explorer. 
          </p>
          <p>
            Although real-time auction bidding is encrypted, anyone can validate that the process has been carried out on the AdX explorer.
          </p>
          <p>
            View and validate activity on the AdX SVM, and validate settlement back to the Solana L1.
          </p>
        </div>
        <div className="mt-4">
            <a 
                href="/dashboard" 
                target="_blank" 
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 border border-white text-sm font-medium rounded-md text-white hover:bg-white-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Explorer
                <ArrowUpRightIcon className="ml-2 -mr-1 h-4 w-4" />
            </a>
        </div>
      </div>
      
    </div>
  );
};

export default Explorer;