import React, { useCallback, useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getPlacementsWithAuction, PlacementWithAuction } from '../../../supabase';

// // Dummy data for the bar chart
// const chartData = [
//   { block: '8901230', transactions: 150 },
//   { block: '8901231', transactions: 200 },
//   { block: '8901232', transactions: 180 },
//   { block: '8901233', transactions: 220 },
//   { block: '8901234', transactions: 190 },
//   { block: '8901235', transactions: 150 },
//   { block: '8901236', transactions: 200 },
//   { block: '8901237', transactions: 180 },
//   { block: '8901238', transactions: 220 },
//   { block: '8901239', transactions: 190 },
// ];


const ExplorerBarChart: React.FC = () => {

  const [chartData, setChartData] = useState<{ hour: string; count: number }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string|null>(null);

  const fetchRecords = useCallback(async () => {
    // if (!rollupNode) return;
    setLoading(true);
    
    try {
      // const fetchedRecords = await rollupNode.fetchRecentCommittedPlacementRecord();
      const fetchedRecords = await getPlacementsWithAuction({});
      console.log(fetchedRecords);

      // Aggregate records by hour
      const aggregatedData = fetchedRecords.reduce((acc, record) => {
        const date = new Date(record.created_at);
        const hour = date.getHours();
        const key = `${date.toDateString()} ${hour}:00`;
        
        if (!acc[key]) {
          acc[key] = { hour: key, count: 0 };
        }
        acc[key].count++;
        return acc;
      }, {} as Record<string, { hour: string; count: number }>);

      // Convert the aggregated data to an array and sort by hour
      const chartData = Object.values(aggregatedData).sort((a, b) => 
        new Date(a.hour).getTime() - new Date(b.hour).getTime()
      );

      // Update the state with the new chart data
      setChartData(chartData);
    } catch (err) {
      setError('Failed to fetch records');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);
  
  return (
   
    <div className="bg-white dark:bg-gray-900 dark:text-white  dark:bg-gray-800 p-4 rounded-lg shadow">
      <h2 className="text-xl font-semibold mb-4 dark:text-white">
        Placement Statistics
      </h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="hour" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#3B82F6" />
        </BarChart>
      </ResponsiveContainer>
    </div>


  );
};

export default ExplorerBarChart;