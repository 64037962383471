import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { NavLink, useLocation, Link} from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import Heading from '../Heading';
import { useAdvertiser } from '../../../context/AdvertiserContext';
import CampaignTable from './CampaignTable';
import MultiSelectTest from './MultiSelectTest';
import Alert from '../../common/Alert';
import { getBidderDelegates, getCampaigns } from '../../../supabase';
import { PublicKey } from '@solana/web3.js';
import { useSolanaWallets } from '@privy-io/react-auth';

export default function Campaigns() {

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  // const { advertiser } = useAdvertiser();
  const { wallets } = useSolanaWallets();
  const wallet = wallets[0];

  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [warnNoDelegates, setWarnNoDelegates] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchCampaigns = useCallback(async () => {
    if (!wallet || !wallet.address) {
        return;
        setCampaigns([])
        setIsLoading(false)
    }
    try {
      console.log('Getting campaigns...')
      // const ps = await advertiser.fetchAllCampaigns();
      const ps = await getCampaigns({ advertiser: wallet.address });
      setCampaigns(ps);
      setIsLoading(false)
      console.log(ps);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setCampaigns([])
      setIsLoading(false)
    }
  }, [wallet]);

  const checkHasDelegates = useCallback(async () => {
    if (!wallet || !wallet.address) {
        return;
        setWarnNoDelegates(false)
    }
    try {
      console.log('Getting campaigns...')
      // const ds = await advertiser.fetchAllDelegates();
      const ds = await getBidderDelegates({ advertiser: wallet.address });
      console.log(ds);
      if (ds.length > 0 ) {
        setWarnNoDelegates(false)
      } else {
        setWarnNoDelegates(true)
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setWarnNoDelegates(false)
    }
  }, [wallet]);

  useEffect(() => {
    if (wallet && wallet.address) {
      fetchCampaigns();
      checkHasDelegates();
    } 
  }, [wallet]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (advertiserPubKey) {
  //       fetchCampaigns();
  //     }
  //   }, 15000); // Fetch every 15 seconds
  
  //   return () => clearInterval(intervalId);
  // }, [advertiserPubKey, fetchCampaigns]);

  
  return (
    <div>
      <Heading pageHeadingName='Campaigns'>
          <Link to="/dashboard/campaigns/new" className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">New</Link>
      </Heading>
      {
        warnNoDelegates ? (
            <Alert
              type="error"
              title="No Delegate Bidder"
              message={<>
                <p>It appears that no bidder delegates have been set up for this account.</p>
                <p>Campaigns specify the criteria and rules for bidding on available placements, but still require
                the bids to be placed</p>
              </>}
              ctaText="Add Delegate"
              ctaUrl="/dashboard/delegates"
              dismissible
            />
        ):("")
      }
      <CampaignTable campaigns={campaigns} isLoading={isLoading} />
      {/* <MultiSelectTest /> */}
    </div>
  )
}
