import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import InfoLayout, { About, FAQ, Contact } from './InfoLayout';
 



const InfoRoutes: React.FC = () => (
  <Routes>
    <Route element={<InfoLayout />}>
      <Route index element={<Navigate to="about" replace />} />
      <Route path="about" element={<About />} />
      <Route path="faq" element={<FAQ />} />
      <Route path="contact" element={<Contact />} />
    </Route>
  </Routes>
);

export default InfoRoutes;