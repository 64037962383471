import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAdvertiser } from '../../../context/AdvertiserContext';
import { useToast } from '../../../context/ToastContext';
import Heading from '../Heading';
import CampaignSteps from './CampaignSteps';
import ExistingCampaignInfoStep from './ExistingCampaignInfoStep';
import BudgetStep from './BudgetStep';
import UserTargetingStep from './UserTargetingStep';
import PlacementTargetingStep from './PlacementTargetingStep';
import CreativesStep from './CreativesStep';
import NewCampaignBasicInfoStep from './NewCampaignBasicInfoStep';
import { getCampaigns } from '../../../supabase';
import { PublicKey } from '@solana/web3.js';
import { useSolanaWallets } from '@privy-io/react-auth';
import { initOrUpdateCampaign } from '../../../rollup';


interface Step {
  id: string;
  name: string;
  status: 'complete' | 'current' | 'upcoming';
}

const initialSteps: Step[] = [
  { id: '1', name: 'Basic Info', status: 'current' },
  { id: '2', name: 'Budget & Bidding', status: 'upcoming' },
  { id: '3', name: 'User Targeting', status: 'upcoming' },
  { id: '4', name: 'Placement Targeting', status: 'upcoming' },
  { id: '5', name: 'Creatives', status: 'upcoming' },
];

  
const CampaignForm: React.FC = () => {
  const { campaignId } = useParams<{ campaignId?: string }>();
  console.log(campaignId);
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState<Step[]>(initialSteps);
  const [campaignData, setCampaignData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(!!campaignId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { advertiser } = useAdvertiser();
  const { showToast } = useToast();
  const navigate = useNavigate();

  const { wallets } = useSolanaWallets();
  const wallet = wallets[0];

  useEffect(() => {
    const fetchOrInitCampaignData = async () => {
      if (!wallet || !wallet.address) {
        showToast('Advertiser context not available', 'error');
        setIsLoading(false);
        return;
      }

      try {
        if (campaignId) {
          // Existing campaign
          // const data = await advertiser.fetchCampaignById(Number(campaignId));
          const data = (await getCampaigns({ id: campaignId }))[0];
          console.log(data);
          if (data) {
            setCampaignData(data);
          } else {
            showToast(`Campaign with ID ${campaignId} not found`, 'error');
            navigate('/campaigns'); // Redirect to campaigns list
          }
        } else {

          const campaigns = await getCampaigns({ advertiser: wallet.address });
          console.log(campaigns);
          const new_campaign_id = campaigns.length + 1;
          setCampaignData({ 
            campaign_id: new_campaign_id,
            // Initialize other fields with default values
            name: '',
            status: { uninitialized: {} },
            objective: { undefined: {} },
            // Add other necessary fields with default values
          });
          // // New campaign
          // const newCampaignId = await advertiser.getNextCampaignIdx();
          // console.log('newCampaignId', newCampaignId)
          // setCampaignData({ 
          //   campaignId: newCampaignId,
          //   // Initialize other fields with default values
          //   name: '',
          //   status: { uninitialized: {} },
          //   objective: { undefined: {} },
          //   // Add other necessary fields with default values
          // });
        }
      } catch (error) {
        showToast(`Error initializing campaign data: ${(error as Error).message}`, 'error');
        navigate('/campaigns'); // Redirect to campaigns list on error
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrInitCampaignData();
  }, [wallet, campaignId]);

  const getExplorerUrl = (txSignature: string) => {
    const cluster = 'devnet'; // Change this based on your network
    return `https://explorer.solana.com/tx/${txSignature}?cluster=${cluster}`;
  };

  const handleStepSubmit = async (stepData: any) => {
    console.log(campaignData);
    if (!wallet || !wallet.address || !campaignData?.campaign_id || !advertiser) {
      showToast('Unable to update campaign. Please try again.', 'error');
      return;
    }

    // If stepData is empty, it means no changes were made, so just move to the next step
    if (Object.keys(stepData).length === 0) {
      moveToNextStep();
      return;
    }

    setIsSubmitting(true);

    try {
      // const tx = await advertiser.initOrUpdateCampaign(campaignData.campaignId, stepData);
      
      // // Wait for transaction confirmation
      // await advertiser.baseProgram.provider.connection.confirmTransaction({
      //   signature: tx,
      //   blockhash: await advertiser.baseProgram.provider.connection.getLatestBlockhash().then(res => res.blockhash),
      //   lastValidBlockHeight: await advertiser.baseProgram.provider.connection.getLatestBlockhash().then(res => res.lastValidBlockHeight),
      // });

      const tx = await initOrUpdateCampaign(wallet, advertiser, stepData, campaignData.campaign_id);

      showToast(`Step ${currentStep} updated successfully`, 'success');
      
      // Move to the next step after successful confirmation
      moveToNextStep();
    } catch (error) {
      showToast(`Error updating campaign: ${(error as Error).message}`, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const moveToNextStep = () => {
    const nextStep = Math.min(currentStep + 1, steps.length);
    setCurrentStep(nextStep);
    updateSteps(nextStep);
  };

  const updateSteps = (completedStep: number) => {
    setSteps(prevSteps =>
      prevSteps.map(step => {
        const stepNumber = parseInt(step.id);
        if (stepNumber < completedStep) {
          return { ...step, status: 'complete' as const };
        } else if (stepNumber === completedStep) {
          return { ...step, status: 'current' as const };
        }
        return { ...step, status: 'upcoming' as const };
      })
    );
  };

  const renderStep = () => {
    if (isLoading) {
      return <div>Loading campaign data...</div>;
    }

    const commonProps = {
      onSubmit: handleStepSubmit,
      initialData: campaignData,
      isSubmitting,
    };

    switch (currentStep) {
      case 1:
        return campaignId 
          ? <ExistingCampaignInfoStep {...commonProps} /> 
          : <NewCampaignBasicInfoStep {...commonProps} />;
      case 2:
        return <BudgetStep {...commonProps} />;
      case 3:
        return <UserTargetingStep {...commonProps} />;
      case 4:
        return <PlacementTargetingStep {...commonProps} />;
      case 5:
        return <CreativesStep {...commonProps} />;
      default:
        return null;
    }
  };

  const handleStepClick = (stepId: string) => {
    const clickedStep = parseInt(stepId);
    setCurrentStep(clickedStep);
    updateSteps(clickedStep);
  };
    

  return (
    <>      
      <Heading pageHeadingName={campaignId ? `Manage Campaign ${campaignId}` : "New Campaign"} />
      <div className="mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
          <CampaignSteps
            steps={steps}
            currentStep={currentStep}
            onStepClick={handleStepClick}
            isNewCampaign={!campaignId}
          />
          {renderStep()}
      </div>
    </>
    
  );
};

export default CampaignForm;
