import React, { useEffect, useState, useCallback } from 'react';
import { useRollupNode } from '../../../context/RollupNodeContext';
import { useNavigate } from 'react-router-dom';
import * as anchor from '@coral-xyz/anchor';
import { getPlacementsWithAuction, PlacementWithAuction } from '../../../supabase';

const TypeBadge: React.FC<{ type: string }> = ({ type }) => {
  const colorClass = {
    'Auction': 'bg-blue-200 text-blue-800 dark:bg-blue-900 dark:text-blue-200',
    'Bid': 'bg-green-200 text-green-800 dark:bg-green-900 dark:text-green-200',
    'Placement': 'bg-yellow-200 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200',
    'Served': 'bg-purple-200 text-purple-800 dark:bg-purple-900 dark:text-purple-200',
  }[type] || 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200';

  return (
    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${colorClass}`}>
      {type}
    </span>
  );
};

const StatusBadge: React.FC<{ status: string }> = ({ status }) => {
  const colorClass = status === 'Confirmed' 
    ? 'bg-green-200 text-green-800 dark:bg-green-900 dark:text-green-200' 
    : 'bg-red-200 text-red-800 dark:bg-red-900 dark:text-red-200';
  return (
    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${colorClass}`}>
      {status}
    </span>
  );
};

const SkeletonRow: React.FC = () => (
  <tr>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4 animate-pulse"></div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2 animate-pulse"></div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4 animate-pulse"></div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/4 animate-pulse"></div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2 animate-pulse"></div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4 animate-pulse"></div>
    </td>
  </tr>
);

const EmptyState: React.FC = () => (
  <tr>
    <td colSpan={6} className="px-6 py-12 text-center">
      <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path vectorEffect="non-scaling-stroke" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-200">No placements</h3>
      <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">No recent placements have been recorded.</p>
    </td>
  </tr>
);


const ExplorerRecentPlacements: React.FC<{ accountFilter?: string }> = ({ accountFilter }) => {

  const { rollupNode } = useRollupNode();
  const [records, setRecords] = useState<PlacementWithAuction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string|null>(null);
  const navigate = useNavigate();

  const fetchRecords = useCallback(async () => {
    // if (!rollupNode) return;
    
    try {
      setLoading(true);
      // const fetchedRecords = await rollupNode.fetchRecentCommittedPlacementRecord();
      const fetchedRecords = await getPlacementsWithAuction({});
      console.log(fetchedRecords);
      // Filter placements that are confirmed or created in the last 5 minutes
      const fiveMinutesAgo = new Date(Date.now() - 5 * 60 * 1000);
      const filteredRecords = fetchedRecords.filter(record => 
        (record.auction.status === 2 || new Date(record.created_at) > fiveMinutesAgo) && record.auction.bid_value > 0
      );
      setRecords(filteredRecords);
    } catch (err) {
      setError('Failed to fetch records');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [rollupNode]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  const handleRowClick = (signature: string) => {
    navigate(`/dashboard/explorer/placement/${signature}`);
  };

  const truncate = (signature: string) => {
    return signature.slice(1, 6) + "..." + signature.slice(-6)
  };

  return (
    <div className="dark:bg-gray-900 dark:text-white rounded-lg shadow overflow-x-auto">
      <h2 className="text-xl font-semibold mb-4 p-4 dark:text-white">Recent Placements</h2>
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        <thead className="bg-gray-50 dark:bg-gray-800">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Transaction</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Placement</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Signer</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Value</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Status</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Timestamp</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700">
          {loading ? (
            <>
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
            </>
          ) : error ? (
            <tr>
              <td colSpan={6} className="px-6 py-4 text-center text-red-500">{error}</td>
            </tr>
          ) : records.length === 0 ? (
            <EmptyState />
          ) : (
            records.map((record, index) => (
              <tr 
                key={record.id}
                onClick={() => handleRowClick(record.id)}
                className="hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150 ease-in-out cursor-pointer"
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-500">
                  {truncate(record.auction.signature || record.id)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-500">
                  {truncate(record.id)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                  <TypeBadge type="Placement" />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                  {Number(record.auction.bid_value)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                  {record.auction.status === 2 ? <StatusBadge status="Confirmed" /> : <StatusBadge status="Pending" />}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                  <div>{new Date(record.created_at).toLocaleString()}</div>
                  <div className="text-xs text-gray-400">{/* You can add relative time here */}</div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ExplorerRecentPlacements;