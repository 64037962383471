import React from 'react';

interface ProgressBarProps {
    currentSection: number;
    totalSections: number;
    onDotClick: (index: number) => void;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentSection, totalSections, onDotClick }) => {
    return (
        <div className="flex flex-col items-center space-y-2">
            {Array.from({ length: totalSections+1 }).map((_, index) => (
                <button
                    key={index}
                    onClick={() => onDotClick(index)}
                    className={`w-3 h-3 rounded-full transition-all duration-300  ${
                        index === currentSection ? 'bg-white scale-155' : 'border-2 border-white bg-transparent hover:bg-gray-300'
                    }`}
                    aria-label={`Go to section ${index + 1}`}
                />
            ))}
        </div>
    );
};

export default ProgressBar;