import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  HomeIcon,
  CogIcon,
  MagnifyingGlassIcon,
  PresentationChartLineIcon,
  FlagIcon,
  BuildingStorefrontIcon,
  DocumentArrowUpIcon,
  UserPlusIcon,
  PaintBrushIcon,
  
} from '@heroicons/react/24/outline'
import Profile from './Profile';
import ThemeToggle from './ThemeToggle';

const mainNavigation = [
  { name: 'Explorer', href: '/dashboard/explorer', icon: MagnifyingGlassIcon },
  { name: 'Overview', href: '/dashboard/overview', icon: HomeIcon },
  { name: 'Properties', href: '/dashboard/properties', icon: BuildingStorefrontIcon },
  { name: 'Campaigns', href: '/dashboard/campaigns', icon: FlagIcon },
  { name: 'Creatives', href: '/dashboard/creatives', icon: PaintBrushIcon },
  { name: 'Delegates', href: '/dashboard/delegates', icon: UserPlusIcon },
  { name: 'Help', href: 'https://gitbook.com', icon: DocumentArrowUpIcon },
];

// const secondaryNavigation = [

  
// ];

export default function Sidebar() {
  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-gray-900 px-6 transition-colors duration-200">
      <div className="flex h-16 shrink-0 items-center">
        <h1 className="font-black text-3xl text-indigo-500 dark:text-indigo-400">
          AdX.so
        </h1>
      </div>

      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col ">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {mainNavigation.map((item) => (
                <li key={item.name}>
                  <NavLink
                    target={item.href.startsWith("http") ? "_blank" : ""}
                    to={item.href}
                    className={({ isActive }) =>
                      `group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 transition-colors duration-150 ${
                        isActive
                          ? 'bg-gray-100 text-indigo-600 dark:bg-gray-800 dark:text-indigo-400'
                          : 'text-gray-700 hover:bg-gray-100 hover:text-indigo-600 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-indigo-400'
                      }`
                    }
                  >
                    <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </li>

          {/* <li className="mt-auto">
            <ul role="list" className="space-y-1">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <NavLink
                    to={item.href}
                    className={({ isActive }) =>
                      `group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 transition-colors duration-150 ${
                        isActive
                          ? 'bg-gray-100 text-indigo-600 dark:bg-gray-800 dark:text-indigo-400'
                          : 'text-gray-700 hover:bg-gray-100 hover:text-indigo-600 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-indigo-400'
                      }`
                    }
                  >
                    <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </li> */}

          <li className="-mx-6 mt-auto">
            <div className="flex items-center lg:px-6 py-3 text-sm">
                <ThemeToggle />
            </div>
          </li>

          <li className="-mx-6 hidden lg:block">
            <Profile />
          </li>
        </ul>
      </nav>
    </div>
  );
}