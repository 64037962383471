import React, { useState, useEffect, useCallback } from 'react';
import { CheckIcon, ChevronRightIcon, ExclamationCircleIcon, PlusIcon } from '@heroicons/react/20/solid';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { Transition, Dialog } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import Heading from '../Heading';
import { usePublisher } from '../../../context/PublisherContext';
import { useToast } from '../../../context/ToastContext';
import { usePrivy, useSolanaWallets } from '@privy-io/react-auth';
import { initProperty } from '../../../rollup';
import { getProperties } from '../../../supabase';


interface Step {
  id: string;
  name: string;
  status: 'complete' | 'current' | 'upcoming';
}

interface WebsiteData {
  title: string;
  categories: string[];
  propertyId: number;
}

const steps: Step[] = [
  { id: '1', name: 'Website URL', status: 'current' },
  { id: '2', name: 'Integration', status: 'upcoming' },
  { id: '3', name: 'Review', status: 'upcoming' },
];

const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [websiteData, setWebsiteData] = useState<WebsiteData | null>(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [websiteTitle, setWebsiteTitle] = useState('');
  const navigate = useNavigate(); // Assuming you're using react-router for navigation
  const [isInitializingProperty, setIsInitializingProperty] = useState(false);
  const [propertyId, setPropertyId] = useState<number | null>(null);
  const { showToast } = useToast();

  const { publisher } = usePublisher();
  const {wallets} = useSolanaWallets();
  const wallet = wallets[0]; 
  const {signMessage} = usePrivy();

  const getExplorerUrl = (txSignature: string) => {
    const cluster = 'devnet';
    return `https://explorer.solana.com/tx/${txSignature}?cluster=${cluster}`;
  };

  
  // useEffect(() => {
  //   if (publisher) {
  //     publisher.getNextPropertyIdx().then((i) => {setPropertyId(i)});
  //   } 
  // }, [publisher]);

  const fetchProperties = useCallback(async () => {
    if (!wallet || !wallet.address) {
        return;
        setPropertyId(1)
        setIsLoading(false)
    }
    try {
      console.log('Getting properties...')
      // const ps = await publisher.fetchAllProperties();
      const ps = await getProperties({publisher: wallet.address});
      setPropertyId(ps.length + 1)
      console.log(ps);
    } catch (error) {
      console.error('Error fetching properties:', error);
      setPropertyId(1)
      setIsLoading(false)
    }
  }, [wallet]);

  useEffect(() => {
    if (wallet && wallet.address) {
      fetchProperties();
    } 
  }, [wallet]);

  const initializeProperty = async () => {
    console.log('initializeProperty')
    setIsInitializingProperty(true);
    setError('');
  

    if (!publisher || !wallet || !wallet.address || propertyId==undefined || !url) {
      console.log(wallet, propertyId, url)
      setError("Missing required information to initialize property.");
      setIsInitializingProperty(false);
      return;
    }
  
    try {
      const txSignature = await initProperty(wallet, publisher, wallet.address, propertyId, url);
      // const txSignature = await publisher.initProperty(propertyId, url);
      
      // // Wait for transaction confirmation
      // await publisher.baseProgram.provider.connection.confirmTransaction(txSignature, "processed");

      // const txSignature = '1234567890';
      const explorerLink = getExplorerUrl(txSignature);
      showToast(
        <>
          Successfully initialized property with ID {propertyId}. 
          <a href={explorerLink} target="_blank" rel="noopener noreferrer" className="underline">
            View transaction
          </a>
        </>, 
        'success'
      );
  
      // You might want to refresh some data here
      // await refreshPropertyData();
  
      setCurrentStep(3)
    } catch (error: any) {
      console.error("Property initialization error:", error);
  
      if (error.message.includes("Simulated")) {
        showToast(`Property initialization simulation failed: ${error.message}`, 'error');
      } else if (error.signature) {
        const explorerLink = getExplorerUrl(error.signature);
        showToast(
          <>
            Property initialization failed. 
            <a href={explorerLink} target="_blank" rel="noopener noreferrer" className="underline">
              View error details
            </a>
          </>, 
          'error'
        );
      } else {
        showToast(`Property initialization failed: ${error.message}`, 'error');
      }
  
      setError("Couldn't initialize property on the blockchain. Please try again.");
    } finally {
      setIsInitializingProperty(false);
    }
  };

  const handleFinalSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    navigate('/dashboard/properties');
  };


  const updateSteps = (completedStep: number) => {
    return steps.map((step) => {
      const stepNumber = parseInt(step.id);
      if (stepNumber < completedStep) {
        return { ...step, status: 'complete' as const };
      } else if (stepNumber === completedStep) {
        return { ...step, status: 'current' as const };
      }
      return { ...step, status: 'upcoming' as const };
    });
  };

  const validateUrl = (input: string) => {
    const regex = /^[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
    return regex.test(input);
  };

  useEffect(() => {
    if (url) {
      if (validateUrl(url)) {
        setError('');
      } else {
        setError('Please enter a valid top-level domain (e.g., example.com)');
      }
    } else {
      setError('');
    }
  }, [url]);

  const fetchWebsiteData = async () => {
    setIsLoading(true);
    setError('');
    try {
      // Simulating API call to fetch actual website title
      // const response = await fetch(`https://api.example.com/fetch-title?url=${encodeURIComponent(url)}`);
      // const data = await response.json();
      setWebsiteTitle(url);
      setWebsiteData({
        title: url,
        categories: ['Technology', 'Blog'], // This could also come from the API
        propertyId: propertyId ?? 0
      });
      setCurrentStep(2);
    } catch (err) {
      setError("Couldn't fetch website data. Please check the URL and try again.");
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateUrl(url)) {
      fetchWebsiteData();
    }
  };



  const copyToClipboard = () => {
    const snippet = `<script>
  // Your JavaScript code here
</script>`;
    navigator.clipboard.writeText(snippet);
  };

  const verifyEmbedding = async () => {
    setIsModalOpen(true);
    setIsLoading(true);
    try {
      // Simulating verification process
      await new Promise(resolve => setTimeout(resolve, 3000));
      setIsVerified(true);
      setCurrentStep(4);  // Change this from 3 to 4 to go to the review step
    } catch (err) {
      setError("Couldn't verify the embedding. Please try again.");
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const addCategory = () => {
    if (newCategory && websiteData) {
      setWebsiteData({
        ...websiteData,
        categories: [...websiteData.categories, newCategory]
      });
      setNewCategory('');
    }
  };

  const PropertyNewSteps: React.FC<{ steps: Step[] }> = ({ steps }) => {
    return (
      <nav aria-label="Progress" className="mb-8">
        <ol role="list" className="divide-y divide-gray-300 dark:divide-gray-600 rounded-md border border-gray-300 dark:border-gray-600 md:flex md:divide-y-0">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative md:flex md:flex-1">
              {step.status === 'complete' ? (
                <div className="group flex w-full items-center">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                      <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-200">{step.name}</span>
                  </span>
                </div>
              ) : step.status === 'current' ? (
                <div aria-current="step" className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600 dark:border-indigo-500 ">
                    <span className="text-indigo-600 dark:text-indigo-500 ">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-indigo-600 dark:text-indigo-500 ">{step.name}</span>
                </div>
              ) : (
                <div className="group flex items-center">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 dark:border-gray-700 group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-gray-200">{step.id}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-gray-200">{step.name}</span>
                  </span>
                </div>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                    <svg
                      className="h-full w-full text-gray-300 dark:text-gray-600"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
    );
  };


  return (
    <>
          <Heading pageHeadingName='New Property'/>

      <div className="mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
        <PropertyNewSteps steps={updateSteps(currentStep)} />
        
        {currentStep === 1 && (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="url" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Website URL
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="text"
                  id="url"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  className={`block w-full pr-10 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm ${
                    error ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : ''
                  }`}
                  placeholder="example.com"
                />
                {error && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div>
                )}
              </div>
              {error && <p className="mt-2 text-sm text-red-600 dark:text-red-400">{error}</p>}
            </div>
            {/* ... (keep the existing form fields for step 1) */}
            <button
              type="submit"
              className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600"
              disabled={isInitializingProperty || !!error}
            >
              {isInitializingProperty ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  {isInitializingProperty ? 'Initializing Property...' : 'Processing...'}
                </>
              ) : (
                'Create Property'
              )}
            </button>
          </form>
        )}

        {currentStep === 2 && websiteData && (
          
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">Website Information</h2>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Name</label>
              <input
                type="text"
                value={websiteData.title}
                disabled
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 dark:bg-gray-600 dark:text-gray-300 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Property ID</label>
              <input
                type="text"
                value={websiteData.propertyId}
                disabled
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 dark:bg-gray-600 dark:text-gray-300 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Categories</label>
              <div className="mt-1 flex flex-wrap gap-2">
                {websiteData.categories.map((category, index) => (
                  <span key={index} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 dark:bg-indigo-700 dark:text-indigo-100">
                    {category}
                  </span>
                ))}
              </div>
              <div className="mt-2 flex">
                <input
                  type="text"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                  placeholder="Add a category"
                  className="flex-grow mr-2 rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
                />
                <button
                  onClick={addCategory}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                  Add
                </button>
              </div>
            </div>
            <button
              type="submit"
              onClick={initializeProperty}
              className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600"
              disabled={isLoading || isInitializingProperty || !!error}
            >
              {isLoading || isInitializingProperty ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  {isInitializingProperty ? 'Initializing Property...' : 'Processing...'}
                </>
              ) : (
                'Submit and Initialize'
              )}
            </button>
          </div>
        )}

        {currentStep === 3 && (
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">Integration</h2>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Embed Code</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <pre className="block w-full p-2 border border-gray-300 rounded-md font-mono text-sm bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300">
  {`<head>
      /// ...
      <script src="https://cdn.jsdelivr.net/npm/tweetnacl@1.0.3/nacl.min.js"></script>
      <script src="https://embed.adx.so/bundle.js"></script>
      <script>
          const communicator = new DaxCommunicator({
              propertyId: '${websiteData?.propertyId}',
              publisherPubkey: '${wallet?.address}'
          });
          communicator.run().catch(console.error);
      </script>
      /// ...
  </head>`}
                </pre>
                <button onClick={copyToClipboard} className="absolute top-2 right-2 p-1 ...">
                  <ClipboardDocumentIcon className="h-5 w-5 text-gray-500 dark:text-gray-300" />
                </button>
              </div>
            </div>
            <button
              onClick={() => setCurrentStep(4)}
              className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:bg-green-500 dark:hover:bg-green-600"
            >
              Continue to Review
            </button>
          </div>
        )}

        {currentStep === 4 && websiteData && (
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">Review</h2>
            <button
              onClick={handleFinalSubmit}
              className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600"
            >
              Complete
            </button>
          </div>
        )}

        {/* ... (Verification modal) */}
        <Transition.Root show={isModalOpen} as={React.Fragment}>
          <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setIsModalOpen}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 dark:bg-green-900">
                      <svg className="animate-spin h-6 w-6 text-green-600 dark:text-green-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                        Verifying Embedding
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Please wait while we verify the embedding of the code on your website.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

      </div>
      </>

  );
};



export default MultiStepForm;