import * as anchor from "@coral-xyz/anchor";
import { Dax } from "../dax";
import { keccak_256 } from 'js-sha3';
import { PublicKey, Keypair } from "@solana/web3.js";
import * as secp256k1 from 'secp256k1';
import nacl from 'tweetnacl';
import Core  from "./core";



// This function will detect the environment and return the appropriate decryption function
function getDecryptionFunction() {
  if (typeof window !== 'undefined' && window.crypto && window.crypto.subtle) {
    // Browser environment
    return async (
      encryptedData: Uint8Array,
      aesKey: Uint8Array,
      iv: Uint8Array,
      authTag: Uint8Array
    ): Promise<ArrayBuffer> => {
      const algorithm = { name: 'AES-GCM', iv: iv };
      const key = await window.crypto.subtle.importKey(
        'raw',
        aesKey,
        algorithm,
        false,
        ['decrypt']
      );
      
      const encryptedDataWithAuthTag = new Uint8Array(encryptedData.length + authTag.length);
      encryptedDataWithAuthTag.set(encryptedData);
      encryptedDataWithAuthTag.set(authTag, encryptedData.length);

      return window.crypto.subtle.decrypt(
        algorithm,
        key,
        encryptedDataWithAuthTag
      );
    };
  } else {
    // Node.js environment
    return (
      encryptedData: Buffer,
      aesKey: Buffer,
      iv: Buffer,
      authTag: Buffer
    ): Buffer => {
      // We'll implement this later for Node.js environment
      throw new Error("Node.js decryption not implemented");
    };
  }
}

// The decryption function to be used
const decryptFunction = getDecryptionFunction();



export default class Requestor extends Core {

    constructor(
        baseConnection: anchor.web3.Connection,
        rollupConnection: anchor.web3.Connection,
        idl: Dax,
        programId: PublicKey,
        keypair?: Keypair,
        wallet?: anchor.Wallet,
        opts: anchor.web3.ConfirmOptions = {}
    ) {
        super(baseConnection, rollupConnection, idl, programId, keypair, wallet, opts);
    }
    
    generateElGamalKeyPair(
        nonce: anchor.BN
    ): [Buffer, number[]] { 
        // const elGamalPrivateKey = crypto.randomBytes(32); 
        if (!this.keypair) {
            throw new Error("Keypair not provided");
        }
        console.log(nonce);
        const signedNonce = Buffer.from(
            nacl.sign.detached(
                nonce.toArrayLike(Buffer, 'le', 8), 
                this.keypair.secretKey
            )
        );
        return this.generateElGamalKeyPairFromSignedNonce(signedNonce);
    }   

    generateElGamalKeyPairFromSignedNonce(
        signedNonce: Buffer,
    ): [Buffer, number[]] { 
        // const elGamalPrivateKey = crypto.randomBytes(32); 
        const elGamalPrivateKey = signedNonce.slice(0, 32);
        const elGamalPublicKey = secp256k1.publicKeyCreate(elGamalPrivateKey, true);
        if (!secp256k1.publicKeyVerify(elGamalPublicKey)) {
            throw new Error("Generated public key is not valid");
        }
        return [elGamalPrivateKey, Array.from(elGamalPublicKey)]
    }   

    async getNonce(): Promise<anchor.BN> {
        return this.getNonceForPubkey(this.publicKey);
    }

    async getNonceForPubkey(
        pubkey: PublicKey
    ): Promise<anchor.BN> {
        const noncePubkey = await this.deriveNoncePubkey(pubkey);
        const nonceAccount = await this.rollupProgram.account.nonce.fetchNullable(noncePubkey);
        return nonceAccount?.nonce ?? new anchor.BN(0);
    }

    generatePlacement(
        nonce: anchor.BN,
        context: anchor.IdlTypes<Dax>["PlacementContext"],
        publisherPubkey: PublicKey,
        requestorPubkey: PublicKey,
        propertyId: number,
        elGamalPublicKey: number[]
    ): anchor.IdlTypes<Dax>["Placement"] {
        const contextBytes = this.rollupProgram.coder.types.encode("PlacementContext", context);
        const contextHash =  Array.from(Buffer.from(keccak_256(contextBytes), 'hex'));
        // Generate the placement
        const placement = {
            requestor: requestorPubkey,
            nonce: nonce,
            publisher: publisherPubkey,
            propertyId: propertyId,
            elGamalPublicKey: elGamalPublicKey,
            contextHash: contextHash
        } as anchor.IdlTypes<Dax>["Placement"];        
        return placement;
    }



    async newPlacement(
        context: anchor.IdlTypes<Dax>["PlacementContext"],
        publisherPubkey: PublicKey,
        propertyId: number
    ): Promise<[anchor.IdlTypes<Dax>["Placement"], Buffer, Buffer]> {
        const nonce = await this.getNonce()
        const [elGamalPrivateKey, elGamalPublicKey] = this.generateElGamalKeyPair(nonce);
        const placement = this.generatePlacement(nonce, context, publisherPubkey, this.publicKey, propertyId, elGamalPublicKey);
        const placementBytes = this.rollupProgram.coder.types.encode("Placement", placement);
        if (!this.keypair) {
            throw new Error("Keypair not provided");
        }
        const requestorSignature = Buffer.from(
            nacl.sign.detached(
                placementBytes, 
                this.keypair.secretKey
            )
        );
        return [placement, requestorSignature, elGamalPrivateKey];
    }

    generateBidAcceptance(
        elGamalPrivateKey: Buffer,
        bid: anchor.IdlTypes<Dax>["Bid"],
    ): [Buffer, Buffer] {
        const bidElGamalPublicKey = Buffer.from(secp256k1.publicKeyConvert(
            Buffer.from(bid.encryptedValue.ephemeralPublicKey),
            true
        ));
        const sharedSecret = this.deriveSharedSecret(
            elGamalPrivateKey, 
            bidElGamalPublicKey
        );
        if (!this.keypair) {
            throw new Error("Keypair not provided");
        }
        const requestorSignature = Buffer.from(
            nacl.sign.detached(
                sharedSecret, 
                this.keypair.secretKey
            )
        );
        return [sharedSecret, requestorSignature];
    }
    

    async decryptBid(
        elGamalPrivateKey: Buffer,
        encryptedValue: any,
    ): Promise<[number, PublicKey, Buffer]>{
        const bidElGamalPublicKey = Buffer.from(secp256k1.publicKeyConvert(
            Buffer.from(encryptedValue.ephemeralPublicKey),
            true
        ));
        const sharedSecret = this.deriveSharedSecret(
            elGamalPrivateKey, 
            bidElGamalPublicKey
        );
        const aesKey = Buffer.from(keccak_256(sharedSecret), 'hex');
        const iv = Buffer.from(encryptedValue.encryptedData.slice(0, 12));
        const ciphertext = Buffer.from(encryptedValue.encryptedData.slice(12));
        const authTag = Buffer.from(encryptedValue.authTag);
        try {
            const decrypted = await decryptFunction(ciphertext, aesKey, iv, authTag);
            const decryptedBuffer = Buffer.from(decrypted);
            const decryptedBidBn = new anchor.BN(decryptedBuffer.slice(0, 8));
            const decryptedBid = decryptedBidBn.toNumber();
            const decryptedCreativeId = new PublicKey(decryptedBuffer.slice(8));
            return [decryptedBid, decryptedCreativeId, sharedSecret];
        } catch (error) {
            console.error(error);
            throw new Error(`Decryption failed`);
        }
    }

    
}