import React, { useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Outlet, Navigate } from 'react-router-dom';
import Nav from './Nav';
import { useWallet } from '@solana/wallet-adapter-react';

// Information section
const InfoLayout = () => {
  const { connected } = useWallet();

  return (
  <>
  
    <Nav isAuthenticated={connected} />
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <h1>Information</h1>
          <nav>
            <ul>
              <li><Link to="/info/about">About</Link></li>
              <li><Link to="/info/faq">FAQ</Link></li>
              <li><Link to="/info/contact">Contact</Link></li>
            </ul>
          </nav>
          <Outlet />
        </main>
    </div>
  </>
)};
  
  export const About = () => <h2>About Us</h2>;
  export const FAQ = () => <h2>Frequently Asked Questions</h2>;
  export const Contact = () => <h2>Contact Us</h2>;
  
  
  export default InfoLayout;