import React, { useState, useEffect, useCallback } from 'react';
import { useDaxProgram } from '../../../context/DaxProgramContext';
import { useToast } from '../../../context/ToastContext';
import { PublicKey } from '@solana/web3.js';
import { useSolanaWallets } from '@privy-io/react-auth';
import Advertiser, { InitOrUpdateCreativeArgs, Language, CreativeAsset, CreativeStatus } from '../../../bindings/advertiser';
import { getCreatives } from '../../../supabase';
import { initOrUpdateCreative } from '../../../rollup';

interface ManageCreativeModalProps {
  isOpen: boolean;
  onClose: () => void;
  advertiser: Advertiser | null;
  refreshCreatives: () => void;
  existingCreative: {
    id: number;
    status: CreativeStatus;
    asset: CreativeAsset;
    ctaUrl: string;
    languages: Language[];
  } | null;
}

const Spinner = () => (
  <div className="animate-spin rounded-full h-4 w-4 border-2 border-indigo-500 border-t-transparent"></div>
);

const ManageCreativeModal: React.FC<ManageCreativeModalProps> = ({ 
  isOpen, 
  onClose, 
  advertiser, 
  refreshCreatives, 
  existingCreative 
}) => {
  // ... (previous state declarations)
  const [assetError, setAssetError] = useState<string | null>(null);
  const [assetProperties, setAssetProperties] = useState<{
    width?: number;
    height?: number;
    duration?: number;
    min_width?: number;
    max_width?: number;
    min_height?: number;
    max_height?: number;
    min_duration?: number;
  }>({});

  const [creativeId, setCreativeId] = useState<number | null>(null);
  const [status, setStatus] = useState<CreativeStatus>({ active: {} });
  const [asset, setAsset] = useState<any>({ Image: { uri: '', width: 0, height: 0, max_width: 0, max_height: 0, min_width: 0, min_height: 0 } });
  const [ctaUrl, setCtaUrl] = useState('');
  const [languages, setLanguages] = useState<Language[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [assetIsLoading, setAssetIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { program } = useDaxProgram();
  const { showToast } = useToast();
  const { wallets } = useSolanaWallets();
  const wallet = wallets[0];
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const resetForm = useCallback(() => {
    // setCreativeId(null);
    setStatus({ active: {} });
    setAsset({ Image: { uri: '', width: 0, height: 0, max_width: 0, max_height: 0, min_width: 0, min_height: 0 } });
    setCtaUrl('');
    setLanguages([]);
    setAssetProperties({});
    setAssetError(null);
    setShowAdvancedSettings(false);
    setError(null);
  }, []);

  useEffect(() => {
    const initializeCreative = async () => {
      if (existingCreative) {
        setCreativeId(existingCreative.id);
        if ((existingCreative.status as any) == "\"Active\"") {
          setStatus({ active: {} });
        } else if ((existingCreative.status as any) == "\"Suspended\"") {
          setStatus({ suspended: {} });
        } else {
          setStatus({ uninitialized: {} });
        }
        setAsset(existingCreative.asset);
        setCtaUrl(existingCreative.ctaUrl);
        // if ((existingCreative.languages as any) == "any") {
        //   setLanguages([{ any: {} }]);
        // } else {
        //   setLanguages(existingCreative.languages);
        // }
        setLanguages([{ en: {} }]);
      } else {
        // const nextId = await advertiser.getNextCreativeIdx();
        const creatives = await getCreatives({ advertiser: wallet.address });
        const nextId = creatives.length + 1;
        console.log('nextId', nextId); 
        setCreativeId(nextId);
        resetForm();
      }
    };

    if (isOpen) {
      initializeCreative();
    } else {
      resetForm();
    }
  }, [isOpen, existingCreative, wallet, resetForm]);


  const handleStatusChange = (newStatus: 'uninitialized' | 'active' | 'suspended') => {
    setStatus({ [newStatus]: {} } as CreativeStatus);
  };

  const handleAssetLoad = (type: 'Image' | 'Video', element: HTMLImageElement | HTMLVideoElement) => {
    setAssetError(null);
    setAssetIsLoading(false);
    const newProperties: typeof assetProperties = {
      width: element.width,
      height: element.height,
      min_width: Math.floor(element.width * 0.5),
      max_width: Math.ceil(element.width * 1.5),
      min_height: Math.floor(element.height * 0.5),
      max_height: Math.ceil(element.height * 1.5),
    };
    if (type === 'Video' && element instanceof HTMLVideoElement) {
      newProperties.duration = element.duration;
      newProperties.min_duration = Math.floor(element.duration * 0.5);
    }
    setAssetProperties(newProperties);
    setAsset((prev: any) => {
      if (type === 'Image' && 'Image' in prev) {
        return { Image: { ...prev.Image, ...newProperties } };
      } else if (type === 'Video' && 'Video' in prev) {
        return { Video: { ...prev.Video, ...newProperties } };
      }
      return prev;
    });
  };

  const handleAssetError = () => {
    setAssetError('Failed to load asset. Please check the URL and try again.');
    setAssetIsLoading(false);
  };

  const renderAssetPreview = () => {
    const stageStyle = "w-full h-64 bg-gray-100 dark:bg-gray-700 flex items-center justify-center rounded-lg overflow-hidden";

    if (assetIsLoading) {
      return (
        <div className={stageStyle}>
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
        </div>
      );
    }

    if (assetError) {
      return (
        <div className={`${stageStyle} text-red-500`}>
          {assetError}
        </div>
      );
    }

    if ('Image' in asset && asset.Image.uri) {
      return (
        <div className={stageStyle}>
          <img
            src={asset.Image.uri}
            alt="Creative Preview"
            onLoad={(e) => handleAssetLoad('Image', e.target as HTMLImageElement)}
            onError={handleAssetError}
            className="max-w-full max-h-full object-contain"
          />
        </div>
      );
    } else if ('Video' in asset && asset.Video.uri) {
      return (
        <div className={stageStyle}>
          <video
            src={asset.Video.uri}
            controls
            onLoadedMetadata={(e) => handleAssetLoad('Video', e.target as HTMLVideoElement)}
            onError={handleAssetError}
            className="max-w-full max-h-full"
          />
        </div>
      );
    } else if ('Copy' in asset) {
      return (
        <div className={`${stageStyle} flex-col p-4 text-left`}>
          <h3 className="text-lg font-bold">{asset.Copy.title}</h3>
          <p className="mt-2">{asset.Copy.body}</p>
          <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
            {asset.Copy.ctaText}
          </button>
        </div>
      );
    }

    return (
      <div className={`${stageStyle} text-gray-400`}>
        No asset preview available
      </div>
    );
  };

  const renderAssetProperties = () => {
    if (!showAdvancedSettings) return null;

    if ('Image' in asset || 'Video' in asset) {
      return (
        <div className="mt-4 grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium">Min Width:</label>
            <input
              type="number"
              value={assetProperties.min_width || ''}
              onChange={(e) => setAssetProperties(prev => ({ ...prev, min_width: Number(e.target.value) }))}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Max Width:</label>
            <input
              type="number"
              value={assetProperties.max_width || ''}
              onChange={(e) => setAssetProperties(prev => ({ ...prev, max_width: Number(e.target.value) }))}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Min Height:</label>
            <input
              type="number"
              value={assetProperties.min_height || ''}
              onChange={(e) => setAssetProperties(prev => ({ ...prev, min_height: Number(e.target.value) }))}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Max Height:</label>
            <input
              type="number"
              value={assetProperties.max_height || ''}
              onChange={(e) => setAssetProperties(prev => ({ ...prev, max_height: Number(e.target.value) }))}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
            />
          </div>
          {('Video' in asset) && (
            <div>
              <label className="block text-sm font-medium">Min Duration:</label>
              <input
                type="number"
                value={assetProperties.min_duration || ''}
                onChange={(e) => setAssetProperties(prev => ({ ...prev, min_duration: Number(e.target.value) }))}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
              />
            </div>
          )}
        </div>
      );
    }
    return null;
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!advertiser || !creativeId || !program || !wallet || assetError) return;
    setIsLoading(true);
    setError(null);

    try {
      // const args: InitOrUpdateCreativeArgs = {
      //   status,
      //   asset,
      //   ctaUrl,
      //   languages,
      // };

      // await advertiser.initOrUpdateCreative(creativeId, args);
      const signature = await initOrUpdateCreative(wallet, advertiser, creativeId, asset, status, ctaUrl, languages);
      
      showToast(`Successfully ${existingCreative ? 'updated' : 'created'} creative`, 'success');
      await refreshCreatives();
      onClose();
    } catch (error: any) {
      console.error("Transaction error:", error);
      setError(`Failed to ${existingCreative ? 'update' : 'create'} creative: ${error.message}`);
      showToast(`Failed to ${existingCreative ? 'update' : 'create'} creative: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };



  if (!isOpen) return null;


  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-auto">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl w-3/4 max-w-4xl max-h-80vh text-gray-900 dark:text-gray-100">
        <h2 className="text-2xl font-bold mb-6">
          {existingCreative ? 'Edit Creative' : 'Create New Creative'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">

         
          <div>
            <label className="block text-sm font-medium mb-1">Creative ID</label>
            <input
              type="number"
              value={creativeId || ''}
              readOnly
              className="w-full px-3 py-2 bg-gray-100 dark:bg-gray-700 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Status</label>
            <div className="space-x-4">
              {['active', 'suspended', 'uninitialized'].map((statusOption) => (
                <label key={statusOption} className="inline-flex items-center">
                  <input
                    type="radio"
                    value={statusOption}
                    checked={statusOption in status}
                    onChange={() => setStatus({ [statusOption]: {} } as CreativeStatus)}
                    className="form-radio h-5 w-5 text-indigo-600"
                  />
                  <span className="ml-2 capitalize">{statusOption}</span>
                </label>
              ))}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Asset Type</label>
            <select
              value={Object.keys(asset)[0]}
              onChange={(e) => {
                const newType = e.target.value as keyof CreativeAsset;
                switch (newType) {
                  case 'Image':
                    setAsset({ Image: { uri: '', width: 0, height: 0, max_width: 0, max_height: 0, min_width: 0, min_height: 0 } });
                    break;
                  case 'Video':
                    setAsset({ Video: { uri: '', width: 0, height: 0, duration: 0, max_width: 0, max_height: 0, min_width: 0, min_height: 0, min_duration: 0 } });
                    break;
                  case 'Copy':
                    setAsset({ Copy: { title: '', body: '', cta_text: '' } });
                    break;
                }
              }}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
            >
              <option value="Image">Image</option>
              <option value="Video">Video</option>
              <option value="Copy">Copy</option>
            </select>
          </div>

           

          {/* Asset-specific inputs */}
          {'Image' in asset && (
            <div className="space-y-4">
              <input
                type="text"
                placeholder="Image URI"
                value={asset.Image.uri}
                onChange={(e) => setAsset({ Image: { ...asset.Image, uri: e.target.value } })}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
              />
              {/* Add inputs for width, height, maxWidth, maxHeight, minWidth, minHeight */}
            </div>
          )}
          {'Video' in asset && (
            <div className="space-y-4">
              <input
                type="text"
                placeholder="Video URI"
                value={asset.video.uri}
                onChange={(e) => setAsset({ video: { ...asset.video, uri: e.target.value } })}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
              />
              {/* Add inputs for width, height, duration, maxWidth, maxHeight, minWidth, minHeight, minDuration */}
            </div>
          )}
          {'Copy' in asset && (
            <div className="space-y-4">
              <input
                type="text"
                placeholder="Title"
                value={asset.copy.title}
                onChange={(e) => setAsset({ copy: { ...asset.copy, title: e.target.value } })}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
              />
              <textarea
                placeholder="Body"
                value={asset.copy.body}
                onChange={(e) => setAsset({ copy: { ...asset.copy, body: e.target.value } })}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
              />
              <input
                type="text"
                placeholder="CTA Text"
                value={asset.copy.ctaText}
                onChange={(e) => setAsset({ copy: { ...asset.copy, ctaText: e.target.value } })}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
              />
            </div>
          )}

          <div>
            <label className="block text-sm font-medium mb-1">Asset Preview</label>
            {renderAssetPreview()}
          </div>
          
          <div>
            <button
              type="button"
              onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
              className="text-sm text-indigo-600 dark:text-indigo-400 hover:underline"
            >
              {showAdvancedSettings ? 'Hide' : 'Show'} Advanced Settings
            </button>
            {renderAssetProperties()}
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">CTA URL</label>
            <input
              type="text"
              value={ctaUrl}
              onChange={(e) => setCtaUrl(e.target.value)}
              placeholder="https://example.com"
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Languages</label>
            <div className="space-y-2">
              {['any', 'en', 'cn', 'fr', 'es', 'de'].map((lang) => (
                <label key={lang} className="inline-flex items-center mr-4">
                  <input
                    type="checkbox"
                    value={lang}
                    checked={languages.some(l => lang in l)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setLanguages([...languages, { [lang]: {} } as Language]);
                      } else {
                        setLanguages(languages.filter(l => !(lang in l)));
                      }
                    }}
                    className="form-checkbox h-5 w-5 text-indigo-600"
                  />
                  <span className="ml-2">{lang.toUpperCase()}</span>
                </label>
              ))}
            </div>
          </div>

          {error && <p className="text-red-500 dark:text-red-400 text-sm">{error}</p>}
          <div className="flex justify-end space-x-2">
            <button
              type="submit"
              disabled={isLoading || !!assetError}
              className="px-4 py-2 bg-indigo-600 dark:bg-indigo-500 text-white rounded hover:bg-indigo-700 dark:hover:bg-indigo-600 transition-colors disabled:opacity-50 flex items-center justify-center"
            >
              {isLoading ? (
                <>
                  <Spinner />
                  <span className="ml-2">Processing...</span>
                </>
              ) : (
                existingCreative ? 'Update Creative' : 'Create Creative'
              )}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-400 dark:hover:bg-gray-500 transition-colors"
            >
              Cancel
            </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default ManageCreativeModal;




