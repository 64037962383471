import React, { useCallback, useEffect, useState } from 'react';
import { ClockIcon, CubeIcon, CurrencyDollarIcon, UserGroupIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { getPlacementsWithAuction, PlacementWithAuction } from '../../../supabase';

// // Dummy data for statistics
// const stats = [
//   { name: 'Total Transactions', value: '1,234,567', icon: ClockIcon },
//   { name: 'Latest Block', value: '8,901,234', icon: CubeIcon },
//   { name: 'Market Cap', value: '$9.87B', icon: CurrencyDollarIcon },
//   { name: 'Active Wallets', value: '567,890', icon: UserGroupIcon },
// ];

const ExplorerStats: React.FC = () => {

  const [records, setRecords] = useState<PlacementWithAuction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string|null>(null);

  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [latestBlock, setLatestBlock] = useState<number>(0);
  const [marketCap, setMarketCap] = useState<number>(0);
  const [activeWallets, setActiveWallets] = useState<number>(0);

  const fetchRecords = useCallback(async () => {
    // if (!rollupNode) return;
    setLoading(true);
    
    try {
      // const fetchedRecords = await rollupNode.fetchRecentCommittedPlacementRecord();
      const fetchedRecords = await getPlacementsWithAuction({});
      console.log(fetchedRecords);
      setRecords(fetchedRecords);
      setTotalTransactions(fetchedRecords.length);
      setLatestBlock(fetchedRecords.length);
      setMarketCap(fetchedRecords.reduce((acc, curr) => acc + curr.auction.bid_value, 0));
      setActiveWallets(fetchedRecords.length * 4);
    } catch (err) {
      setError('Failed to fetch records');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return (
   
    <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {/* {stats.map((item) => ( */}
        <div className="bg-white dark:bg-gray-900 dark:text-white p-4 rounded-lg shadow">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <ClockIcon className="h-6 w-6 text-blue-500" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Total Transactions</p>
              <p className="text-lg font-semibold text-gray-900 dark:text-white">{totalTransactions}</p>
            </div>
          </div>
        </div>
        <div className="bg-white dark:bg-gray-900 dark:text-white p-4 rounded-lg shadow">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <CubeIcon className="h-6 w-6 text-blue-500" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Latest Block</p>
              <p className="text-lg font-semibold text-gray-900 dark:text-white">{latestBlock}</p>
            </div>
          </div>
        </div>
        <div className="bg-white dark:bg-gray-900 dark:text-white p-4 rounded-lg shadow">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <CurrencyDollarIcon className="h-6 w-6 text-blue-500" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Market Cap</p>
              <p className="text-lg font-semibold text-gray-900 dark:text-white">{marketCap}</p>
            </div>
          </div>
        </div>
        <div className="bg-white dark:bg-gray-900 dark:text-white p-4 rounded-lg shadow">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <UserGroupIcon className="h-6 w-6 text-blue-500" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Active Wallets</p>
              <p className="text-lg font-semibold text-gray-900 dark:text-white">{activeWallets}</p>
            </div>
          </div>
        </div>
      {/* ))} */}
    </div>

  );
};

export default ExplorerStats;