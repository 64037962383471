import * as anchor from "@coral-xyz/anchor";
import { Dax } from "../dax";
import { PublicKey, Keypair } from "@solana/web3.js";
import Core  from "./core";
import nacl from "tweetnacl";
import { keccak_256 } from 'js-sha3';


export default class Publisher extends Core {

    constructor(
        baseConnection: anchor.web3.Connection,
        rollupConnection: anchor.web3.Connection,
        idl: Dax,
        programId: PublicKey,
        keypair?: Keypair,
        wallet?: anchor.Wallet ,
        opts: anchor.web3.ConfirmOptions = {}
    ) {
        super(baseConnection, rollupConnection, idl, programId, keypair, wallet, opts);
    }
    
    async initPropertyIxn(
        propertyId: number,
        url: string,
    ): Promise<anchor.web3.TransactionInstruction> {
        let accounts = {
            payer: this.publicKey,
            publisher: this.publicKey,
            balance: this.deriveBalancePubkey(this.publicKey),
            property: this.derivePropertyPubkey(this.publicKey, propertyId),
            systemProgram: anchor.web3.SystemProgram.programId,
            instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
        }
        const initializePropertIxn = await this.baseProgram.methods
            .initProperty({
                id: propertyId,
                url: url,
            })
            .accounts( accounts ).instruction();
        return initializePropertIxn;
    }

    async initProperty(
        propertyId: number,
        url: string,
        nonce: number = 0
    ): Promise<string> {
        let tx = new anchor.web3.Transaction()

        // Generate the expected property after the update
        const [expectedPropertyStateAfterUpdate, expectedPropertyStateHash, publisherSignature] = await this.generatePropertyAndSignature(
            propertyId,
            url,
            nonce
        );
        const ed25519VerifyIxn = this.verifyEd25519SignatureIxn(
            expectedPropertyStateHash,
            publisherSignature,
            this.publicKey
        );
        tx.add(ed25519VerifyIxn);

        const ixn = await this.initPropertyIxn(propertyId, url);
        tx.add(ixn);
        tx.feePayer = this.publicKey;
        const provider = this.rollupProgram.provider as anchor.AnchorProvider;
        tx.recentBlockhash = (await this.rollupProgram.provider.connection.getLatestBlockhash()).blockhash;
        const txHash = await provider.sendAndConfirm(tx);
        return txHash
    }

    async generatePropertyAndSignature(
        propertyId: number,
        url: string,
        nonce: number = 1
    ): Promise<[anchor.IdlAccounts<Dax>["property"], Buffer, Buffer]> {
        // Generate the expected property after the update
        const expectedPropertyStateAfterUpdate = {
            publisher: this.publicKey,
            id: propertyId,
            url: url,
            nonce: nonce
        } as anchor.IdlAccounts<Dax>["property"];
        
        const expectedPropertyStateAfterUpdateBytes = (await this.rollupProgram.coder.accounts
            .encode("property", expectedPropertyStateAfterUpdate)).subarray(8); // Remove account discriminator
        const expectedPropertyStateHash = Buffer.from(keccak_256(expectedPropertyStateAfterUpdateBytes), 'hex');
        const publisherSignature = Buffer.from(
            nacl.sign.detached(
                expectedPropertyStateHash, 
                this.keypair!.secretKey
            )
        ); 
        return [expectedPropertyStateAfterUpdate, expectedPropertyStateHash, publisherSignature]
    }



    async fetchPropertyById(
        propertyId: number,
        commitment: anchor.web3.Commitment = "processed"
    ): Promise<anchor.IdlAccounts<Dax>["property"] | null> {
        const propertyPubkey = this.derivePropertyPubkey(this.publicKey, propertyId);
        return this.fetchPropertyByPubkey(propertyPubkey)
    }

    async fetchPropertyByPubkey(
        propertyPubkey: PublicKey,
        commitment: anchor.web3.Commitment = "processed"
    ): Promise<anchor.IdlAccounts<Dax>["property"] | null> {
        const propertyAccount = await this.baseProgram.account.property.fetch(propertyPubkey);
        return propertyAccount 
    }

    async fetchAllProperties(
        commitment: anchor.web3.Commitment = "processed"
    ): Promise<any[]> {
        const propertyAccounts = await this.baseProgram.account.property.all(
          [{ memcmp: { offset: 8, bytes: this.publicKey.toBase58() } } as anchor.web3.MemcmpFilter]
        );
        return propertyAccounts 
    } 

    async getNextPropertyIdx(): Promise<number> {
        const properties = await this.fetchAllProperties();
        var max = 0;
        properties.forEach((p)=>{ 
            if (p.account.id > max) {
                max = Number(p.account.id)
            }
        })
        return max + 1
    }
   
}