import React, { createContext, useContext, useState, useEffect } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Keypair, PublicKey } from '@solana/web3.js';
import bs58 from 'bs58';
import Requestor from '../bindings/requestor'; 
import { Dax, IDL} from '../dax'; 
import { useDaxProgram } from './DaxProgramContext';
import { useSolanaWallets } from '@privy-io/react-auth';

interface RequestorContextType {
  requestor: Requestor | null;
}

const RequestorContext = createContext<RequestorContextType | undefined>(undefined);

interface StoredRequestors {
  [publicKey: string]: {
    keypairSecretKey: string; // base58 encoded secret key
    // Add any other Requestor-specific data you want to persist here
  };
}

const STORAGE_KEY = 'requestor-instances';

export function useRequestor() {
  const context = useContext(RequestorContext);
  if (context === undefined) {
    throw new Error('useRequestor must be used within a RequestorProvider');
  }
  return context;
}

export const RequestorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { program } = useDaxProgram();
  const [requestor, setRequestor] = useState<Requestor | null>(null);

  const {wallets} = useSolanaWallets();
  const wallet = wallets[0]; 
  const [publicKey, setPublicKey] = useState<PublicKey|undefined>(undefined);
  useEffect(() =>{
    if (wallet) {
      setPublicKey(new PublicKey(wallet.address));
    } else {
      setPublicKey(undefined);
    }
  }, [wallet])

  useEffect(() => {
    if (publicKey && program) {
      const publicKeyString = publicKey.toBase58();
      let requestorInstance: Requestor;

      // Retrieve the stored requestors
      const storedRequestorsJson = localStorage.getItem(STORAGE_KEY);
      let storedRequestors: StoredRequestors = storedRequestorsJson ? JSON.parse(storedRequestorsJson) : {};

      if (publicKeyString in storedRequestors) {
        // Recover the Keypair from stored secret key
        const secretKey = bs58.decode(storedRequestors[publicKeyString].keypairSecretKey);
        const keypair = Keypair.fromSecretKey(secretKey);
        
        // Create a new Requestor instance with the recovered keypair
        requestorInstance = new Requestor(
          program.provider.connection,
          program.provider.connection, 
          IDL as Dax,
          program.programId,
          keypair
        );
      } else {
        // Generate a new Keypair
        const keypair = Keypair.generate();
        
        // Create a new Requestor instance
        requestorInstance = new Requestor(
          program.provider.connection,
          program.provider.connection, 
          IDL as Dax,
          program.programId,
          keypair
        );

        // Store the new requestor data
        storedRequestors[publicKeyString] = {
          keypairSecretKey: bs58.encode(keypair.secretKey),
          // Add any other Requestor-specific data you want to persist here
        };
        localStorage.setItem(STORAGE_KEY, JSON.stringify(storedRequestors));
      }

      setRequestor(requestorInstance);
    } else {
      setRequestor(null);
    }
  }, [publicKey, program]);

  return (
    <RequestorContext.Provider value={{ requestor }}>
      {children}
    </RequestorContext.Provider>
  );
};

// Utility function to clear all stored requestors
export function clearAllRequestors() {
  localStorage.removeItem(STORAGE_KEY);
}

// Utility function to remove a specific requestor
export function removeRequestor(publicKey: PublicKey) {
  const storedRequestorsJson = localStorage.getItem(STORAGE_KEY);
  if (storedRequestorsJson) {
    const storedRequestors: StoredRequestors = JSON.parse(storedRequestorsJson);
    delete storedRequestors[publicKey.toBase58()];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storedRequestors));
  }
}