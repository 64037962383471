import React, { useState, useEffect, useCallback } from 'react';
import { Objective } from '../../../bindings/advertiser';
import DatePicker from '../../common/DatePicker';
import { useSolanaWallets } from '@privy-io/react-auth';
import { getCampaigns } from '../../../supabase';

interface NewCampaignBasicInfoStepProps {
  onSubmit: (data: any) => void;
  initialData: any;
  isSubmitting: boolean;
}

const NewCampaignBasicInfoStep: React.FC<NewCampaignBasicInfoStepProps> = ({ onSubmit, initialData, isSubmitting }) => {
  const [name, setName] = useState('');
  const [objective, setObjective] = useState<Objective>({ undefined: {} });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  // const [nextCampaignId, setNextCampaignId] = useState<number | null>(null);

  // const {wallets } = useSolanaWallets();
  // const wallet = wallets[0];

  // const fetchCampaigns = useCallback(async () => {
  //   if (!wallet || !wallet.address) {
  //       setNextCampaignId(1)
  //       return;
  //   }
  //   try {
  //     console.log('Getting campaigns...')
  //     // const ps = await advertiser.fetchAllCampaigns();
  //     const ps = await getCampaigns({ advertiser: wallet.address });
  //     setNextCampaignId(ps.length + 1)
  //     console.log(ps);
  //   } catch (error) {
  //     console.error('Error fetching campaigns:', error);
  //     setNextCampaignId(1);
  //   }
  // }, [wallet]);

  // useEffect(() => {
  //   if (wallet && wallet.address) {
  //     fetchCampaigns();
  //   }
  // }, [wallet]);

  useEffect(() => {
    if (initialData) {
      // if (initialData.campaignId) {
      //   setNextCampaignId(initialData.campaignId)
      // }
      setName(initialData.name || '');
      setObjective(initialData.objective || { undefined: {} });
      setStartDate(initialData.startTimestamp ? new Date(initialData.startTimestamp * 1000) : null);
      setEndDate(initialData.endTimestamp ? new Date(initialData.endTimestamp * 1000) : null);
    }
  }, [initialData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(name, objective, startDate, endDate);
    onSubmit({
      id: initialData.campaign_id,
      name,
      objective,
      startTimestamp: startDate ? Math.floor(startDate.getTime() / 1000) : null,
      endTimestamp: endDate ? Math.floor(endDate.getTime() / 1000) : null,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Campaign Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
          required
        />
      </div>
      <div>
        <label htmlFor="objective" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Objective
        </label>
        <select
          id="objective"
          value={Object.keys(objective)[0]}
          onChange={(e) => setObjective({ [e.target.value]: {} } as Objective)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
          required
        >
          <option value="undefined">Select an objective</option>
          <option value="impressions">Impressions</option>
          <option value="conversions">Conversions</option>
        </select>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <DatePicker
          label="Start Date"
          selected={startDate}
          onChange={setStartDate}
          placeholderText="Optional"
        />
        <DatePicker
          label="End Date"
          selected={endDate}
          onChange={setEndDate}
          placeholderText="Optional"
          minDate={startDate || undefined}
        />
      </div>
      <button
        type="submit"
        disabled={isSubmitting}
        className={`w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
          isSubmitting
            ? 'bg-gray-400 dark:bg-gray-600'
            : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600'
        }`}
      >
        {isSubmitting ? 'Submitting...' : 'Next'}
      </button>
    </form>
  );
};

export default NewCampaignBasicInfoStep;