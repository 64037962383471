import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';  // Assuming you're using React Router for navigation

type AlertType = 'info' | 'success' | 'warning' | 'error';

interface AlertProps {
  type: AlertType;
  title: string;
  message: string | React.ReactNode;
  dismissible?: boolean;
  onDismiss?: () => void;
  ctaText?: string;
  ctaAction?: () => void;
  ctaUrl?: string;
}

const Alert: React.FC<AlertProps> = ({
  type,
  title,
  message,
  dismissible = false,
  onDismiss,
  ctaText,
  ctaAction,
  ctaUrl,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const typeStyles: Record<AlertType, string> = {
    info: 'bg-blue-50 dark:bg-blue-900 text-blue-800 dark:text-blue-100 border-blue-400 dark:border-blue-500',
    success: 'bg-green-50 dark:bg-green-900 text-green-800 dark:text-green-100 border-green-400 dark:border-green-500',
    warning: 'bg-yellow-50 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-100 border-yellow-400 dark:border-yellow-500',
    error: 'bg-red-50 dark:bg-red-900 text-red-800 dark:text-red-100 border-red-400 dark:border-red-500',
  };

  const iconStyles: Record<AlertType, string> = {
    info: 'text-blue-400 dark:text-blue-300',
    success: 'text-green-400 dark:text-green-300',
    warning: 'text-yellow-400 dark:text-yellow-300',
    error: 'text-red-400 dark:text-red-300',
  };

  const ctaStyles: Record<AlertType, string> = {
    info: 'text-blue-700 hover:bg-blue-100 dark:text-blue-300 dark:hover:bg-blue-800 border-blue-700 dark:border-blue-300',
    success: 'text-green-700 hover:bg-green-100 dark:text-green-300 dark:hover:bg-green-800 border-green-700 dark:border-green-300',
    warning: 'text-yellow-700 hover:bg-yellow-100 dark:text-yellow-300 dark:hover:bg-yellow-800 border-yellow-700 dark:border-yellow-300',
    error: 'text-red-700 hover:bg-red-100 dark:text-red-300 dark:hover:bg-red-800 border-red-700 dark:border-red-300',
  };

  const handleDismiss = () => {
    setIsVisible(false);
    onDismiss?.();
  };

  if (!isVisible) return null;

  const renderCTA = () => {
    if (!ctaText) return null;

    const ctaClasses = `mt-3 inline-flex items-center px-3 py-1.5 border text-xs font-medium rounded-md ${ctaStyles[type]} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${type === 'info' ? 'blue' : type === 'success' ? 'green' : type === 'warning' ? 'yellow' : 'red'}-500`;

    if (ctaAction) {
      return (
        <button
          type="button"
          onClick={ctaAction}
          className={ctaClasses}
        >
          {ctaText}
        </button>
      );
    }

    if (ctaUrl) {
      return (
        <Link
          to={ctaUrl}
          className={ctaClasses}
        >
          {ctaText}
        </Link>
      );
    }

    return null;
  };

  return (
    <div className={`rounded-md p-4 border-l-4 ${typeStyles[type]}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {type === 'info' && (
            <svg className={`h-5 w-5 ${iconStyles[type]}`} viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
            </svg>
          )}
          {type === 'success' && (
            <svg className={`h-5 w-5 ${iconStyles[type]}`} viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
            </svg>
          )}
          {type === 'warning' && (
            <svg className={`h-5 w-5 ${iconStyles[type]}`} viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          )}
          {type === 'error' && (
            <svg className={`h-5 w-5 ${iconStyles[type]}`} viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clipRule="evenodd" />
            </svg>
          )}
        </div>
        <div className="ml-3 flex-1">
          <h3 className="text-sm font-medium">{title}</h3>
          <div className="mt-2 text-sm">
            {typeof message === 'string' ? (
              <p>{message}</p>
            ) : (
              message
            )}
          </div>
          {renderCTA()}
        </div>
        {dismissible && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={handleDismiss}
                className={`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                  type === 'error' ? 'focus:ring-red-600 focus:ring-offset-red-50' :
                  type === 'warning' ? 'focus:ring-yellow-600 focus:ring-offset-yellow-50' :
                  type === 'success' ? 'focus:ring-green-600 focus:ring-offset-green-50' :
                  'focus:ring-blue-600 focus:ring-offset-blue-50'
                }`}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Alert;