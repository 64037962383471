import React, { useState, useCallback, useEffect} from 'react';
import { SiSolana } from 'react-icons/si';
import { useRollupNode } from '../../../context/RollupNodeContext';
import { Link, useParams } from 'react-router-dom';
import { ChevronUpIcon, ChevronDownIcon, CheckBadgeIcon } from '@heroicons/react/20/solid'
import { truncate } from 'fs';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Bid, getBids, getPlacementsWithAuctionAndContext, PlacementWithAuctionAndContext } from '../../../supabase';

const ExplorerPlacement: React.FC<{ placementId: string }> = ({ placementId }) => {
  
  const { rollupNode } = useRollupNode();
  const [records, setRecords] = useState<PlacementWithAuctionAndContext[]>([]);
  const [bids, setBids] = useState<Bid[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string|null>(null);

  const fetchRecords = useCallback(async (placementId: string) => {
    // if (!rollupNode) return;
    
    try {
      setLoading(true);
      // const auctionPubkey = rollupNode.deriveAuctionPubkey(placementId);
      // const fetchedRecords = await rollupNode.fetchRecentCommittedPlacementRecord(auctionPubkey);
      const fetchedRecords = await getPlacementsWithAuctionAndContext({id: placementId});

      const bids = await getBids({auction: fetchedRecords[0].auction.id});
      console.log("Bids", bids);
      setBids(bids);

      fetchedRecords[0].auction.count_bids = bids.length;
      console.log("Placements", fetchedRecords);
      setRecords(fetchedRecords);
    } catch (err) {
      setError('Failed to fetch records');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [rollupNode]);

  useEffect(() => {
    if (!placementId) { return };
    fetchRecords(placementId);
  }, [fetchRecords, placementId]);

  if (loading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return <NotFound type="placement" />;
  }



  // const contextItems = [
  //   {key: "Region", value: "IE"},
  //   {key: "Age", value: "26"}
  // ]

  const settleTransfers = [
    {role: "Publisher", pubkey: "241ninXrNeBq9hw5mP5nFetWd4Qr2xPJwzBqiYTBb859", delta: 0.0334},
    {role: "Advertiser", pubkey: "5U2wGLDeqJ38DqWNaWG8txG3y19VaaqJPB69b4Kz5rdR", delta: -0.0234},
    {role: "Requestor", pubkey: "A2jryLZDEiqVGuGM737fWLcDKkHj4vZs6yfoxT5Z7veF", delta: 0.006},
    {role: "Protocol", pubkey: "GndbGMY9eUo69meUKiV2znYmkgGo99PwmnV6noHYLpLw", delta: 0.004}
  ]

  // const bids = [
  //   {bidder: "241ninXrNeBq9hw5mP5nFetWd4Qr2xPJwzBqiYTBb859", timestamp: "2024-12-24T12:34:56", isWinner: false, "bidderSignature": "fwq312ade"},
  //   {bidder: "241ninXrNeBq9hw5mP5nFetWd4Qr2xPJwzBqiYTBb859", timestamp: "2024-12-24T12:34:56", isWinner: true, "bidderSignature": "bghgerefdss"},
  //   {bidder: "241ninXrNeBq9hw5mP5nFetWd4Qr2xPJwzBqiYTBb859", timestamp: "2024-12-24T12:34:56", isWinner: false, "bidderSignature": "dsfefsdvdsf"},
  //   {bidder: "241ninXrNeBq9hw5mP5nFetWd4Qr2xPJwzBqiYTBb859", timestamp: "2024-12-24T12:34:56", isWinner: false, "bidderSignature": "sdfsadfwsdac"},
  // ]

  return (
    <div className="w-full space-y-6 mt-6">

      <div className="bg-white dark:bg-gray-900 dark:text-white rounded-lg shadow overflow-hidden dark:divide-gray-700 flex justify-between items-center p-4 ">
        <div className="">
          <div className="text-sm text-gray-800 dark:text-gray-200">
            Placement Identifier
          </div>
          <div>
            {records[0]?.id}
          </div>
        </div>
        <div className="">
          <div className="text-sm text-gray-800 dark:text-gray-200">
            Timestamp
          </div>
          <div>
            {records[0]?.auction.created_at ? new Date(records[0]?.auction.created_at).toLocaleString() : ""}
          </div>
        </div>
        <div className="flex mb-3">
          <a
            className="flex bg-indigo-600 dark:bg-indigo-700 text-white hover:bg-indigo-500 dark:hover:bg-indigo-600 rounded px-3 py-2"
            target="_blank"
            href={records[0] ? `https://explorer.solana.com/tx/${records[0].auction.signature}?cluster=${"devnet"}` : "#"}
          >
            <SiSolana className="mr-2 mt-1 flex-shrink-0" />
            View on Solana Explorer
          </a>
        </div>
      </div>

      <CollapsibleCard title="Request & Context">

        <div className="">

          <div className="flex justify-between">
            <div>
              <div className="text-sm text-gray-800 dark:text-gray-200">
                Requestor
              </div>
              <div >
                {records[0]?.requestor} 
              </div>
            </div>

            <div>
              <div className="text-sm text-gray-800 dark:text-gray-200 flex">
                Context Hash
                <span className="text-emerald-500 flex text-sm ml-2">
                  <CheckCircleIcon className="h-4 w-4 mt-0.5 mr-0.5"  /> Verified
                </span>
              </div>
              <div className="flex">
                {records[0]?.context_hash} 
              </div>
            </div>

          </div>


          <div className="flex justify-between">
            <div>
              <div className="text-sm text-gray-800 dark:text-gray-200">
                Requestor Signature
              </div>
              <div >
                {records[0]?.id} 
              </div>
            </div>


          </div>

        </div>


        <div className="flex">
          <div className="">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Conext</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Value</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700">
              
                  {
                    (records[0]?.context.property as any).map((context: any, index: any) => (
                      <tr 
                        key={context.key}
                        className=""
                      >
                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                          {Object.keys(context)[0]}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                          {(Object.values(context)[0] as any).value}
                        </td>
                      </tr>
                    ))
                  }
              </tbody>
              <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700">
              
                  {
                    (records[0]?.context.userdata as any).map((context: any, index: any) => (
                      <tr 
                        key={context.key}
                        className=""
                      >
                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                          {Object.keys(context)[0]}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                          {JSON.stringify((Object.values(context)[0] as any).value)}
                        </td>
                      </tr>
                    ))
                  }
              </tbody>
            </table>
          </div>

          
        </div>

      </CollapsibleCard>

      <CollapsibleCard title="Auction">

        <div className="flex">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-800">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Bidder</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Bid</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Timestamp</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700">
            
                {
                  bids.map((bid, index) => (
                    <tr 
                      key={bid.bid_signature}
                      className=""
                    >
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                        {bid.bidder}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                        {"****"}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium ">
                        {new Date(bid.created_at).toLocaleString()}
                      </td>
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
      </CollapsibleCard>

      <CollapsibleCard title="Commit & Settle">



        <div className="flex">
         <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Role</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Address</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Change</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700">
              
                  {/* {
                    settleTransfers.map((settle, index) => (
                      <tr 
                        key={settle.role}
                        className=""
                      >
                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                          {settle.role}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                          {settle.pubkey}
                        </td>
                        <td className={"px-6 py-3 whitespace-nowrap text-sm font-medium " + (settle.delta > 0 ? "text-emerald-500" : "text-rose-500")}>
                          {settle.delta > 0 ? `+${settle.delta}` : settle.delta}
                        </td>
                      </tr>
                    ))
                  } */}
                  <tr>
                    <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                      Publisher
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                      {records[0]?.auction.publisher}
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                      {+records[0]?.auction.bid_value}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                      Advertiser
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                      {bids.find((bid) => bid.id === records[0]?.auction.winning_bid)?.advertiser}
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                      {-records[0]?.auction.bid_value}
                    </td>
                    {/* <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                      Requestor
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
                      Protocol
                    </td> */}
                  </tr>
              </tbody>
            </table>
        </div>
        <div className="mt-3 bg-gray-100 dark:bg-gray-800 rounded-md text-xs p-3">
          <code >
            {JSON.stringify(records[0]?.auction, null, 2)
              .split('\n')
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
          </code>
        </div>
        
      </CollapsibleCard>

    </div>
  );
};

export default ExplorerPlacement;







const SkeletonLoader = () => (
  <div className="animate-pulse space-y-6 mt-6">
    <div className="h-20 bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
    <div className="h-40 bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
    <div className="h-40 bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
    <div className="h-40 bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
  </div>
);

const NotFound = ({ type }: { type: 'placement' | 'account' }) => (
  <div className="text-center py-8">
    <h2 className="text-2xl font-bold mb-2">Not Found</h2>
    <p>The requested {type} could not be found.</p>
    <Link to="/explorer" className="text-blue-500 hover:underline mt-4 inline-block">
      Return to Explorer Home
    </Link>
  </div>
);



interface CollapsibleCardProps {
  title: string;
  children: React.ReactNode;
}

const CollapsibleCard: React.FC<CollapsibleCardProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-white dark:bg-gray-900 dark:text-white rounded-lg shadow overflow-hidden dark:divide-gray-700">
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={toggleCard}
      >
        <h2 className="text-xl font-semibold">{title}</h2>
        {isOpen ? (
          <ChevronUpIcon className="w-6 h-6" />
        ) : (
          <ChevronDownIcon className="w-6 h-6" />
        )}
      </div>
      {isOpen && (
        <div className="p-4 border-t border-gray-300">
          {children}
        </div>
      )}
    </div>
  );
};


