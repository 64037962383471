import * as anchor from "@coral-xyz/anchor";
import { Dax } from "../dax";
import { PublicKey, Keypair, SendTransactionError } from "@solana/web3.js";
import Core  from "./core";
import { InitOrUpdateCampaignArgs, InitOrUpdateCreativeArgs } from "./advertiser";
import { stringToFixedLengthArray } from "./utils";




export default class Node extends Core {

    private _nodeState: anchor.IdlAccounts<Dax>["rollupNode"] | undefined;

    constructor(
        baseConnection: anchor.web3.Connection,
        rollupConnection: anchor.web3.Connection,
        idl: Dax,
        programId: PublicKey,
        keypair?: Keypair,
        wallet?: anchor.Wallet ,
        opts: anchor.web3.ConfirmOptions = {}
    ) {
        super(baseConnection, rollupConnection, idl, programId, keypair, wallet, opts);
    }
   
    get nodeAccountPublicKey(): PublicKey {
        return Node.deriveNodeAccountPubkey(this.publicKey, this.baseProgram.programId)
    }

    get state(): anchor.IdlAccounts<Dax>["rollupNode"] {
        return this._nodeState!;
    }

    async loadState(
        commitment: anchor.web3.Commitment = "processed"
    ) {
        this._nodeState  = await this.baseProgram.account.rollupNode.fetchNullable(
            this.nodeAccountPublicKey,
            commitment
        ) ?? undefined;
    }

    static deriveNodeAccountPubkey(
        nodeAuthorityPubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
              anchor.utils.bytes.utf8.encode("rollup_node"), 
              nodeAuthorityPubkey.toBuffer(),
            ],
            programId
        );
        return pk
    }

    async listPlacementIxns(
        placement: anchor.IdlTypes<Dax>["Placement"], 
        context: anchor.IdlTypes<Dax>["PlacementContext"],
        requestorSignature: Buffer
    ): Promise<anchor.web3.TransactionInstruction[]> {
        const noncePubkey = this.deriveNoncePubkey(placement.requestor);
        const auctionPubkey = this.deriveAuctionPubkey(anchor.utils.bytes.bs58.encode(requestorSignature));
        const propertyPubkey = this.derivePropertyPubkey(placement.publisher, placement.propertyId);
        const placementBytes = this.rollupProgram.coder.types
            .encode("Placement", placement);
        const ed25519VerifyIxn = this.verifyEd25519SignatureIxn(
            placementBytes,
            requestorSignature,
            placement.requestor
        );
        let accounts = {
            auction: auctionPubkey,
            authority: this.publicKey,
            requestor: placement.requestor,
            nonce: noncePubkey,
            publisher: placement.publisher,
            property: propertyPubkey,
            systemProgram: anchor.web3.SystemProgram.programId,
            instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
        }
        const listPlacementIxn = await this.rollupProgram.methods
            .listPlacement({context: context})
            .accounts( accounts).instruction();

        return [ed25519VerifyIxn, listPlacementIxn];
    }

    async initPropertyIxn(
        publisherPubkey: PublicKey,
        propertyId: number,
        url: string,
    ): Promise<anchor.web3.TransactionInstruction> {
        let accounts = {
            payer: this.publicKey,
            publisher: publisherPubkey,
            balance: this.deriveBalancePubkey(publisherPubkey),
            property: this.derivePropertyPubkey(publisherPubkey, propertyId),
            systemProgram: anchor.web3.SystemProgram.programId,
            instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
        }
        const initializePropertIxn = await this.baseProgram.methods
            .initProperty({
                id: propertyId,
                url: url,
            })
            .accounts( accounts ).instruction();
        return initializePropertIxn;
    }

    async initOrUpdateBidderDelegateIxn(
        delegatePubkey: PublicKey,
        advertiserPubkey: PublicKey,
        stateDelegatePubkey: PublicKey,
        nonce: number = 1
    ): Promise<anchor.web3.TransactionInstruction> {
        let accounts = {
            payer: this.publicKey,
            advertiser: advertiserPubkey,
            balance: this.deriveBalancePubkey(advertiserPubkey),
            delegate: delegatePubkey,
            bidderDelegate: this.deriveBidderDelegatePubkey(advertiserPubkey, delegatePubkey),
            systemProgram: anchor.web3.SystemProgram.programId,
            instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
        }
        const ixn = await this.baseProgram.methods
            .initOrUpdateBidderDelegate({
                revoke: delegatePubkey.toString() != stateDelegatePubkey.toString()
            })
            .accounts( accounts ).instruction();
        return ixn;
    }

    async initProperty(
        publisherSignature: Buffer,
        expectedPropertyStateAfterUpdate: anchor.IdlAccounts<Dax>["property"],
        expectedPropertyStateHash: Buffer,
    ): Promise<string> {
        let tx = new anchor.web3.Transaction();
        const ed25519VerifyIxn = this.verifyEd25519SignatureIxn(
            expectedPropertyStateHash,
            publisherSignature,
            expectedPropertyStateAfterUpdate.publisher
        );
        tx.add(ed25519VerifyIxn);

        const ixn = await this.initPropertyIxn(
            expectedPropertyStateAfterUpdate.publisher,
            expectedPropertyStateAfterUpdate.id,
            expectedPropertyStateAfterUpdate.url
        );

        tx.add(ixn);
        tx.feePayer = this.publicKey;
        const provider = this.rollupProgram.provider as anchor.AnchorProvider;
        tx.recentBlockhash = (await this.rollupProgram.provider.connection.getLatestBlockhash()).blockhash;
        const txHash = await provider.sendAndConfirm(tx);
        return txHash
    }

    async initOrUpdateBidderDelegate(
        delegatePubkey: PublicKey,
        advertiserSignature: Buffer,
        expectedBidderDelegateStateAfterUpdate: anchor.IdlAccounts<Dax>["bidderDelegate"],
        expectedBidderDelegateStateHash: Buffer,
    ): Promise<string> {

        // try {

        let tx = new anchor.web3.Transaction();
        const ed25519VerifyIxn = this.verifyEd25519SignatureIxn(
            expectedBidderDelegateStateHash,
            advertiserSignature,
            expectedBidderDelegateStateAfterUpdate.advertiser
        );
        tx.add(ed25519VerifyIxn);

        const ixn = await this.initOrUpdateBidderDelegateIxn(
            delegatePubkey, // Needed here since new expectedBidderDelegateStateAfterUpdate.delegate, may be systemProgram to nullify
            expectedBidderDelegateStateAfterUpdate.advertiser,
            expectedBidderDelegateStateAfterUpdate.delegate,
            expectedBidderDelegateStateAfterUpdate.nonce,
        );

        tx.add(ixn);
        tx.feePayer = this.publicKey;
        const provider = this.rollupProgram.provider as anchor.AnchorProvider;
        tx.recentBlockhash = (await this.rollupProgram.provider.connection.getLatestBlockhash()).blockhash;
        const txHash = await provider.sendAndConfirm(tx);
        return txHash

        // } catch (error) {
        //     if (error instanceof SendTransactionError) {
        //       console.error('SendTransactionError occurred:');
        //       console.error('Error message:', error.message);
              
        //       // Get and log the full details
        //       const logs = error.logs;
        //       if (logs) {
        //         console.error('Transaction logs:');
        //         logs.forEach((log, index) => {
        //           console.error(`Log ${index}:`, log);
        //         });
        //       } else {
        //         console.error('No logs available');
        //       }
              
        //       // You can also access other properties of the error
        //     console.error('Error name:', error.name);
        //     } else {
        //         console.error('An unexpected error occurred:', error);
        //     };
        // }

    }

    async initOrUpdateCampaignIxn(
        advertiserPubkey: PublicKey,
        campaignId: number,
        args: InitOrUpdateCampaignArgs,
    ): Promise<anchor.web3.TransactionInstruction> {
        const campaignPubkey = this.deriveCampaignPubkey(
            advertiserPubkey,
            campaignId
        );
        // Prepare the accounts
        const accounts = {
            payer: this.publicKey!,
            advertiser: advertiserPubkey,
            campaign: campaignPubkey,
            systemProgram: anchor.web3.SystemProgram.programId,
            instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
        };
        // TODO: Break this up if the package is likely too be too large
        const ixArgs = {
            id: campaignId,
            status: args.status ?? null,
            objective: args.objective ?? null,
            name: args.name !== undefined ? stringToFixedLengthArray(args.name) : null,
            startTimestamp: args.startTimestamp !== undefined ? new anchor.BN(args.startTimestamp) : null,
            endTimestamp: args.endTimestamp !== undefined ? new anchor.BN(args.endTimestamp) : null,
            budget: args.budget ?? null,
            bidding: args.bidding ?? null,
            ageRange: args.ageRange ?? null,
            netWorthRange: args.netWorthRange ?? null,
            incomeRange: args.incomeRange ?? null,
            genderInclusions: args.genderInclusions ?? null,
            genderExclusions: args.genderExclusions ?? null,
            regionInclusions: args.regionInclusions != undefined ? args.regionInclusions.map((r) => stringToFixedLengthArray(r, 2)) : null,
            regionExclusions: args.regionExclusions != undefined ? args.regionExclusions.map((r) => stringToFixedLengthArray(r, 2)) : null,
            eductionInclusions: args.eductionInclusions as any ?? null,
            eductionExclusions: args.eductionExclusions as any  ?? null,
            deviceTypeInclusions: args.deviceTypeInclusions ?? null,
            deviceTypeExclusions: args.deviceTypeExclusions ?? null,
            categoryInclusions: args.categoryInclusions ?? null,
            categoryExclusions: args.categoryExclusions ?? null,
            keywordInclusions: args.keywordInclusions ?? null,
            keywordExclusions: args.keywordExclusions ?? null,
            imageCreatives: args.imageCreatives ?? null,
            videoCreatives: args.videoCreatives ?? null,
            copyCreatives: args.copyCreatives ?? null,
        };
        return await this.baseProgram.methods
          .initOrUpdateCampaign(ixArgs)
          .accounts(accounts)
          .instruction()
    }

    async initOrUpdateCreativeIxn(
        advertiserPubkey: PublicKey,
        creativeId: number,
        args: InitOrUpdateCreativeArgs,
    ): Promise<anchor.web3.TransactionInstruction> {
        const creativePubkey = this.deriveCreativePubkey(
            advertiserPubkey,
            creativeId
        );
        // Prepare the accounts
        const accounts = {
            payer: this.publicKey!,
            advertiser: advertiserPubkey,
            creative: creativePubkey,
            systemProgram: anchor.web3.SystemProgram.programId,
            instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
        };

        // Prepare the instruction arguments
        const ixArgs = {
            id: creativeId,
            status: args.status ?? null,
            asset: args.asset ?? null,
            ctaUrl: args.ctaUrl ?? null,
            languages: args.languages ?? null,
        };

        return await this.baseProgram.methods
          .initOrUpdateCreative(ixArgs)
          .accounts(accounts)
          .instruction()
    }

    async initOrUpdateCampaign(
        advertiserPubkey: PublicKey,
        campaignId: number,
        args: InitOrUpdateCampaignArgs,
        nonceSignature: Buffer,
        nonceBytes: Buffer,
    ): Promise<string> {
        let tx = new anchor.web3.Transaction();
        const ed25519VerifyIxn = this.verifyEd25519SignatureIxn(
            nonceBytes,
            nonceSignature,
            advertiserPubkey
        );
        tx.add(ed25519VerifyIxn);
        const ixn = await this.initOrUpdateCampaignIxn(
            advertiserPubkey,
            campaignId,
            args,
        );
        tx.add(ixn);
        tx.feePayer = this.publicKey;
        const provider = this.rollupProgram.provider as anchor.AnchorProvider;
        tx.recentBlockhash = (await this.rollupProgram.provider.connection.getLatestBlockhash()).blockhash;
        const txHash = await provider.sendAndConfirm(tx);
        return txHash
    }

    async initOrUpdateCreative(
        advertiserPubkey: PublicKey,
        campaignId: number,
        args: InitOrUpdateCreativeArgs,
        nonceSignature: Buffer,
        nonceBytes: Buffer,
    ): Promise<string> {

        // try {

        let tx = new anchor.web3.Transaction();
        const ed25519VerifyIxn = this.verifyEd25519SignatureIxn(
            nonceBytes,
            nonceSignature,
            advertiserPubkey
        );
        tx.add(ed25519VerifyIxn);
        const ixn = await this.initOrUpdateCreativeIxn(
            advertiserPubkey,
            campaignId,
            args,
        );
        tx.add(ixn);
        tx.feePayer = this.publicKey;
        const provider = this.rollupProgram.provider as anchor.AnchorProvider;
        tx.recentBlockhash = (await this.rollupProgram.provider.connection.getLatestBlockhash()).blockhash;
        const txHash = await provider.sendAndConfirm(tx);
        return txHash

        // } catch (error) {
        //     if (error instanceof SendTransactionError) {
        //       console.error('SendTransactionError occurred:');
        //       console.error('Error message:', error.message);
              
        //       // Get and log the full details
        //       const logs = error.logs;
        //       if (logs) {
        //         console.error('Transaction logs:');
        //         logs.forEach((log, index) => {
        //           console.error(`Log ${index}:`, log);
        //         });
        //       } else {
        //         console.error('No logs available');
        //       }
              
        //       // You can also access other properties of the error
        //     console.error('Error name:', error.name);
        //     } else {
        //         console.error('An unexpected error occurred:', error);
        //     };
        // }

    }

    async submitBidIxn(
        bid: anchor.IdlTypes<Dax>["Bid"], 
        bidderSignature: Buffer,
        bidderPubkey: PublicKey
    ): Promise<anchor.web3.TransactionInstruction[]> {
        console.log(bid);
        const bidPubkey = this.deriveBidPubkey(bidderSignature);
        const auctionPubkey = this.deriveAuctionPubkey(anchor.utils.bytes.bs58.encode(bid.placementId));
        const bidBytes = this.rollupProgram.coder.types
            .encode("Bid", bid);
        const ed25519VerifyIxn = this.verifyEd25519SignatureIxn(
            bidBytes,
            bidderSignature,
            bidderPubkey
        );
        let accounts = {
            authority: this.publicKey,
            advertiser: bid.advertiser,
            advertiserBalance: this.deriveBalancePubkey(bid.advertiser),
            bidder: bidderPubkey,
            bidderDelegate: this.deriveBidderDelegatePubkey(bid.advertiser, bidderPubkey),
            bid: bidPubkey,
            auction: auctionPubkey,
            systemProgram: anchor.web3.SystemProgram.programId,
            instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
        }
        console.log(accounts);
        const ixn = await this.rollupProgram.methods
            .submitBid({})
            .accounts( accounts).instruction();

        return [ed25519VerifyIxn, ixn];
    }

    async acceptBidIxn(
        bid: anchor.IdlTypes<Dax>["Bid"],
        bidId: Buffer,
        sharedSecret: Buffer, 
        requestorSignature: Buffer,
        requestorPubkey: PublicKey
    ): Promise<anchor.web3.TransactionInstruction[]> {

        const bidPubkey = this.deriveBidPubkey(bidId);
        const auctionPubkey = this.deriveAuctionPubkey(anchor.utils.bytes.bs58.encode(Buffer.from(bid.placementId)));
        const ed25519VerifyIxn = this.verifyEd25519SignatureIxn(
            sharedSecret,
            requestorSignature,
            requestorPubkey
        );
        let accounts = {
            authority: this.publicKey,
            auction: auctionPubkey,
            bid: bidPubkey,
            requestor: requestorPubkey,
            systemProgram: anchor.web3.SystemProgram.programId,
            instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
        }
        const ixn = await this.rollupProgram.methods
            .acceptBid({})
            .accounts( accounts).instruction();

        return [ed25519VerifyIxn, ixn];
    }

    async commitPlacementIxns(
        placementId: string
    ): Promise<anchor.web3.TransactionInstruction[]> {
        const auctionPubkey = this.deriveAuctionPubkey(placementId);
        const auctionState = await this.fetchAuctionForPlacement(placementId);
        if (!auctionState) {
            throw new Error('Auction state not found');
        }
        const placementBytes = this.rollupProgram.coder.types
            .encode("Placement", auctionState.placement);
        const ed25519VerifyIxnPlacement = this.verifyEd25519SignatureIxn(
            placementBytes,
            Buffer.from(auctionState.requestorSignature),
            auctionState.placement.requestor
        );
        const bidState = await this.fetchBidByPubkey(auctionState.winningBid);
        if (!bidState) {
            throw new Error('Bid state not found');
        }
        const bidBytes = this.rollupProgram.coder.types
            .encode("Bid", bidState.bid);
        const ed25519VerifyIxnBid = this.verifyEd25519SignatureIxn(
            bidBytes,
            Buffer.from(bidState.bidderSignature),
            bidState.bidder
        );
        let accounts = {
            authority: this.publicKey,
            rollupNode: this.nodeAccountPublicKey,
            auction: auctionPubkey,
            requestor: auctionState.placement.requestor,
            publisherBalance: this.deriveBalancePubkey(auctionState.placement.publisher),
            advertiserBalance: this.deriveBalancePubkey(bidState.bid.advertiser),
            bidderDelegate: this.deriveBidderDelegatePubkey(bidState.bid.advertiser, bidState.bidder),
            systemProgram: anchor.web3.SystemProgram.programId,
            instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
        }
        const commitIxn = await this.baseProgram.methods
            .commitPlacement({
                sharedSecret: auctionState.sharedSecret
            })
            .accounts( accounts ).instruction();

        return [ed25519VerifyIxnPlacement, ed25519VerifyIxnBid, commitIxn];
    }

    async initializeNodeIxn(): Promise<anchor.web3.TransactionInstruction> {
        let accounts = {
            payer: this.publicKey,
            authority: this.publicKey,
            nodeAuthority: this.publicKey,
            rollupNode: this.nodeAccountPublicKey,
            systemProgram: anchor.web3.SystemProgram.programId,
        }
        const ixn = await this.baseProgram.methods
            .initRollupNode({}) // Pass the args object as the argument
            .accounts(accounts).instruction();
        return ixn;
    }
    

    async initializeNode(): Promise<string> {

        /// Will be a permissioned ixn in practice
        ///  Can self-permission for now / for testing

        let tx = new anchor.web3.Transaction()
        tx.add(await this.initializeNodeIxn());
        tx.feePayer = this.publicKey;
        const provider = this.rollupProgram.provider as anchor.AnchorProvider;
        tx.recentBlockhash = (await this.rollupProgram.provider.connection.getLatestBlockhash()).blockhash;
        const txHash = await provider.sendAndConfirm(tx);
        return txHash

        // TODO: Create a LUT for each Node
        //  to reduce the transaction side on commit back to base layer

    }

    
    async submitPlacement(
        placement: anchor.IdlTypes<Dax>["Placement"], 
        context: anchor.IdlTypes<Dax>["PlacementContext"],
        requestorSignature: Buffer,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ): Promise<string> {

        /// Simulate the REST Endpoint that receives a signed placement object
        ///  from the requestor and relays it to the network

        let tx = new anchor.web3.Transaction()
        tx.add(
            ...await this.listPlacementIxns(placement, context, requestorSignature)
        );
        tx.feePayer = this.publicKey;
        const provider = this.rollupProgram.provider as anchor.AnchorProvider;
        tx.recentBlockhash = (await this.rollupProgram.provider.connection.getLatestBlockhash()).blockhash;
        const txHash = await provider.sendAndConfirm(tx, [], {commitment: commitmentLevel});
        return txHash
        
    }

    async submitBid(
        bid: anchor.IdlTypes<Dax>["Bid"], 
        requestorSignature: Buffer,
        bidderPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ): Promise<string> {

        /// Simulate the REST Endpoint that receives a signed bid object
        ///  from the advertiser/bidder and relays it to the network

        let tx = new anchor.web3.Transaction()
        tx.add(
            ...await this.submitBidIxn(bid, requestorSignature, bidderPubkey)
        );
        tx.feePayer = this.publicKey;
        const provider = this.rollupProgram.provider as anchor.AnchorProvider;
        tx.recentBlockhash = (await this.rollupProgram.provider.connection.getLatestBlockhash()).blockhash;
        const txHash = await provider.sendAndConfirm(tx, [], {commitment: commitmentLevel});
        return txHash
        
    }

    async acceptBid(
        bid: anchor.IdlTypes<Dax>["Bid"],
        bidId: Buffer,
        sharedSecret: Buffer, 
        requestorSignature: Buffer,
        requestorPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ): Promise<string> {

        /// Simulate the REST Endpoint that receives a signed bid acceptance
        ///  from the requestor and relays it to the network

        let tx = new anchor.web3.Transaction()
        tx.add(
            ...await this.acceptBidIxn(bid, bidId, sharedSecret, requestorSignature, requestorPubkey)
        );
        tx.feePayer = this.publicKey;
        const provider = this.rollupProgram.provider as anchor.AnchorProvider;
        tx.recentBlockhash = (await this.rollupProgram.provider.connection.getLatestBlockhash()).blockhash;
        const txHash = await provider.sendAndConfirm(tx, [], {commitment: commitmentLevel});
        return txHash
        
    }
  
    async commitPlacementToBaseLayer(
        placementId: string,
    ): Promise<string> {

        /// Simulates the node committing the proof 
        ///  back to the base layer for settlement
        
        const auctionState = await this.fetchAuctionForPlacement(placementId);
        let tx = new anchor.web3.Transaction()
        if (!auctionState) {
            throw new Error('Auction state not found');
        }
        // TODO: Compute budget program?
        tx.add(
            ...await this.commitPlacementIxns( anchor.utils.bytes.bs58.encode(Buffer.from(auctionState.requestorSignature)) )
        );
        tx.feePayer = this.publicKey;
        const provider = this.rollupProgram.provider as anchor.AnchorProvider;
        tx.recentBlockhash = (await this.rollupProgram.provider.connection.getLatestBlockhash()).blockhash;
        // tx.sign(this.keypair);
        // console.log('TX package size:', tx.serialize().length);
        const txHash = await provider.sendAndConfirm(tx);
        return txHash  
    }
  

    

   
}