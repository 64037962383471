import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { NavLink, useLocation, Link} from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import Heading from '../Heading';
import { useAdvertiser } from '../../../context/AdvertiserContext';
import CreativeTable from './CreativeTable';
import ManageCreativeModal from './ManageCreativeModal';
import Alert from '../../common/Alert';
import PubkeyDisplay from '../../common/PubkeyDisplay';
import { PublicKey } from '@solana/web3.js';
import { getCreatives } from '../../../supabase';
import { useSolanaWallets } from '@privy-io/react-auth';

export default function Creatives() {

  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [creatives, setCreatives] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedCreative, setSelectedCreative] = useState<any>(null);
  const { advertiser } = useAdvertiser();

  const { wallets } = useSolanaWallets();
  const wallet = wallets[0];

  const handleRefreshCreatives = useCallback(async () => {
    // Fetch updated creatives from your data source
    // const updatedCreatives = await fetchCreatives();
    const creatives = await getCreatives({ advertiser: wallet.address });
    setCreatives(creatives);
  }, []);
  
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedCreative(null);
    handleRefreshCreatives();
  }, [handleRefreshCreatives]);

  const handleManageCreative = (creative: any): void => {
    setSelectedCreative(creative);
    setIsModalOpen(true);
  };

  const handleCreateNew = () => {
    console.log('Creating new creative...')
    setSelectedCreative(null);
    setIsModalOpen(true);
  };

  const handleEdit = (creative: any) => {
    setSelectedCreative(creative);
    setIsModalOpen(true);
  };

  const fetchCreatives = useCallback(async () => {
    if (!wallet || !wallet.address) {
        return;
        setCreatives([])
        setIsLoading(false)
    }
    try {
      console.log('Getting creatives...')
      // const ds = await advertiser.fetchAllCreatives();
      const ds = await getCreatives({ advertiser: wallet.address });
      console.log(ds);
      setCreatives(ds);
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching creatives:', error);
      setCreatives([])
      setIsLoading(false)
    }
  }, [wallet]);

  useEffect(() => {
    if (wallet && wallet.address) {
      fetchCreatives();
    } 
  }, [wallet]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (advertiser) {
  //       fetchCreatives();
  //     }
  //   }, 15000); // Fetch every 15 seconds
  
  //   return () => clearInterval(intervalId);
  // }, [advertiser, fetchCreatives]);

  // if (!wallet || !wallet.address) {

  //   return (
  //     <div></div>
  //   )

  // } else {

    return (
      <div>
        <Heading pageHeadingName='Creatives'>
          <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded inline-flex items-center" onClick={handleCreateNew}>New Item</button>
        </Heading>
        <CreativeTable 
          creatives={creatives} 
          isLoading={isLoading} 
          advertiser={advertiser}
          refreshCreatives={fetchCreatives}
          onManageCreative={handleManageCreative}
          isSelectionView={false}
          selectedCreatives={[]}
          onSelectCreative={() => {}}
        />
        {
            (advertiser && creatives) ? (
              <ManageCreativeModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                advertiser={advertiser}
                refreshCreatives={fetchCreatives}
                existingCreative={selectedCreative ? {
                  id: selectedCreative.num,
                  status: selectedCreative.status,
                  asset: selectedCreative.asset,
                  ctaUrl: selectedCreative.cta_url,
                  languages: selectedCreative.languages,
                } : null}
              />
            ) : ("")
        }
      </div>

    )
  // }
}

