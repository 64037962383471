import React, { useState, useEffect } from 'react';
import { useDaxProgram } from '../../../context/DaxProgramContext';
import { useToast } from '../../../context/ToastContext';
import { PublicKey } from '@solana/web3.js';
import { useSolanaWallets } from '@privy-io/react-auth';
import Advertiser from '../../../bindings/advertiser';
import { initOrUpdateBidderDelegate } from '../../../rollup';

interface BidderDelegateModalProps {
  isOpen: boolean;
  onClose: () => void;
  existingDelegates: PublicKey[];
  advertiser: Advertiser,
  refreshDelegates: () => void;
}

const Spinner = () => (
  <div className="animate-spin rounded-full h-4 w-4 border-2 border-indigo-500 border-t-transparent"></div>
);

const BidderDelegateModal: React.FC<BidderDelegateModalProps> = ({ isOpen, onClose, existingDelegates, advertiser, refreshDelegates }) => {
  const [delegatePubkey, setDelegatePubkey] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { program } = useDaxProgram();
  const { showToast } = useToast();

  const { wallets } = useSolanaWallets();
  const wallet = wallets[0];

  const [publicKey, setPublicKey] = useState<PublicKey | undefined>(undefined);
  useEffect(() => {
    if (wallet) {
      setPublicKey(new PublicKey(wallet.address));
    } else {
      setPublicKey(undefined);
    }
  }, [wallet]);

  useEffect(() => {
    validateInput();
  }, [delegatePubkey, existingDelegates]);

  const validateInput = () => {
    if (!delegatePubkey) {
      setError('Please enter a public key');
      return;
    }

    try {
      const pubkey = new PublicKey(delegatePubkey);

      if (!PublicKey.isOnCurve(pubkey.toBuffer())) {
        setError('Invalid public key: not on ed25519 curve');
        return;
      }

      console.log(existingDelegates)
      if (existingDelegates.some(delegate => delegate.toString() == pubkey.toString())) {
        setError('This public key is already a delegate');
        return;
      }

    } catch (err) {
      // console.error(err)
      setError('Invalid public key format');
      return;
    }

    setError(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!publicKey || !program || !delegatePubkey || error) return;

    setIsLoading(true);

    try {
      const delegatePubkeyObject = new PublicKey(delegatePubkey);
      // await advertiser.initBidderDelegate(delegatePubkeyObject);
      await initOrUpdateBidderDelegate(wallet, advertiser, delegatePubkeyObject, false);

      showToast('Successfully added bidder delegate', 'success');
      refreshDelegates();
      setDelegatePubkey('')
      onClose();
    } catch (error: any) {
      console.error("Transaction error:", error);
      showToast(`Failed to add bidder delegate: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl w-96 text-gray-900 dark:text-gray-100">
        <h2 className="text-2xl font-bold mb-4">
          Add Bidder Delegate
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Delegate Public Key
            </label>
            <input
              type="text"
              value={delegatePubkey}
              onChange={(e) => setDelegatePubkey(e.target.value)}
              placeholder="Enter public key"
              className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-indigo-500 dark:focus:border-indigo-400 transition-colors"
            />
          </div>
          {error && <p className="text-red-500 dark:text-red-400 text-sm">{error}</p>}
          <div className="flex justify-end space-x-2">
            <button
              type="submit"
              disabled={isLoading || !!error}
              className="px-4 py-2 bg-indigo-600 dark:bg-indigo-500 text-white rounded hover:bg-indigo-700 dark:hover:bg-indigo-600 transition-colors disabled:opacity-50 flex items-center justify-center"
            >
              {isLoading ? (
                <>
                  <Spinner />
                  <span className="ml-2">Processing...</span>
                </>
              ) : (
                'Add Delegate'
              )}
            </button>
            <button
              type="button"
              onClick={()=>{setDelegatePubkey(''); onClose()}}
              className="px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-400 dark:hover:bg-gray-500 transition-colors"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BidderDelegateModal;