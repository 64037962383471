import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PubkeyDisplay from '../../common/PubkeyDisplay';
import { PublicKey } from '@solana/web3.js';
import Advertiser from '../../../bindings/advertiser';
import { useToast } from '../../../context/ToastContext';

interface CreativeTableProps {
  creatives: any[];
  isLoading: boolean;
  advertiser: Advertiser | null;
  refreshCreatives: () => void;
  onManageCreative: (creative: any) => void; 
  isSelectionView?: boolean;
  onSelectCreative: (creative: any, checked: boolean) => void;
  selectedCreatives: any[];
}

const CreativeTable: React.FC<CreativeTableProps> = ({ creatives, isLoading, advertiser, refreshCreatives, onManageCreative, isSelectionView = false, onSelectCreative = () => {}, selectedCreatives = [] }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const renderPreview = (creative: any) => {
    const asset = creative.asset;
    const maxSize = 100; // Maximum width or height in pixels

    if ('Image' in asset) {
      return (
        <div style={{ width: `${maxSize}px`, height: `${maxSize}px`, overflow: 'hidden' }}>
          <img 
            src={asset.Image.uri} 
            alt="Creative Preview" 
            style={{ 
              width: '100%', 
              height: '100%', 
              objectFit: 'cover'
            }}
          />
        </div>
      );
    } else if ('Video' in asset) {
      return (
        <div style={{ width: `${maxSize}px`, height: `${maxSize}px`, overflow: 'hidden' }}>
          <video 
            src={asset.Video.uri} 
            style={{ 
              width: '100%', 
              height: '100%', 
              objectFit: 'cover'
            }}
            muted 
            loop 
            autoPlay 
          />
        </div>
      );
    } else if ('copy' in asset) {
      return (
        <div style={{ 
          width: `${maxSize}px`, 
          height: `${maxSize}px`, 
          overflow: 'hidden', 
          padding: '5px',
          fontSize: '12px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: '#f0f0f0',
          color: '#333',
        }}>
          <strong>{asset.copy.title}</strong>
          <span>{asset.copy.body.substring(0, 50)}...</span>
          <em>{asset.copy.ctaText}</em>
        </div>
      );
    } else {
      return (
        <div style={{ 
          width: `${maxSize}px`, 
          height: `${maxSize}px`, 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f0f0f0',
          color: '#333',
        }}>
          Unknown Asset Type
        </div>
      );
    }
  };

  const renderLanguages = (languages: any[]) => {
    // return languages.map(lang => Object.keys(lang)[0]).join(', ');
    return languages.join(', ');
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tbody>
          {[...Array(3)].map((_, idx) => (
            <tr key={`skeleton-${idx}`}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4 animate-pulse"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-20 w-20 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4 animate-pulse"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2 animate-pulse"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right">
                <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-20 ml-auto animate-pulse"></div>
              </td>
            </tr>
          ))}
        </tbody>
      );
    }

    if (creatives.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={5} className="px-6 py-4 text-center text-gray-500 dark:text-gray-400">
              No creatives found. Add a creative to get started.
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody>
        {creatives.map((creative, idx) => (
          <tr key={creative.id}>
            {isSelectionView && (
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                <input type="checkbox" checked={selectedCreatives.includes(creative.id)} onChange={(e) => onSelectCreative(creative, e.target.checked)} />
              </td>
            )}
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
              {creative.num}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {renderPreview(creative)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
              <PubkeyDisplay pubkey={creative.id} />
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
              {renderLanguages(creative.languages)}
            </td>
            {!isSelectionView && (
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button
                  type="button"
                  onClick={() => onManageCreative(creative)}
                className="inline-flex items-center rounded-md bg-white dark:bg-gray-700 px-2.5 py-1.5 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-700 transition-colors duration-200"
              >
                Manage
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    );
  };

  // ... (rest of the component remains the same)

  return (
    <div className="transition-colors duration-200">
      <div className="-mx-4 mt-10 ring-1 ring-gray-300 dark:ring-gray-700 sm:mx-0 sm:rounded-lg bg-white dark:bg-gray-800">
        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
          <thead className="bg-gray-200 dark:bg-gray-900 rounded-t-lg">
            <tr>
              {isSelectionView && (
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                  Selection
                </th>
              )}
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                ID
              </th>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                Preview
              </th>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                Pubkey
              </th>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                Languages
              </th>
              {!isSelectionView && (
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Actions</span>
                </th>
              )}
            </tr>
          </thead>
          {renderTableBody()}
        </table>
      </div>
    </div>
  );
}

export default CreativeTable;