export type Dax = {
  "version": "0.1.0",
  "name": "dax",
  "instructions": [
    {
      "name": "initRollupNode",
      "docs": [
        "Base Layer Ixns"
      ],
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "nodeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rollupNode",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InitRollupNodeArgs"
          }
        }
      ]
    },
    {
      "name": "initBalance",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "balance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InitBalanceArgs"
          }
        }
      ]
    },
    {
      "name": "initOrUpdateBidderDelegate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "advertiser",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "balance",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bidderDelegate",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InitOrUpdateBidderDelegateArgs"
          }
        }
      ]
    },
    {
      "name": "revokeBidderDelegate",
      "accounts": [
        {
          "name": "advertiser",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "delegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bidderDelegate",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "RevokeBidderDelegateArgs"
          }
        }
      ]
    },
    {
      "name": "balanceDepositWithdraw",
      "accounts": [
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "balance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "BalanceDepositWithdrawArgs"
          }
        }
      ]
    },
    {
      "name": "commitPlacement",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true,
          "docs": [
            "The signer of the rollup node"
          ]
        },
        {
          "name": "rollupNode",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "The account reflecting that this signer is a permissioned node"
          ]
        },
        {
          "name": "auction",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "requestor",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "publisherBalance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "advertiserBalance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidderDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "CommitPlacementArgs"
          }
        }
      ]
    },
    {
      "name": "initProperty",
      "docs": [
        "Rollup Ixns"
      ],
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "publisher",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "balance",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "property",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InitPropertyArgs"
          }
        }
      ]
    },
    {
      "name": "initOrUpdateCampaign",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "advertiser",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "campaign",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InitOrUpdateCampaignArgs"
          }
        }
      ]
    },
    {
      "name": "initOrUpdateCreative",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "advertiser",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "creative",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InitOrUpdateCreativeArgs"
          }
        }
      ]
    },
    {
      "name": "listPlacement",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "requestor",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "nonce",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "Nonce account for the requestor"
          ]
        },
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "publisher",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "property",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "ListPlacementArgs"
          }
        }
      ]
    },
    {
      "name": "submitBid",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "advertiser",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "advertiserBalance",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bidder",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bidderDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bid",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "SubmitBidArgs"
          }
        }
      ]
    },
    {
      "name": "acceptBid",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "bid",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "requestor",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "AcceptBidArgs"
          }
        }
      ]
    },
    {
      "name": "closeBid",
      "accounts": [
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bid",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidder",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "CloseBidArgs"
          }
        }
      ]
    },
    {
      "name": "confirmCommit",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true,
          "docs": [
            "The signer of the rollup node"
          ]
        },
        {
          "name": "rollupNode",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "The account reflecting that this signer is a permissioned node"
          ]
        },
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bid",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidder",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "ConfirmCommitArgs"
          }
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "balance",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "credits",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "bidderDelegate",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "advertiser",
            "type": "publicKey"
          },
          {
            "name": "delegate",
            "type": "publicKey"
          },
          {
            "name": "nonce",
            "type": "u16"
          }
        ]
      }
    },
    {
      "name": "rollupNode",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "credits",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "auctionBid",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "auction",
            "type": "publicKey"
          },
          {
            "name": "bidder",
            "type": "publicKey"
          },
          {
            "name": "bid",
            "type": {
              "defined": "Bid"
            }
          },
          {
            "name": "bidderSignature",
            "type": {
              "array": [
                "u8",
                64
              ]
            }
          }
        ]
      }
    },
    {
      "name": "auction",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "publisher",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "PlacementStatus"
            }
          },
          {
            "name": "countBids",
            "type": "u32"
          },
          {
            "name": "placement",
            "type": {
              "defined": "Placement"
            }
          },
          {
            "name": "requestorSignature",
            "type": {
              "array": [
                "u8",
                64
              ]
            }
          },
          {
            "name": "context",
            "type": {
              "defined": "PlacementContext"
            }
          },
          {
            "name": "winningBid",
            "type": "publicKey"
          },
          {
            "name": "contentId",
            "type": "publicKey"
          },
          {
            "name": "advertiser",
            "type": "publicKey"
          },
          {
            "name": "bidValue",
            "type": "u64"
          },
          {
            "name": "sharedSecret",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          },
          {
            "name": "attestorSignature",
            "type": {
              "array": [
                "u8",
                64
              ]
            }
          }
        ]
      }
    },
    {
      "name": "campaign",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "advertiser",
            "type": "publicKey"
          },
          {
            "name": "campaignId",
            "type": "u32"
          },
          {
            "name": "status",
            "type": {
              "defined": "CampaignStatus"
            }
          },
          {
            "name": "objective",
            "type": {
              "defined": "Objective"
            }
          },
          {
            "name": "nonce",
            "type": "u16"
          },
          {
            "name": "name",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          },
          {
            "name": "startTimestamp",
            "type": "i64"
          },
          {
            "name": "endTimestamp",
            "type": "i64"
          },
          {
            "name": "budget",
            "type": {
              "defined": "Budget"
            }
          },
          {
            "name": "bidding",
            "type": {
              "defined": "Bidding"
            }
          },
          {
            "name": "ageRange",
            "type": {
              "option": {
                "array": [
                  "u8",
                  2
                ]
              }
            }
          },
          {
            "name": "netWorthRange",
            "type": {
              "option": {
                "array": [
                  "u32",
                  2
                ]
              }
            }
          },
          {
            "name": "incomeRange",
            "type": {
              "option": {
                "array": [
                  "u32",
                  2
                ]
              }
            }
          },
          {
            "name": "genderInclusions",
            "type": {
              "vec": {
                "defined": "Gender"
              }
            }
          },
          {
            "name": "genderExclusions",
            "type": {
              "vec": {
                "defined": "Gender"
              }
            }
          },
          {
            "name": "regionInclusions",
            "type": {
              "vec": {
                "array": [
                  "u8",
                  2
                ]
              }
            }
          },
          {
            "name": "regionExclusions",
            "type": {
              "vec": {
                "array": [
                  "u8",
                  2
                ]
              }
            }
          },
          {
            "name": "eductionInclusions",
            "type": {
              "vec": {
                "defined": "Education"
              }
            }
          },
          {
            "name": "eductionExclusions",
            "type": {
              "vec": {
                "defined": "Education"
              }
            }
          },
          {
            "name": "deviceTypeInclusions",
            "type": {
              "vec": {
                "defined": "DeviceType"
              }
            }
          },
          {
            "name": "deviceTypeExclusions",
            "type": {
              "vec": {
                "defined": "DeviceType"
              }
            }
          },
          {
            "name": "categoryInclusions",
            "type": {
              "vec": {
                "defined": "PropertyCategory"
              }
            }
          },
          {
            "name": "categoryExclusions",
            "type": {
              "vec": {
                "defined": "PropertyCategory"
              }
            }
          },
          {
            "name": "keywordInclusions",
            "type": {
              "vec": "string"
            }
          },
          {
            "name": "keywordExclusions",
            "type": {
              "vec": "string"
            }
          },
          {
            "name": "imageCreatives",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "videoCreatives",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "copyCreatives",
            "type": {
              "vec": "publicKey"
            }
          }
        ]
      }
    },
    {
      "name": "creative",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "advertiser",
            "type": "publicKey"
          },
          {
            "name": "id",
            "type": "u32"
          },
          {
            "name": "nonce",
            "type": "u16"
          },
          {
            "name": "status",
            "type": {
              "defined": "CreativeStatus"
            }
          },
          {
            "name": "ctaUrl",
            "type": "string"
          },
          {
            "name": "asset",
            "type": {
              "defined": "CreativeAsset"
            }
          },
          {
            "name": "languages",
            "type": {
              "vec": {
                "defined": "Language"
              }
            }
          }
        ]
      }
    },
    {
      "name": "nonce",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "nonce",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "property",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "publisher",
            "type": "publicKey"
          },
          {
            "name": "id",
            "type": "u32"
          },
          {
            "name": "url",
            "type": "string"
          },
          {
            "name": "nonce",
            "type": "u16"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "BalanceDepositWithdrawArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "delta",
            "type": "i128"
          }
        ]
      }
    },
    {
      "name": "CommitPlacementArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "sharedSecret",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      }
    },
    {
      "name": "InitBalanceArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "InitOrUpdateBidderDelegateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "revoke",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "InitOrUpdateCampaignArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u32"
          },
          {
            "name": "status",
            "type": {
              "option": {
                "defined": "CampaignStatus"
              }
            }
          },
          {
            "name": "objective",
            "type": {
              "option": {
                "defined": "Objective"
              }
            }
          },
          {
            "name": "name",
            "type": {
              "option": {
                "array": [
                  "u8",
                  32
                ]
              }
            }
          },
          {
            "name": "startTimestamp",
            "type": {
              "option": "i64"
            }
          },
          {
            "name": "endTimestamp",
            "type": {
              "option": "i64"
            }
          },
          {
            "name": "budget",
            "type": {
              "option": {
                "defined": "Budget"
              }
            }
          },
          {
            "name": "bidding",
            "type": {
              "option": {
                "defined": "Bidding"
              }
            }
          },
          {
            "name": "ageRange",
            "type": {
              "option": {
                "option": {
                  "array": [
                    "u8",
                    2
                  ]
                }
              }
            }
          },
          {
            "name": "netWorthRange",
            "type": {
              "option": {
                "option": {
                  "array": [
                    "u32",
                    2
                  ]
                }
              }
            }
          },
          {
            "name": "incomeRange",
            "type": {
              "option": {
                "option": {
                  "array": [
                    "u32",
                    2
                  ]
                }
              }
            }
          },
          {
            "name": "genderInclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "Gender"
                }
              }
            }
          },
          {
            "name": "genderExclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "Gender"
                }
              }
            }
          },
          {
            "name": "regionInclusions",
            "type": {
              "option": {
                "vec": {
                  "array": [
                    "u8",
                    2
                  ]
                }
              }
            }
          },
          {
            "name": "regionExclusions",
            "type": {
              "option": {
                "vec": {
                  "array": [
                    "u8",
                    2
                  ]
                }
              }
            }
          },
          {
            "name": "eductionInclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "Education"
                }
              }
            }
          },
          {
            "name": "eductionExclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "Education"
                }
              }
            }
          },
          {
            "name": "deviceTypeInclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "DeviceType"
                }
              }
            }
          },
          {
            "name": "deviceTypeExclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "DeviceType"
                }
              }
            }
          },
          {
            "name": "categoryInclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "PropertyCategory"
                }
              }
            }
          },
          {
            "name": "categoryExclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "PropertyCategory"
                }
              }
            }
          },
          {
            "name": "keywordInclusions",
            "type": {
              "option": {
                "vec": "string"
              }
            }
          },
          {
            "name": "keywordExclusions",
            "type": {
              "option": {
                "vec": "string"
              }
            }
          },
          {
            "name": "imageCreatives",
            "type": {
              "option": {
                "vec": "publicKey"
              }
            }
          },
          {
            "name": "videoCreatives",
            "type": {
              "option": {
                "vec": "publicKey"
              }
            }
          },
          {
            "name": "copyCreatives",
            "type": {
              "option": {
                "vec": "publicKey"
              }
            }
          }
        ]
      }
    },
    {
      "name": "InitOrUpdateCreativeArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u32"
          },
          {
            "name": "status",
            "type": {
              "option": {
                "defined": "CreativeStatus"
              }
            }
          },
          {
            "name": "asset",
            "type": {
              "option": {
                "defined": "CreativeAsset"
              }
            }
          },
          {
            "name": "ctaUrl",
            "type": {
              "option": "string"
            }
          },
          {
            "name": "languages",
            "type": {
              "option": {
                "vec": {
                  "defined": "Language"
                }
              }
            }
          }
        ]
      }
    },
    {
      "name": "InitPropertyArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u32"
          },
          {
            "name": "url",
            "type": "string"
          }
        ]
      }
    },
    {
      "name": "InitRollupNodeArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "RevokeBidderDelegateArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "AcceptBidArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "CloseBidArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "ConfirmCommitArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "ListPlacementArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "context",
            "type": {
              "defined": "PlacementContext"
            }
          }
        ]
      }
    },
    {
      "name": "SubmitBidArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "Bid",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "placementId",
            "type": {
              "array": [
                "u8",
                64
              ]
            }
          },
          {
            "name": "advertiser",
            "type": "publicKey"
          },
          {
            "name": "sharedSecretCommitment",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          },
          {
            "name": "encryptedValue",
            "type": {
              "defined": "EciesCiphertext"
            }
          }
        ]
      }
    },
    {
      "name": "PlacementContext",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "creative",
            "type": {
              "defined": "CreativeContext"
            }
          },
          {
            "name": "property",
            "type": {
              "vec": {
                "defined": "PropertyContext"
              }
            }
          },
          {
            "name": "user",
            "type": {
              "vec": {
                "defined": "UserContext"
              }
            }
          }
        ]
      }
    },
    {
      "name": "EciesCiphertext",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "ephemeralPublicKey",
            "type": {
              "array": [
                "u8",
                33
              ]
            }
          },
          {
            "name": "encryptedData",
            "type": "bytes"
          },
          {
            "name": "authTag",
            "type": {
              "array": [
                "u8",
                16
              ]
            }
          }
        ]
      }
    },
    {
      "name": "Placement",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "requestor",
            "type": "publicKey"
          },
          {
            "name": "nonce",
            "type": "u64"
          },
          {
            "name": "publisher",
            "type": "publicKey"
          },
          {
            "name": "propertyId",
            "type": "u32"
          },
          {
            "name": "elGamalPublicKey",
            "type": {
              "array": [
                "u8",
                33
              ]
            }
          },
          {
            "name": "contextHash",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      }
    },
    {
      "name": "CampaignStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "Paused"
          },
          {
            "name": "Completed"
          },
          {
            "name": "Cancelled"
          }
        ]
      }
    },
    {
      "name": "Objective",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "Impressions"
          },
          {
            "name": "Conversions"
          }
        ]
      }
    },
    {
      "name": "Budget",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "Daily",
            "fields": [
              {
                "name": "max_spend_per_day",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Total",
            "fields": [
              {
                "name": "campaign_total",
                "type": "u64"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "Bidding",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CostPerImpression",
            "fields": [
              {
                "name": "amount",
                "type": "u64"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "CreativeStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "Suspended"
          }
        ]
      }
    },
    {
      "name": "CreativeAsset",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "Image",
            "fields": [
              {
                "name": "uri",
                "type": "string"
              },
              {
                "name": "width",
                "type": "u16"
              },
              {
                "name": "height",
                "type": "u16"
              },
              {
                "name": "max_width",
                "type": "u16"
              },
              {
                "name": "max_height",
                "type": "u16"
              },
              {
                "name": "min_width",
                "type": "u16"
              },
              {
                "name": "min_height",
                "type": "u16"
              }
            ]
          },
          {
            "name": "Video",
            "fields": [
              {
                "name": "uri",
                "type": "string"
              },
              {
                "name": "width",
                "type": "u16"
              },
              {
                "name": "height",
                "type": "u16"
              },
              {
                "name": "duration",
                "type": "u16"
              },
              {
                "name": "max_width",
                "type": "u16"
              },
              {
                "name": "max_height",
                "type": "u16"
              },
              {
                "name": "min_width",
                "type": "u16"
              },
              {
                "name": "min_height",
                "type": "u16"
              },
              {
                "name": "min_duration",
                "type": "u16"
              }
            ]
          },
          {
            "name": "Copy",
            "fields": [
              {
                "name": "title",
                "type": "string"
              },
              {
                "name": "body",
                "type": "string"
              },
              {
                "name": "cta_text",
                "type": "string"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "Language",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "En"
          },
          {
            "name": "Cn"
          },
          {
            "name": "Fr"
          },
          {
            "name": "Es"
          },
          {
            "name": "De"
          }
        ]
      }
    },
    {
      "name": "CreativeContext",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "Image",
            "fields": [
              {
                "name": "width",
                "type": "u16"
              },
              {
                "name": "height",
                "type": "u16"
              }
            ]
          },
          {
            "name": "Video",
            "fields": [
              {
                "name": "width",
                "type": "u16"
              },
              {
                "name": "height",
                "type": "u16"
              },
              {
                "name": "max_duration",
                "type": "u16"
              }
            ]
          },
          {
            "name": "Copy",
            "fields": [
              {
                "name": "title_chars",
                "type": "u16"
              },
              {
                "name": "body_chars",
                "type": "u16"
              },
              {
                "name": "cta_chars",
                "type": "u16"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "PropertyContext",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "DeviceType",
            "fields": [
              {
                "name": "value",
                "type": {
                  "defined": "DeviceType"
                }
              }
            ]
          },
          {
            "name": "Category",
            "fields": [
              {
                "name": "value",
                "type": {
                  "defined": "PropertyCategory"
                }
              }
            ]
          },
          {
            "name": "Keyword",
            "fields": [
              {
                "name": "value",
                "type": "string"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "UserContext",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Age",
            "fields": [
              {
                "name": "value",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Gender",
            "fields": [
              {
                "name": "value",
                "type": {
                  "defined": "Gender"
                }
              }
            ]
          },
          {
            "name": "CountryCode",
            "fields": [
              {
                "name": "value",
                "type": {
                  "array": [
                    "u8",
                    2
                  ]
                }
              }
            ]
          },
          {
            "name": "NetWorth",
            "fields": [
              {
                "name": "value",
                "type": "u32"
              }
            ]
          },
          {
            "name": "Income",
            "fields": [
              {
                "name": "value",
                "type": "u32"
              }
            ]
          },
          {
            "name": "Education",
            "fields": [
              {
                "name": "value",
                "type": {
                  "defined": "Education"
                }
              }
            ]
          }
        ]
      }
    },
    {
      "name": "DeviceType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Desktop"
          },
          {
            "name": "MobileWeb"
          },
          {
            "name": "NativeApp"
          }
        ]
      }
    },
    {
      "name": "PropertyCategory",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "News"
          },
          {
            "name": "Sports"
          },
          {
            "name": "Entertainment"
          },
          {
            "name": "Finance"
          },
          {
            "name": "Lifestyle"
          },
          {
            "name": "Other"
          }
        ]
      }
    },
    {
      "name": "Gender",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Male"
          },
          {
            "name": "Female"
          },
          {
            "name": "Other"
          }
        ]
      }
    },
    {
      "name": "Education",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "HighSchool"
          },
          {
            "name": "Bachelors"
          },
          {
            "name": "Masters"
          },
          {
            "name": "Phd"
          },
          {
            "name": "Other"
          }
        ]
      }
    },
    {
      "name": "PlacementStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "OpenToBids"
          },
          {
            "name": "PlacementMatched"
          },
          {
            "name": "Expired"
          }
        ]
      }
    }
  ],
  "events": [
    {
      "name": "PlacementSettled",
      "fields": [
        {
          "name": "placementId",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "node",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "auction",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "requestor",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "publisher",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "publisherBalance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "advertiser",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "advertiserBalance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "placement",
          "type": {
            "defined": "Placement"
          },
          "index": false
        },
        {
          "name": "winningBid",
          "type": {
            "defined": "Bid"
          },
          "index": false
        },
        {
          "name": "bidValue",
          "type": "u64",
          "index": false
        },
        {
          "name": "fee",
          "type": "u64",
          "index": false
        },
        {
          "name": "creativeId",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "sharedSecret",
          "type": {
            "array": [
              "u8",
              32
            ]
          },
          "index": false
        },
        {
          "name": "requestorSignature",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "bidderSignature",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "AuctionStarted",
      "fields": [
        {
          "name": "placementId",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "auction",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "publisher",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "property",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "auctionEnds",
          "type": "i64",
          "index": false
        },
        {
          "name": "context",
          "type": {
            "defined": "PlacementContext"
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        },
        {
          "name": "placement",
          "type": {
            "defined": "Placement"
          },
          "index": false
        }
      ]
    },
    {
      "name": "BidPlaced",
      "fields": [
        {
          "name": "placementId",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "auction",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "publisher",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "advertiser",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "bidder",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        },
        {
          "name": "encryptedValue",
          "type": {
            "defined": "EciesCiphertext"
          },
          "index": false
        }
      ]
    },
    {
      "name": "PlacementMatched",
      "fields": [
        {
          "name": "placementId",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "auction",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "publisher",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "advertiser",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "encryptedValue",
          "type": {
            "defined": "EciesCiphertext"
          },
          "index": false
        },
        {
          "name": "bidValue",
          "type": "u64",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "AuctionAlreadyClosed"
    },
    {
      "code": 6001,
      "name": "InvalidKey"
    },
    {
      "code": 6002,
      "name": "DecryptionFailed"
    },
    {
      "code": 6003,
      "name": "InvalidDecryptedValue"
    },
    {
      "code": 6004,
      "name": "InvalidFeature"
    },
    {
      "code": 6005,
      "name": "InvalidNodeAuthority"
    },
    {
      "code": 6006,
      "name": "InvalidContext"
    },
    {
      "code": 6007,
      "name": "InvalidBidder"
    },
    {
      "code": 6008,
      "name": "InvalidBid"
    },
    {
      "code": 6009,
      "name": "InvalidPublisher"
    },
    {
      "code": 6010,
      "name": "InvalidAdvertiser"
    },
    {
      "code": 6011,
      "name": "InvalidRequestor"
    },
    {
      "code": 6012,
      "name": "InvalidAuction"
    },
    {
      "code": 6013,
      "name": "InvalidDerivedAccount"
    },
    {
      "code": 6014,
      "name": "InvalidProgam"
    },
    {
      "code": 6015,
      "name": "InvalidProperty"
    },
    {
      "code": 6016,
      "name": "UnauthorizedRollupNode"
    },
    {
      "code": 6017,
      "name": "CannotCloseWinningBid"
    },
    {
      "code": 6018,
      "name": "CannotCloseBidWhileAuctionOpen"
    },
    {
      "code": 6019,
      "name": "ValidationFailed"
    },
    {
      "code": 6020,
      "name": "MustBeMinimallyComplete"
    },
    {
      "code": 6021,
      "name": "UpdateHashMismatch"
    },
    {
      "code": 6022,
      "name": "InvalidNonce"
    }
  ]
};

export const IDL: Dax = {
  "version": "0.1.0",
  "name": "dax",
  "instructions": [
    {
      "name": "initRollupNode",
      "docs": [
        "Base Layer Ixns"
      ],
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "nodeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rollupNode",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InitRollupNodeArgs"
          }
        }
      ]
    },
    {
      "name": "initBalance",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "balance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InitBalanceArgs"
          }
        }
      ]
    },
    {
      "name": "initOrUpdateBidderDelegate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "advertiser",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "balance",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bidderDelegate",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InitOrUpdateBidderDelegateArgs"
          }
        }
      ]
    },
    {
      "name": "revokeBidderDelegate",
      "accounts": [
        {
          "name": "advertiser",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "delegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bidderDelegate",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "RevokeBidderDelegateArgs"
          }
        }
      ]
    },
    {
      "name": "balanceDepositWithdraw",
      "accounts": [
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "balance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "BalanceDepositWithdrawArgs"
          }
        }
      ]
    },
    {
      "name": "commitPlacement",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true,
          "docs": [
            "The signer of the rollup node"
          ]
        },
        {
          "name": "rollupNode",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "The account reflecting that this signer is a permissioned node"
          ]
        },
        {
          "name": "auction",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "requestor",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "publisherBalance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "advertiserBalance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidderDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "CommitPlacementArgs"
          }
        }
      ]
    },
    {
      "name": "initProperty",
      "docs": [
        "Rollup Ixns"
      ],
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "publisher",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "balance",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "property",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InitPropertyArgs"
          }
        }
      ]
    },
    {
      "name": "initOrUpdateCampaign",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "advertiser",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "campaign",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InitOrUpdateCampaignArgs"
          }
        }
      ]
    },
    {
      "name": "initOrUpdateCreative",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "advertiser",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "creative",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InitOrUpdateCreativeArgs"
          }
        }
      ]
    },
    {
      "name": "listPlacement",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "requestor",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "nonce",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "Nonce account for the requestor"
          ]
        },
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "publisher",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "property",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "ListPlacementArgs"
          }
        }
      ]
    },
    {
      "name": "submitBid",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "advertiser",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "advertiserBalance",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bidder",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bidderDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bid",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "SubmitBidArgs"
          }
        }
      ]
    },
    {
      "name": "acceptBid",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "bid",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "requestor",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instructionSysvarAccount",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "AcceptBidArgs"
          }
        }
      ]
    },
    {
      "name": "closeBid",
      "accounts": [
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bid",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidder",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "CloseBidArgs"
          }
        }
      ]
    },
    {
      "name": "confirmCommit",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true,
          "docs": [
            "The signer of the rollup node"
          ]
        },
        {
          "name": "rollupNode",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "The account reflecting that this signer is a permissioned node"
          ]
        },
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bid",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidder",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "ConfirmCommitArgs"
          }
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "balance",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "credits",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "bidderDelegate",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "advertiser",
            "type": "publicKey"
          },
          {
            "name": "delegate",
            "type": "publicKey"
          },
          {
            "name": "nonce",
            "type": "u16"
          }
        ]
      }
    },
    {
      "name": "rollupNode",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "credits",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "auctionBid",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "auction",
            "type": "publicKey"
          },
          {
            "name": "bidder",
            "type": "publicKey"
          },
          {
            "name": "bid",
            "type": {
              "defined": "Bid"
            }
          },
          {
            "name": "bidderSignature",
            "type": {
              "array": [
                "u8",
                64
              ]
            }
          }
        ]
      }
    },
    {
      "name": "auction",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "publisher",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "PlacementStatus"
            }
          },
          {
            "name": "countBids",
            "type": "u32"
          },
          {
            "name": "placement",
            "type": {
              "defined": "Placement"
            }
          },
          {
            "name": "requestorSignature",
            "type": {
              "array": [
                "u8",
                64
              ]
            }
          },
          {
            "name": "context",
            "type": {
              "defined": "PlacementContext"
            }
          },
          {
            "name": "winningBid",
            "type": "publicKey"
          },
          {
            "name": "contentId",
            "type": "publicKey"
          },
          {
            "name": "advertiser",
            "type": "publicKey"
          },
          {
            "name": "bidValue",
            "type": "u64"
          },
          {
            "name": "sharedSecret",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          },
          {
            "name": "attestorSignature",
            "type": {
              "array": [
                "u8",
                64
              ]
            }
          }
        ]
      }
    },
    {
      "name": "campaign",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "advertiser",
            "type": "publicKey"
          },
          {
            "name": "campaignId",
            "type": "u32"
          },
          {
            "name": "status",
            "type": {
              "defined": "CampaignStatus"
            }
          },
          {
            "name": "objective",
            "type": {
              "defined": "Objective"
            }
          },
          {
            "name": "nonce",
            "type": "u16"
          },
          {
            "name": "name",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          },
          {
            "name": "startTimestamp",
            "type": "i64"
          },
          {
            "name": "endTimestamp",
            "type": "i64"
          },
          {
            "name": "budget",
            "type": {
              "defined": "Budget"
            }
          },
          {
            "name": "bidding",
            "type": {
              "defined": "Bidding"
            }
          },
          {
            "name": "ageRange",
            "type": {
              "option": {
                "array": [
                  "u8",
                  2
                ]
              }
            }
          },
          {
            "name": "netWorthRange",
            "type": {
              "option": {
                "array": [
                  "u32",
                  2
                ]
              }
            }
          },
          {
            "name": "incomeRange",
            "type": {
              "option": {
                "array": [
                  "u32",
                  2
                ]
              }
            }
          },
          {
            "name": "genderInclusions",
            "type": {
              "vec": {
                "defined": "Gender"
              }
            }
          },
          {
            "name": "genderExclusions",
            "type": {
              "vec": {
                "defined": "Gender"
              }
            }
          },
          {
            "name": "regionInclusions",
            "type": {
              "vec": {
                "array": [
                  "u8",
                  2
                ]
              }
            }
          },
          {
            "name": "regionExclusions",
            "type": {
              "vec": {
                "array": [
                  "u8",
                  2
                ]
              }
            }
          },
          {
            "name": "eductionInclusions",
            "type": {
              "vec": {
                "defined": "Education"
              }
            }
          },
          {
            "name": "eductionExclusions",
            "type": {
              "vec": {
                "defined": "Education"
              }
            }
          },
          {
            "name": "deviceTypeInclusions",
            "type": {
              "vec": {
                "defined": "DeviceType"
              }
            }
          },
          {
            "name": "deviceTypeExclusions",
            "type": {
              "vec": {
                "defined": "DeviceType"
              }
            }
          },
          {
            "name": "categoryInclusions",
            "type": {
              "vec": {
                "defined": "PropertyCategory"
              }
            }
          },
          {
            "name": "categoryExclusions",
            "type": {
              "vec": {
                "defined": "PropertyCategory"
              }
            }
          },
          {
            "name": "keywordInclusions",
            "type": {
              "vec": "string"
            }
          },
          {
            "name": "keywordExclusions",
            "type": {
              "vec": "string"
            }
          },
          {
            "name": "imageCreatives",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "videoCreatives",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "copyCreatives",
            "type": {
              "vec": "publicKey"
            }
          }
        ]
      }
    },
    {
      "name": "creative",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "advertiser",
            "type": "publicKey"
          },
          {
            "name": "id",
            "type": "u32"
          },
          {
            "name": "nonce",
            "type": "u16"
          },
          {
            "name": "status",
            "type": {
              "defined": "CreativeStatus"
            }
          },
          {
            "name": "ctaUrl",
            "type": "string"
          },
          {
            "name": "asset",
            "type": {
              "defined": "CreativeAsset"
            }
          },
          {
            "name": "languages",
            "type": {
              "vec": {
                "defined": "Language"
              }
            }
          }
        ]
      }
    },
    {
      "name": "nonce",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "nonce",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "property",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "publisher",
            "type": "publicKey"
          },
          {
            "name": "id",
            "type": "u32"
          },
          {
            "name": "url",
            "type": "string"
          },
          {
            "name": "nonce",
            "type": "u16"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "BalanceDepositWithdrawArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "delta",
            "type": "i128"
          }
        ]
      }
    },
    {
      "name": "CommitPlacementArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "sharedSecret",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      }
    },
    {
      "name": "InitBalanceArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "InitOrUpdateBidderDelegateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "revoke",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "InitOrUpdateCampaignArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u32"
          },
          {
            "name": "status",
            "type": {
              "option": {
                "defined": "CampaignStatus"
              }
            }
          },
          {
            "name": "objective",
            "type": {
              "option": {
                "defined": "Objective"
              }
            }
          },
          {
            "name": "name",
            "type": {
              "option": {
                "array": [
                  "u8",
                  32
                ]
              }
            }
          },
          {
            "name": "startTimestamp",
            "type": {
              "option": "i64"
            }
          },
          {
            "name": "endTimestamp",
            "type": {
              "option": "i64"
            }
          },
          {
            "name": "budget",
            "type": {
              "option": {
                "defined": "Budget"
              }
            }
          },
          {
            "name": "bidding",
            "type": {
              "option": {
                "defined": "Bidding"
              }
            }
          },
          {
            "name": "ageRange",
            "type": {
              "option": {
                "option": {
                  "array": [
                    "u8",
                    2
                  ]
                }
              }
            }
          },
          {
            "name": "netWorthRange",
            "type": {
              "option": {
                "option": {
                  "array": [
                    "u32",
                    2
                  ]
                }
              }
            }
          },
          {
            "name": "incomeRange",
            "type": {
              "option": {
                "option": {
                  "array": [
                    "u32",
                    2
                  ]
                }
              }
            }
          },
          {
            "name": "genderInclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "Gender"
                }
              }
            }
          },
          {
            "name": "genderExclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "Gender"
                }
              }
            }
          },
          {
            "name": "regionInclusions",
            "type": {
              "option": {
                "vec": {
                  "array": [
                    "u8",
                    2
                  ]
                }
              }
            }
          },
          {
            "name": "regionExclusions",
            "type": {
              "option": {
                "vec": {
                  "array": [
                    "u8",
                    2
                  ]
                }
              }
            }
          },
          {
            "name": "eductionInclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "Education"
                }
              }
            }
          },
          {
            "name": "eductionExclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "Education"
                }
              }
            }
          },
          {
            "name": "deviceTypeInclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "DeviceType"
                }
              }
            }
          },
          {
            "name": "deviceTypeExclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "DeviceType"
                }
              }
            }
          },
          {
            "name": "categoryInclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "PropertyCategory"
                }
              }
            }
          },
          {
            "name": "categoryExclusions",
            "type": {
              "option": {
                "vec": {
                  "defined": "PropertyCategory"
                }
              }
            }
          },
          {
            "name": "keywordInclusions",
            "type": {
              "option": {
                "vec": "string"
              }
            }
          },
          {
            "name": "keywordExclusions",
            "type": {
              "option": {
                "vec": "string"
              }
            }
          },
          {
            "name": "imageCreatives",
            "type": {
              "option": {
                "vec": "publicKey"
              }
            }
          },
          {
            "name": "videoCreatives",
            "type": {
              "option": {
                "vec": "publicKey"
              }
            }
          },
          {
            "name": "copyCreatives",
            "type": {
              "option": {
                "vec": "publicKey"
              }
            }
          }
        ]
      }
    },
    {
      "name": "InitOrUpdateCreativeArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u32"
          },
          {
            "name": "status",
            "type": {
              "option": {
                "defined": "CreativeStatus"
              }
            }
          },
          {
            "name": "asset",
            "type": {
              "option": {
                "defined": "CreativeAsset"
              }
            }
          },
          {
            "name": "ctaUrl",
            "type": {
              "option": "string"
            }
          },
          {
            "name": "languages",
            "type": {
              "option": {
                "vec": {
                  "defined": "Language"
                }
              }
            }
          }
        ]
      }
    },
    {
      "name": "InitPropertyArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u32"
          },
          {
            "name": "url",
            "type": "string"
          }
        ]
      }
    },
    {
      "name": "InitRollupNodeArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "RevokeBidderDelegateArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "AcceptBidArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "CloseBidArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "ConfirmCommitArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "ListPlacementArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "context",
            "type": {
              "defined": "PlacementContext"
            }
          }
        ]
      }
    },
    {
      "name": "SubmitBidArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "Bid",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "placementId",
            "type": {
              "array": [
                "u8",
                64
              ]
            }
          },
          {
            "name": "advertiser",
            "type": "publicKey"
          },
          {
            "name": "sharedSecretCommitment",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          },
          {
            "name": "encryptedValue",
            "type": {
              "defined": "EciesCiphertext"
            }
          }
        ]
      }
    },
    {
      "name": "PlacementContext",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "creative",
            "type": {
              "defined": "CreativeContext"
            }
          },
          {
            "name": "property",
            "type": {
              "vec": {
                "defined": "PropertyContext"
              }
            }
          },
          {
            "name": "user",
            "type": {
              "vec": {
                "defined": "UserContext"
              }
            }
          }
        ]
      }
    },
    {
      "name": "EciesCiphertext",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "ephemeralPublicKey",
            "type": {
              "array": [
                "u8",
                33
              ]
            }
          },
          {
            "name": "encryptedData",
            "type": "bytes"
          },
          {
            "name": "authTag",
            "type": {
              "array": [
                "u8",
                16
              ]
            }
          }
        ]
      }
    },
    {
      "name": "Placement",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "requestor",
            "type": "publicKey"
          },
          {
            "name": "nonce",
            "type": "u64"
          },
          {
            "name": "publisher",
            "type": "publicKey"
          },
          {
            "name": "propertyId",
            "type": "u32"
          },
          {
            "name": "elGamalPublicKey",
            "type": {
              "array": [
                "u8",
                33
              ]
            }
          },
          {
            "name": "contextHash",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      }
    },
    {
      "name": "CampaignStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "Paused"
          },
          {
            "name": "Completed"
          },
          {
            "name": "Cancelled"
          }
        ]
      }
    },
    {
      "name": "Objective",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "Impressions"
          },
          {
            "name": "Conversions"
          }
        ]
      }
    },
    {
      "name": "Budget",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "Daily",
            "fields": [
              {
                "name": "max_spend_per_day",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Total",
            "fields": [
              {
                "name": "campaign_total",
                "type": "u64"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "Bidding",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CostPerImpression",
            "fields": [
              {
                "name": "amount",
                "type": "u64"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "CreativeStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "Suspended"
          }
        ]
      }
    },
    {
      "name": "CreativeAsset",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "Image",
            "fields": [
              {
                "name": "uri",
                "type": "string"
              },
              {
                "name": "width",
                "type": "u16"
              },
              {
                "name": "height",
                "type": "u16"
              },
              {
                "name": "max_width",
                "type": "u16"
              },
              {
                "name": "max_height",
                "type": "u16"
              },
              {
                "name": "min_width",
                "type": "u16"
              },
              {
                "name": "min_height",
                "type": "u16"
              }
            ]
          },
          {
            "name": "Video",
            "fields": [
              {
                "name": "uri",
                "type": "string"
              },
              {
                "name": "width",
                "type": "u16"
              },
              {
                "name": "height",
                "type": "u16"
              },
              {
                "name": "duration",
                "type": "u16"
              },
              {
                "name": "max_width",
                "type": "u16"
              },
              {
                "name": "max_height",
                "type": "u16"
              },
              {
                "name": "min_width",
                "type": "u16"
              },
              {
                "name": "min_height",
                "type": "u16"
              },
              {
                "name": "min_duration",
                "type": "u16"
              }
            ]
          },
          {
            "name": "Copy",
            "fields": [
              {
                "name": "title",
                "type": "string"
              },
              {
                "name": "body",
                "type": "string"
              },
              {
                "name": "cta_text",
                "type": "string"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "Language",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "En"
          },
          {
            "name": "Cn"
          },
          {
            "name": "Fr"
          },
          {
            "name": "Es"
          },
          {
            "name": "De"
          }
        ]
      }
    },
    {
      "name": "CreativeContext",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "Image",
            "fields": [
              {
                "name": "width",
                "type": "u16"
              },
              {
                "name": "height",
                "type": "u16"
              }
            ]
          },
          {
            "name": "Video",
            "fields": [
              {
                "name": "width",
                "type": "u16"
              },
              {
                "name": "height",
                "type": "u16"
              },
              {
                "name": "max_duration",
                "type": "u16"
              }
            ]
          },
          {
            "name": "Copy",
            "fields": [
              {
                "name": "title_chars",
                "type": "u16"
              },
              {
                "name": "body_chars",
                "type": "u16"
              },
              {
                "name": "cta_chars",
                "type": "u16"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "PropertyContext",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "DeviceType",
            "fields": [
              {
                "name": "value",
                "type": {
                  "defined": "DeviceType"
                }
              }
            ]
          },
          {
            "name": "Category",
            "fields": [
              {
                "name": "value",
                "type": {
                  "defined": "PropertyCategory"
                }
              }
            ]
          },
          {
            "name": "Keyword",
            "fields": [
              {
                "name": "value",
                "type": "string"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "UserContext",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Age",
            "fields": [
              {
                "name": "value",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Gender",
            "fields": [
              {
                "name": "value",
                "type": {
                  "defined": "Gender"
                }
              }
            ]
          },
          {
            "name": "CountryCode",
            "fields": [
              {
                "name": "value",
                "type": {
                  "array": [
                    "u8",
                    2
                  ]
                }
              }
            ]
          },
          {
            "name": "NetWorth",
            "fields": [
              {
                "name": "value",
                "type": "u32"
              }
            ]
          },
          {
            "name": "Income",
            "fields": [
              {
                "name": "value",
                "type": "u32"
              }
            ]
          },
          {
            "name": "Education",
            "fields": [
              {
                "name": "value",
                "type": {
                  "defined": "Education"
                }
              }
            ]
          }
        ]
      }
    },
    {
      "name": "DeviceType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Desktop"
          },
          {
            "name": "MobileWeb"
          },
          {
            "name": "NativeApp"
          }
        ]
      }
    },
    {
      "name": "PropertyCategory",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "News"
          },
          {
            "name": "Sports"
          },
          {
            "name": "Entertainment"
          },
          {
            "name": "Finance"
          },
          {
            "name": "Lifestyle"
          },
          {
            "name": "Other"
          }
        ]
      }
    },
    {
      "name": "Gender",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Male"
          },
          {
            "name": "Female"
          },
          {
            "name": "Other"
          }
        ]
      }
    },
    {
      "name": "Education",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "HighSchool"
          },
          {
            "name": "Bachelors"
          },
          {
            "name": "Masters"
          },
          {
            "name": "Phd"
          },
          {
            "name": "Other"
          }
        ]
      }
    },
    {
      "name": "PlacementStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "OpenToBids"
          },
          {
            "name": "PlacementMatched"
          },
          {
            "name": "Expired"
          }
        ]
      }
    }
  ],
  "events": [
    {
      "name": "PlacementSettled",
      "fields": [
        {
          "name": "placementId",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "node",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "auction",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "requestor",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "publisher",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "publisherBalance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "advertiser",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "advertiserBalance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "placement",
          "type": {
            "defined": "Placement"
          },
          "index": false
        },
        {
          "name": "winningBid",
          "type": {
            "defined": "Bid"
          },
          "index": false
        },
        {
          "name": "bidValue",
          "type": "u64",
          "index": false
        },
        {
          "name": "fee",
          "type": "u64",
          "index": false
        },
        {
          "name": "creativeId",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "sharedSecret",
          "type": {
            "array": [
              "u8",
              32
            ]
          },
          "index": false
        },
        {
          "name": "requestorSignature",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "bidderSignature",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "AuctionStarted",
      "fields": [
        {
          "name": "placementId",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "auction",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "publisher",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "property",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "auctionEnds",
          "type": "i64",
          "index": false
        },
        {
          "name": "context",
          "type": {
            "defined": "PlacementContext"
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        },
        {
          "name": "placement",
          "type": {
            "defined": "Placement"
          },
          "index": false
        }
      ]
    },
    {
      "name": "BidPlaced",
      "fields": [
        {
          "name": "placementId",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "auction",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "publisher",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "advertiser",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "bidder",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        },
        {
          "name": "encryptedValue",
          "type": {
            "defined": "EciesCiphertext"
          },
          "index": false
        }
      ]
    },
    {
      "name": "PlacementMatched",
      "fields": [
        {
          "name": "placementId",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "auction",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "publisher",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "advertiser",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "encryptedValue",
          "type": {
            "defined": "EciesCiphertext"
          },
          "index": false
        },
        {
          "name": "bidValue",
          "type": "u64",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "AuctionAlreadyClosed"
    },
    {
      "code": 6001,
      "name": "InvalidKey"
    },
    {
      "code": 6002,
      "name": "DecryptionFailed"
    },
    {
      "code": 6003,
      "name": "InvalidDecryptedValue"
    },
    {
      "code": 6004,
      "name": "InvalidFeature"
    },
    {
      "code": 6005,
      "name": "InvalidNodeAuthority"
    },
    {
      "code": 6006,
      "name": "InvalidContext"
    },
    {
      "code": 6007,
      "name": "InvalidBidder"
    },
    {
      "code": 6008,
      "name": "InvalidBid"
    },
    {
      "code": 6009,
      "name": "InvalidPublisher"
    },
    {
      "code": 6010,
      "name": "InvalidAdvertiser"
    },
    {
      "code": 6011,
      "name": "InvalidRequestor"
    },
    {
      "code": 6012,
      "name": "InvalidAuction"
    },
    {
      "code": 6013,
      "name": "InvalidDerivedAccount"
    },
    {
      "code": 6014,
      "name": "InvalidProgam"
    },
    {
      "code": 6015,
      "name": "InvalidProperty"
    },
    {
      "code": 6016,
      "name": "UnauthorizedRollupNode"
    },
    {
      "code": 6017,
      "name": "CannotCloseWinningBid"
    },
    {
      "code": 6018,
      "name": "CannotCloseBidWhileAuctionOpen"
    },
    {
      "code": 6019,
      "name": "ValidationFailed"
    },
    {
      "code": 6020,
      "name": "MustBeMinimallyComplete"
    },
    {
      "code": 6021,
      "name": "UpdateHashMismatch"
    },
    {
      "code": 6022,
      "name": "InvalidNonce"
    }
  ]
};
