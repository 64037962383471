import React, { createContext, useContext, useState, useEffect } from 'react';
import { PublicKey } from '@solana/web3.js';
import Publisher from '../bindings/publisher';
import { Dax, IDL} from '../dax'; 
import { useDaxProgram } from './DaxProgramContext';
import { useSolanaWallets } from '@privy-io/react-auth';

interface PublisherContextType {
  publisher: Publisher | null;
}

const PublisherContext = createContext<PublisherContextType | undefined>(undefined);

export function usePublisher() {
  const context = useContext(PublisherContext);
  if (context === undefined) {
    throw new Error('usePublisher must be used within a PublisherProvider');
  }
  return context;
}

export const PublisherProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [publisher, setPublisher] = useState<Publisher | null>(null);
  const { provider, program } = useDaxProgram();

  const {wallets} = useSolanaWallets();
  const wallet = wallets[0]; 
  const [publicKey, setPublicKey] = useState<PublicKey|undefined>(undefined);
  useEffect(() =>{
    if (wallet) {
      setPublicKey(new PublicKey(wallet.address));
    } else {
      setPublicKey(undefined);
    }
  }, [wallet])

  useEffect(() => {
    if (publicKey && program) {
      const publicKeyString = publicKey.toBase58();
      
      // Create a new Requestor instance
      let p = new Publisher(
        program.provider.connection,
        program.provider.connection, 
        IDL as Dax,
        program.programId,
        undefined,
        provider?.wallet as any
      );
      setPublisher(p);
    } else {
      setPublisher(null);
    }
  }, [publicKey, program]);

  return (
    <PublisherContext.Provider value={{ publisher }}>
      {children}
    </PublisherContext.Provider>
  );
};