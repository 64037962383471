import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const dateRanges = [
  { name: 'Last Day', value: 'day' },
  { name: 'Last Week', value: 'week' },
  { name: 'Last Month', value: 'month' },
  { name: 'Year to Date', value: 'ytd' },
  { name: 'Custom Range', value: 'custom' },
];

interface TimePeriodSelectorProps {
  selectedRange: { name: string; value: string };
  onRangeChange: (range: { name: string; value: string }, startDate?: Date, endDate?: Date) => void;
}

const TimePeriodSelector: React.FC<TimePeriodSelectorProps> = ({ selectedRange, onRangeChange }) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const handleRangeChange = (range: { name: string; value: string }) => {
    if (range.value === 'custom') {
      onRangeChange(range);
    } else {
      onRangeChange(range);
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start ?? undefined);
    setEndDate(end ?? undefined);
    if (start && end) {
      onRangeChange({ name: 'Custom Range', value: 'custom' }, start, end);
    }
  };

  return (
    <div className="relative inline-block text-left">
      <Menu>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-700 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          {selectedRange.name}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {dateRanges.map((range) => (
              <Menu.Item key={range.value}>
                {({ active }) => (
                  <button
                    onClick={() => handleRangeChange(range)}
                    className={`${
                      active ? 'bg-gray-100 dark:bg-gray-600 text-gray-900 dark:text-white' : 'text-gray-700 dark:text-gray-200'
                    } block px-4 py-2 text-sm w-full text-left`}
                  >
                    {range.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Menu>
      {selectedRange.value === 'custom' && (
        <div className="mt-2">
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            className="form-input px-4 py-2 border rounded-md dark:bg-gray-700 dark:text-white"
            placeholderText="Select date range"
          />
        </div>
      )}
    </div>
  );
};

export default TimePeriodSelector;