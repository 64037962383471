import React, { createContext, useContext, useMemo, useEffect, useCallback, useState } from 'react';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { Program, AnchorProvider, web3, Idl } from '@coral-xyz/anchor';
import { useQuery, useQueryClient } from 'react-query';
import { IDL } from '../dax';
import { useSolanaWallets } from '@privy-io/react-auth';


interface DaxProgramContextType {
  program: Program<typeof IDL> | null;
  provider: AnchorProvider | null;
}

const DaxProgramContext = createContext<DaxProgramContextType | undefined>(undefined);

interface DaxProgramProviderProps {
  children: React.ReactNode;
  programID: PublicKey;
  endpoint: string;
}

export const DaxProgramProvider: React.FC<DaxProgramProviderProps> = ({ children, programID, endpoint }) => {
  
  // const { publicKey, signTransaction, signAllTransactions } = useWallet();
  const {wallets} = useSolanaWallets();
  const wallet = wallets[0]; 
  
  const [publicKey, setPublicKey] = useState<PublicKey|undefined>(undefined);
  useEffect(() =>{
    if (wallet) {
      setPublicKey(new PublicKey(wallet.address));
    } else {
      setPublicKey(undefined);
    }
  }, [wallet])

  const signTransaction = useCallback(async (txn: Transaction) => {
    const solanaProvider = await wallet.getProvider();
    const txnSerialized = txn.serializeMessage().toString('base64');
    const {signature} = await solanaProvider.request({
      method: "signMessage",
      params: {
        message: txnSerialized,
      },
    });
    txn.addSignature(new PublicKey(wallet.address), Buffer.from(signature, 'base64'));
    return txn
  }, [wallet]);

  const signAllTransactions = useCallback(async (txns: Transaction[]) => {
    txns.map(async (txn) => {
      signTransaction(txn)
    })
  }, [signTransaction]);

  const signMessage = useCallback(async (msg: string) => {
    const solanaProvider = await wallet.getProvider();
    const {signature} = await solanaProvider.request({
      method: "signMessage",
      params: {
        message: msg,
      },
    });
    return signature
  }, [wallet]);

  const queryClient = useQueryClient();

  const provider = useMemo(() => {
    if (!publicKey || !signAllTransactions || !signTransaction) return null;
    const connection = new Connection(endpoint);
    return new AnchorProvider(
      connection,
      { publicKey, signTransaction, signAllTransactions } as any,
      { commitment: 'processed' }
    );
  }, [publicKey, signTransaction, signAllTransactions, endpoint]);

  const program = useMemo(() => {
    if (!provider) return null;
    return new Program(IDL, programID, provider);
  }, [provider, programID]);

  useEffect(() => {
    if (!program) return;

    const connection = new Connection(endpoint);
    const subscriptionId = connection.onLogs(
      programID,
      (logs) => {
        console.log("New logs:", logs);
        // You might want to refresh your data here
        queryClient.invalidateQueries('items');
      },
      "confirmed"
    );

    return () => {
      connection.removeOnLogsListener(subscriptionId);
    };
  }, [program, queryClient, programID, endpoint]);

  return (
    <DaxProgramContext.Provider value={{ program, provider }}>
      {children}
    </DaxProgramContext.Provider>
  );
};

export const useDaxProgram = () => {
  const context = useContext(DaxProgramContext);
  if (context === undefined) {
    throw new Error('useDaxProgram must be used within a DaxProgramProvider');
  }
  return context;
};

export const useItems = () => {
  const { program } = useDaxProgram();

  return useQuery<Item[], Error>('items', async () => {
    if (!program) throw new Error('Program not initialized');
    // Implement your fetch logic here
    // return await program.account.yourAccountName.all();
    return []; // Replace with actual fetch logic
  }, {
    enabled: !!program,
  });
};

interface Item {
  id: string;
  // Add other properties based on your program's structure
}