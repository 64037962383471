import React from 'react';
import { useBalance } from '../../../context/BalancesContext';
import { SiSolana } from 'react-icons/si';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid'

interface BalanceWidgetProps {
  onClick: () => void;
}

const BalanceWidget: React.FC<BalanceWidgetProps> = ({ onClick }) => {
  const { walletBalance, creditsBalance, isLoading } = useBalance();

  const Spinner = () => (
    <div className="animate-spin rounded-full h-4 w-4 border-2 border-indigo-500 dark:border-indigo-400 border-t-transparent"></div>
  );

  return (
    <button
      onClick={onClick}
      className="flex items-center text-gray-700 dark:text-gray-200 transition-colors duration-200 focus:outline-none rounded-md"
    >
      <div className="flex items-center flex-1">
        <SiSolana className="text-indigo-500 dark:text-indigo-400 mr-1 flex-shrink-0" />
        <span className="text-sm font-medium flex justify-end">
          {isLoading ? (
            <Spinner />
          ) : (
            walletBalance !== null ? (walletBalance / 1e9).toFixed(2) : '0.00'
          )}
        </span>
      </div>
      <div className="mx-2 h-4 w-px bg-gray-300 dark:bg-gray-600 flex-shrink-0"></div>
      <div className="flex items-center flex-1">
        <CurrencyDollarIcon className="text-indigo-500 dark:text-indigo-400 mr-1 h-5 w-5 flex-shrink-0" />
        <span className="text-sm font-medium  flex justify-end">
          {isLoading ? (
            <Spinner />
          ) : (
            creditsBalance !== null ? (creditsBalance / 1e9).toFixed(2) : '0.00'
          )}
        </span>
      </div>
    </button>
  );
};

export default BalanceWidget;