import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Connection, PublicKey, Keypair } from '@solana/web3.js';
import { Dax, IDL } from '../dax';
import Node from '../bindings/node'; 
import { AnchorProvider, Program } from '@coral-xyz/anchor';
import { useDaxProgram } from './DaxProgramContext'; 
import dotenv from 'dotenv';
dotenv.config();

interface RollupNodeContextType {
  rollupNode: Node | null;
}

const RollupNodeContext = createContext<RollupNodeContextType | undefined>(undefined);

export const RollupNodeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [rollupNode, setRollupNode] = useState<Node | null>(null);
  const { program, provider } = useDaxProgram();

  useEffect(() => {
    // Initialize Node only once when the component mounts
    const initializeNode = async () => {
      const keypairJson = process.env.REACT_APP_ROLLUP_NODE_KEYPAIR || '';

      if (!keypairJson || !provider || !program) {
        console.error("Missing keypair in environment variables");
        return;
      }

      const keypair = Keypair.fromSecretKey(Uint8Array.from(JSON.parse(keypairJson)));

      const nodeInstance = new Node(
        provider.connection,
        provider.connection, 
        program.idl, 
        program.programId, 
        keypair
    );

      await nodeInstance.loadState();
      setRollupNode(nodeInstance);
    };

    initializeNode();
  }, [program, provider]);

  return (
    <RollupNodeContext.Provider value={{ rollupNode }}>
      {children}
    </RollupNodeContext.Provider>
  );
};

export const useRollupNode = () => {
  const context = useContext(RollupNodeContext);
  if (!context) {
    throw new Error('useNode must be used within a NodeProvider');
  }
  return context;
};

