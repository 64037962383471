import React, { useEffect, useState, useCallback } from 'react';
import { PublicKey } from '@solana/web3.js';
import CreativeUploader from '../../common/CreativeUploader';
import ImageCreativeUploader from './ImageCreativeUploader';
import CreativeTable from '../creatives/CreativeTable';
import { useSolanaWallets } from '@privy-io/react-auth';
import { getCreatives } from '../../../supabase';
import { useAdvertiser } from '../../../context/AdvertiserContext';

interface CreativesStepProps {
  onSubmit: (data: any) => void;
  initialData?: any;
}

const CreativesStep: React.FC<CreativesStepProps> = ({ onSubmit, initialData }) => {
  const [imageCreatives, setImageCreatives] = useState<PublicKey[]>([]);
  const [videoCreatives, setVideoCreatives] = useState<PublicKey[]>([]);
  const [copyCreatives, setCopyCreatives] = useState<PublicKey[]>([]);
  const [selectedCreatives, setSelectedCreatives] = useState<any[]>([]);

  useEffect(() => {
    if (initialData) {
      console.log("initialData", initialData);
      setImageCreatives(initialData.image_creatives?.map((c: string) => new PublicKey(c)) || []);
      setVideoCreatives(initialData.videoCreatives?.map((c: string) => new PublicKey(c)) || []);
      setCopyCreatives(initialData.copyCreatives?.map((c: string) => new PublicKey(c)) || []);
      setSelectedCreatives(initialData.image_creatives?.map((c: string) => new PublicKey(c).toBase58()) || []);
    }
  }, [initialData]);

  const { wallets } = useSolanaWallets();
  const wallet = wallets[0];

  const [creatives, setCreatives] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { advertiser } = useAdvertiser();


  const fetchCreatives = useCallback(async () => {
    if (!wallet || !wallet.address) {
        return;
        setCreatives([])
        setIsLoading(false)
    }
    try {
      console.log('Getting creatives...')
      // const ds = await advertiser.fetchAllCreatives();
      const ds = await getCreatives({ advertiser: wallet.address });
      console.log(ds);
      setCreatives(ds);
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching creatives:', error);
      setCreatives([])
      setIsLoading(false)
    }
  }, [wallet]);

  useEffect(() => {
    if (wallet && wallet.address) {
      fetchCreatives();
    } 
  }, [wallet]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      imageCreatives: selectedCreatives,
      videoCreatives,
      copyCreatives,
      selectedCreatives
    });
  };

  const handleManageCreative = (creative: any): void => {
    console.log('Managing creative:', creative);
  };

  const handleSelectCreative = (creative: any, checked: boolean): void => {
    console.log('Selecting creative:', creative);
    if (checked) {
      setSelectedCreatives([...selectedCreatives, creative.id]);
    } else {
      setSelectedCreatives(selectedCreatives.filter((id) => id !== creative.id));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {/* <ImageCreativeUploader
        creatives={imageCreatives}
        onChange={setImageCreatives}
      />
      <CreativeUploader
        label="Video Creatives"
        creativeType="video"
        creatives={videoCreatives}
        onChange={setVideoCreatives}
      />
      <CreativeUploader
        label="Copy Creatives"
        creativeType="copy"
        creatives={copyCreatives}
        onChange={setCopyCreatives}
      /> */}
      <CreativeTable 
          creatives={creatives} 
          isLoading={isLoading} 
          advertiser={advertiser}
          refreshCreatives={fetchCreatives}
          onManageCreative={handleManageCreative}
          isSelectionView={true}
          selectedCreatives={selectedCreatives}
          onSelectCreative={handleSelectCreative}
        />
      <button
        type="submit"
        className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600"
      >
        Finish
      </button>
    </form>
  );
};

export default CreativesStep;