import React from 'react';
import MacOSWindowStack from './MacOSWindowStack';

const ForPublishers: React.FC = () => {
  const windows = [
    {
      heading: "AdX.so - New Property",
      content: <img className="w-full h-full object-cover" src="screen-register-property.png" alt="AdX Explorer" />,
      width: '80%',
      height: '70%',
      top: '0%',
      left: '0%',
      zIndex: 1
    },
    {
      heading: "Integration",
      content: <img className="w-full h-full object-cover" src="screen-integration.png" alt="VS Code" />,
      width: '60%',
      height: '60%',
      top: '50%',
      left: '40%',
      zIndex: 2
    }
  ];

  return (
    <div className="flex flex-col lg:flex-row w-full h-full gap-8 md:gap-5">
      

      {/* Second column (2/3 width on desktop) */}
      <div className="w-full lg:w-2/3 h-full flex items-center justify-center mt-8 lg:mt-0">
        <div className="w-full h-full">
          <MacOSWindowStack windows={windows} />
        </div>
      </div>

      {/* First column (1/3 width on desktop) */}
      <div className="w-full lg:w-1/3 flex flex-col justify-center md:pl-8 ">
        <h2 className="text-4xl md:text-5xl font-medium mb-4">
            Integration is easy
        </h2>
        <div className="text-lg lg:text-xl whitespace-pre-line opacity-80 space-y-3">
          <p>
            Just add a snippet of code to your website or application's HTML.
          </p>
          <p>
            Our SDK handles creation and management of ephemeral keypairs in your audience wallet, which signs and verifies the real-time placement auctions from their device.
          </p>
        </div>
      </div>

    </div>
  );
};

export default ForPublishers;