import { CheckIcon } from '@heroicons/react/20/solid';
import React from 'react';

const items = [
  { text: "Use case optimized SVM rollup node" }, 
  { text: "Settlement program and proving system back to L1 for DA and settlement" }, 
  { text: "Explorer UI to interpret and verify and view activity on the SVM and  L1" }, 
  { text: "EDCH blind auction and matching primitive Solana program " }, 
  { text: "JavaScript module for embedding into properties to request and render ads" }, 
  { text: "Example implementation of a demand-side platform (DSP) interface dapp" }, 
  { text: "Example implementation of a supply-side platform (SSP) interface dapp" }, 
  { text: "Example implementation of a 'bidder node'" }
];


const WhatWeBuilt: React.FC = () => {
  return (
    <div className="text-center justify-center content-center w-full">
        <h2 className="text-5xl md:text-6xl font-medium mb-4">
          Progress to date
        </h2>

        <h3 className="font-bold text-lg lg:text-xl z-10">
          What we've built in the 5 week hackathon period.
        </h3>

    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-8 justify-left text-left items-left mt-8">
      {items.map((item)=>(
        <BuildItem text={item.text} />
      ))}
    </div>

  <div className="mt-10 ">
    <p className="text-lg sm:text-xl text-left md:text-center">
        Development started on 26 August 2024. All Github repositories shared as part of hackathon submission.
      </p>
        
  </div>
    

    </div>
  );
};

export default WhatWeBuilt;

const BuildItem: React.FC<{text: string}> = ({text}) => {
  return (
    <div className="relative rounded-lg overflow-hidden items-center p-4 md:p-5 backdrop-blur-md bg-white/10">
      <div className="flex md:block items-left">
        <div className="w-10 h-10">
          <CheckIcon className="h-8 w-8 mb-2 ml-0 mr-4" />
        </div>
        <div className="">
        <p className="text-md lg:text-xl font-medium">
          {text}
        </p>
        </div>
      </div>
    </div>
  );
};

