import React from 'react';
import ExplorerStats from './ExplorerStats';
import ExplorerBarChart from './ExplorerBarChart';
import ExplorerRecentPlacements from './ExplorerRecentPlacements';

import { useRollupNode } from '../../../context/RollupNodeContext';

const ExplorerHome: React.FC = () => {

  const { rollupNode } = useRollupNode();



  return (
    <div className="w-full space-y-6 mt-6">

      <ExplorerStats />      

      <ExplorerBarChart />

      <ExplorerRecentPlacements accountFilter={undefined} />

    </div>
  );
};

export default ExplorerHome;