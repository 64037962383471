import React from 'react';
import MacOSWindowStack from './MacOSWindowStack';

const ForAdvertisers: React.FC = () => {
    const windows = [
        {
          heading: "AdX.so - New Campaign",
          content: <img className="w-full h-full object-cover" src="screen-new-campaign.png" alt="AdX Explorer" />,
          width: '70%',
          height: '70%',
          top: '0%',
          left: '0%',
          zIndex: 2
        },
        {
          heading: "Adx.so - Dashboard",
          content: <img className="w-full h-full object-cover" src="screen-dashboard.png" alt="Solana Explorer" />,
          width: '70%',
          height: '60%',
          top: '30%',
          left: '30%',
          zIndex: 1
        }
      ];

    return (
    <div className="flex flex-col lg:flex-row w-full h-full gap-8 md:gap-5">
        {/* Second column (2/3 width on desktop, full width and first on mobile) */}
        <div className="w-full lg:w-2/3 h-full flex items-center justify-center order-1 md:order-2">
        <div className="w-full h-full">
            <MacOSWindowStack windows={windows} />
        </div>
        </div>

        {/* First column (1/3 width on desktop, full width and second on mobile) */}
        <div className="w-full lg:w-1/3 flex flex-col justify-center pr-8 order-2 md:order-1 mt-8 lg:mt-0">
        <h2 className="text-4xl md:text-5xl font-medium mb-4">Configure campaigns for real-time bidding</h2>
        <div className="text-lg lg:text-xl whitespace-pre-line opacity-80 space-y-3">
            <p>
                Reach audiences based on targetted context and user attribution. 
            </p>
            <p>
                Configure creatives and campaigns with budgets, targeting, exclusions and bidding rules to secure impressions.
            </p>
            <p>
                Delegated bidder nodes execute ultra low-latency, real-time bids on the AdX network on your behalf.
            </p>
        </div>
        </div>
    </div>
    );
};

export default ForAdvertisers;