import React, { createContext, useContext, useState, useEffect } from 'react';
import { PublicKey } from '@solana/web3.js';
import Advertiser from '../bindings/advertiser';
import { Dax, IDL} from '../dax'; 
import { useDaxProgram } from './DaxProgramContext';
import { useSolanaWallets } from '@privy-io/react-auth';

interface AdvertiserContextType {
  advertiser: Advertiser | null;
}

const AdvertiserContext = createContext<AdvertiserContextType | undefined>(undefined);

export function useAdvertiser() {
  const context = useContext(AdvertiserContext);
  if (context === undefined) {
    throw new Error('useAdvertiser must be used within a AdvertiserProvider');
  }
  return context;
}

export const AdvertiserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [advertiser, setAdvertiser] = useState<Advertiser | null>(null);
  const { provider, program } = useDaxProgram();

  const {wallets} = useSolanaWallets();
  const wallet = wallets[0]; 
  const [publicKey, setPublicKey] = useState<PublicKey|undefined>(undefined);
  useEffect(() =>{
    if (wallet) {
      setPublicKey(new PublicKey(wallet.address));
    } else {
      setPublicKey(undefined);
    }
  }, [wallet])

  useEffect(() => {
    if (publicKey && program) {
      const publicKeyString = publicKey.toBase58();
      
      // Create a new Requestor instance
      let p = new Advertiser(
        program.provider.connection,
        program.provider.connection, 
        IDL as Dax,
        program.programId,
        undefined,
        provider?.wallet as any
      );
      setAdvertiser(p);
    } else {
      setAdvertiser(null);
    }
  }, [publicKey, program]);

  return (
    <AdvertiserContext.Provider value={{ advertiser }}>
      {children}
    </AdvertiserContext.Provider>
  );
};