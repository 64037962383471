import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ExplorerLayout, {  } from './ExplorerLayout';
 



const ExplorerRoutes: React.FC = () => (
  <Routes>
    <Route element={<ExplorerLayout />}>
      {/* <Route index element={<Navigate to="transactions" replace />} /> */}
      {/* <Route path="transactions" element={<Transactions />} />
      <Route path="accounts" element={<Accounts />} />
      <Route path="blocks" element={<Blocks />} /> */}
    </Route>
  </Routes>
);

export default ExplorerRoutes;