import React, { ReactNode } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

interface ToastProps {
  content: ReactNode;
  type: 'success' | 'error' | 'info';
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ content, type, onClose }) => {
  const bgColor = {
    success: 'bg-green-500',
    error: 'bg-red-500',
    info: 'bg-blue-500',
  }[type];

  return (
    <div className={`${bgColor} text-white px-4 py-3 rounded shadow-lg flex items-center justify-between`}>
      <div className="mr-4">{content}</div>
      <button onClick={onClose} className="ml-auto focus:outline-none">
        <XMarkIcon className="h-5 w-5" />
      </button>
    </div>
  );
};

export default Toast;