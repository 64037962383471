import { ArrowsPointingInIcon, BuildingOffice2Icon, ComputerDesktopIcon, DocumentCurrencyDollarIcon, UsersIcon } from '@heroicons/react/24/outline';
import React from 'react';

const points = [
  {
      id: 0,
      heading: "Publishers",
      body: "Publishers list properties (websites, applications, blogs) capable of serving ads in return for fees. ",
      icon: <ComputerDesktopIcon className="h-10"/>
  },
  {
      id: 1,
      heading: "Advertisers",
      body: "Advertisers configure creatives (copy, images and videos) and campaigns -- targeting, objectives, budgets and bidding.",
      icon: <BuildingOffice2Icon className="h-10"/>
  },
  {
    id: 2,
    heading: "Audience",
    body: "When a user visits a property on the exchange, a request for an ad placement is emitted and context is shared with the network.",
    icon: <UsersIcon className="h-10"/>
  },
  {
    id: 3,
    heading: "Real-time auction",
    body: "Campaign rules are compared with the placement's context (demographic, device, page, etc) to generate real-time-bids (RTBs) to determine which ad is served.",
    icon: <ArrowsPointingInIcon className="h-10"/>
  },
]

const WhatIsAnAdExchange: React.FC = () => {
  return (
    <div className="flex flex-col lg:flex-row w-full gap-8">
      {/* First column (1/3 width on desktop) */}
      <div className="w-full lg:w-1/3 flex flex-col md:pr-8 items-center justify-center h-full flex">
      
            <h2 className="text-5xl md:text-6xl font-medium mb-4">What is an ad exchange?</h2>
            <div className="text-lg lg:text-xl whitespace-pre-line opacity-80">
              <p>
                An ad exchange is a digital marketplace where advertisers and publishers buy and sell digital advertising space in real-time. 
              </p>
              <p>
                Ad impressions are auctioned in milliseconds as a web page loads, based on the information available on the website or app, on the user and the device.
              </p>
            </div>

      </div>

      <div className="w-full lg:w-2/3 flex items-center justify-center mt-4 md:mt-0">

        <div className="flex-1 grid sm grid-cols-1 sm:grid-cols-2 gap-4">
            {points.map((p) => (
              <div key={p.id} className="flex md:block relative rounded-lg overflow-hidden items-center p-5 backdrop-blur-md bg-white/10">
                <div className="mb-4 mr-5">
                  {p.icon}
                </div>
                <div>
                  <h3 className="font-bold text-lg lg:text-xl z-10">
                    {p.heading}
                  </h3>
                  <p className="font-medium text-sm lg:text-lg z-10">
                    {p.body}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
    </div>
  );
};

export default WhatIsAnAdExchange;