import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PubkeyDisplay from '../../common/PubkeyDisplay';
import { PublicKey } from '@solana/web3.js';
import Advertiser from '../../../bindings/advertiser';
import { useToast } from '../../../context/ToastContext';
import { initOrUpdateBidderDelegate } from '../../../rollup';
import { useSolanaWallets } from '@privy-io/react-auth';

interface DelegateTableProps {
  delegates: any[];
  isLoading: boolean;
  advertiser: Advertiser | null,
  refreshDelegates: ()=>void
}

const DelegateTable: React.FC<DelegateTableProps> = ({ delegates, isLoading, advertiser, refreshDelegates }) => {
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);
  const [isRevoking, setIsRevoking] = useState<boolean | null>(null);
  const [revokingDelegate, setRevokingDelegate] = useState<PublicKey | null>(null);
  const { showToast } = useToast();

  const { wallets } = useSolanaWallets();
  const wallet = wallets[0];

  const handleRevokeDelegate = async (delegatePubkey: PublicKey) => {
    if (!wallet || !advertiser || !delegatePubkey || error) return;

    setRevokingDelegate(delegatePubkey)
    setIsRevoking(true);

    try {
      // await advertiser.revokeBidderDelegate(delegatePubkey);
      const delegatePubkeyObject = new PublicKey(delegatePubkey)
      await initOrUpdateBidderDelegate(wallet, advertiser, delegatePubkeyObject, true);
      refreshDelegates();
      showToast('Successfully revoked delegate', 'success');
    } catch (error: any) {
      console.error("Transaction error:", error);
      showToast(`Failed to add bidder delegate: ${error.message}`, 'error');
    } finally {
      setIsRevoking(false);
      refreshDelegates()
      setRevokingDelegate(null)
    }
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tbody>
          {[...Array(3)].map((_, idx) => (
            <tr key={`skeleton-${idx}`}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4 animate-pulse"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right">
                <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-20 ml-auto animate-pulse"></div>
              </td>
            </tr>
          ))}
        </tbody>
      );
    }

    if (delegates.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={4} className="px-6 py-4 text-center text-gray-500 dark:text-gray-400">
              No delegates found. Add a delegate to get started.
            </td>
          </tr>
        </tbody>
      );
    }

    console.log(delegates);

    return (
      <tbody>
        {delegates.map((delegate, idx) => (
          <tr key={delegate.delegate}>
            <td
              className={
                (idx === 0 ? '' : 'border-t border-transparent') +
                ' relative py-4 pl-4 pr-3 text-sm sm:pl-6'
              }
            >
              <div className="font-medium text-gray-900 dark:text-white">
                {
                  revokingDelegate && revokingDelegate.toString() == delegate.delegate.toString() ? (
                    <span className="opacity-50 flex">
                      <PubkeyDisplay pubkey={delegate.delegate} />
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                  ): (
                    <PubkeyDisplay pubkey={delegate.delegate} />
                  )
                }
                
              </div>
              {idx !== 0 ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-200 dark:bg-gray-600" /> : null}
            </td>
            <td
              className={
                (idx === 0 ? '' : 'border-t border-transparent') +
                ' relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'
              }
            >
              <button
                type="button"
                onClick={!isRevoking ? () => handleRevokeDelegate(delegate.delegate) : ()=>{}}
                className="inline-flex items-center rounded-md bg-white dark:bg-gray-700 px-2.5 py-1.5 text-sm font-semibold text-rose-900 dark:text-white shadow-sm ring-1 ring-inset ring-rose-300 dark:ring-rose-600 hover:bg-rose-50 dark:hover:bg-rose-700 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-700 transition-colors duration-200"
              >
                {
                  revokingDelegate && revokingDelegate.toString() == delegate.delegate.toString() ? (
                    <span className="flex">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="red" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="red" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Revoking...
                    </span>
                  ) :(
                    <>Revoke</>
                  )
                }
                
              </button>
              {idx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-200 dark:bg-gray-600" /> : null}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className="transition-colors duration-200">
      <div className="-mx-4 mt-10 ring-1 ring-gray-300 dark:ring-gray-700 sm:mx-0 sm:rounded-lg bg-white dark:bg-gray-800">
        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
          <thead className="bg-gray-200 dark:bg-gray-900 rounded-t-lg">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                Delegate
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          {renderTableBody()}
        </table>
      </div>
    </div>
  );
}

export default DelegateTable;