import React, { useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import MacOSWindow from './MacOSWindow';

interface WindowConfig {
  heading: string;
  content: React.ReactNode;
  width: string;
  height?: string;
  top: string;
  left: string;
  zIndex: number;
}

interface MacOSWindowStackProps {
  windows: WindowConfig[];
}

const MacOSWindowStack: React.FC<MacOSWindowStackProps> = ({ windows }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const height = (width * 3) / 4; // 3:4 aspect ratio
        setContainerSize({ width, height });
        containerRef.current.style.height = `${height}px`;

        // Calculate scale based on container width
        const baseWidth = 1024; // Base width for scale 1
        const newScale = Math.max(0.5, Math.min(1, width / baseWidth));
        setScale(newScale);
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const calculatePosition = (value: string, dimension: 'width' | 'height'): string => {
    if (value.endsWith('%')) {
      const percentage = parseFloat(value) / 100;
      const pixelValue = containerSize[dimension] * percentage;
      return `${pixelValue}px`;
    }
    return value;
  };

  const applyOffset = (value: string, dimension: 'width' | 'height'): string => {
    const pixelValue = parseFloat(calculatePosition(value, dimension));
    const offset = pixelValue >= 0 ? pixelValue : containerSize[dimension] + pixelValue;
    return `${offset}px`;
  };

  return (
    <div ref={containerRef} className="relative w-full" style={{ maxWidth: '100%' }}>
      {windows.map((window, index) => {
        const width = calculatePosition(window.width, 'width');
        const height = window.height ? calculatePosition(window.height, 'height') : 'auto';
        const top = applyOffset(window.top, 'height');
        const left = applyOffset(window.left, 'width');

        return (
          <motion.div
            key={index}
            className="absolute"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5, delay: index * 0.2, ease: "easeOut" }}
            style={{
              width,
              height,
              top,
              left,
              zIndex: window.zIndex,
              transform: `scale(${scale})`,
              transformOrigin: 'top left',
            }}
          >
            <MacOSWindow heading={window.heading}>
              {window.content}
            </MacOSWindow>
          </motion.div>
        );
      })}
    </div>
  );
};

export default MacOSWindowStack;