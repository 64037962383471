import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { NavLink, useLocation, Link} from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import Heading from '../Heading';
import { useAdvertiser } from '../../../context/AdvertiserContext';
import DelegateTable from './DelegateTable';
import BidderDelegateModal from './BidderDelegateModal';
import Alert from '../../common/Alert';
import PubkeyDisplay from '../../common/PubkeyDisplay';
import { PublicKey } from '@solana/web3.js';
import { getBidderDelegates } from '../../../supabase';
import { useSolanaWallets } from '@privy-io/react-auth';

export default function Delegates() {

  const location = useLocation();

  const [isBidderDelegateModalOpen, setIsBidderDelegateModalOpen] = useState(false);

  const handleNewDelegateClick = () => {
    setIsBidderDelegateModalOpen(true);
  };


  const { advertiser } = useAdvertiser();
  const {wallets} = useSolanaWallets();
  const wallet = wallets[0]; 


  const [delegates, setDelegates] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchDelegates = useCallback(async () => {
    if (!wallet || !wallet.address) {
        return;
        setDelegates([])
        setIsLoading(false)
    }
    try {
      console.log('Getting delegates...')
      // const ds = await advertiser.fetchAllDelegates();
      const ds = await getBidderDelegates({ advertiser: wallet.address});
      console.log(ds);
      setDelegates(ds);
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching delegates:', error);
      setDelegates([])
      setIsLoading(false)
    }
  }, [wallet]);

  useEffect(() => {
    if (wallet && wallet.address) {
      fetchDelegates();
    } 
  }, [wallet]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (advertiser) {
  //       fetchDelegates();
  //     }
  //   }, 15000); // Fetch every 15 seconds
  
  //   return () => clearInterval(intervalId);
  // }, [advertiser, fetchDelegates]);

  // if (!wallet || !wallet.address) {

  //   return (
  //     <div></div>
  //   )

  // } else {

    return (
      <div>
        <Heading pageHeadingName='Delegates'>
          <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded inline-flex items-center" onClick={handleNewDelegateClick}>New Item</button>    
        </Heading>
        {
          delegates && delegates.length == 0 ? (
            <Alert
              type="info"
              title="Add a bidder delegate"
              message={<>
                <p>Bidder delegates are a node connected to the DAX SVM network that you grant permission to bid on your behalf based 
                  on the configuration in your Campaigns.
                </p>
                <p>For the purpose of the demo, you can add the public Bidder Node: <span className="font-bolder">
                  <PubkeyDisplay pubkey={new PublicKey("9TVQtierp8DB9DD4gCPXkzDXgzrV53J1mw184V2VPi2f")}/>
                  </span></p>
              </>}
              ctaText="Add Delegate"
              ctaAction={()=>setIsBidderDelegateModalOpen(true)}
              dismissible
            />
        ):("")
      }
        <DelegateTable 
          delegates={delegates} 
          isLoading={isLoading} 
          advertiser={advertiser}
          refreshDelegates={fetchDelegates}
        />
        {
            (advertiser && delegates) ? (
              <BidderDelegateModal
                  isOpen={isBidderDelegateModalOpen}
                  onClose={() => setIsBidderDelegateModalOpen(false)}
                  existingDelegates={delegates.map((d)=>(d.delegate))}
                  advertiser={advertiser}
                  refreshDelegates={fetchDelegates}
              />
            ) : ("")
        }
      </div>

    )
  // }
}
