import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
 
import Campaigns from './campaigns/Campaigns';
import CampaignForm from './campaigns/CampaignForm';
import Overview from './overview/Overview';
import Properties from './properties/Properties';
import PropertyNew from './properties/PropertyNew';
import Explorer from './explorer/Explorer';
import Delegates from './delegates/Delegates';
import Creatives from './creatives/Creatives';


const DashboardRoutes: React.FC = () => (
  <Routes>
    <Route element={<DashboardLayout />}>
      <Route index element={<Navigate to="overview" replace />} />
      <Route path="campaigns" element={<Campaigns />} />
      <Route path="campaigns/new" element={<CampaignForm />} />
      <Route path="campaigns/manage/:campaignId" element={<CampaignForm />} />
      <Route path="creatives" element={<Creatives />} />
      <Route path="delegates" element={<Delegates />} />
      <Route path="delegates/new" element={<Delegates />} />
      <Route path="properties" element={<Properties />} />
      <Route path="properties/new" element={<PropertyNew />} />
      <Route path="overview" element={<Overview />} />
      <Route path="explorer" element={<Explorer />} />
      <Route path="explorer/placement/:placementId" element={<Explorer />} />
      <Route path="explorer/account/:accountPubkey" element={<Explorer />} />
    </Route>
  </Routes>
);

export default DashboardRoutes;