import React from "react";
import { CheckIcon } from '@heroicons/react/20/solid';

interface Step {
  id: string;
  name: string;
  status: 'complete' | 'current' | 'upcoming';
}

interface CampaignStepsProps {
  steps: Step[];
  currentStep: number;
  onStepClick: (stepId: string) => void;
  isNewCampaign: boolean;
}

const CampaignSteps: React.FC<CampaignStepsProps> = ({ steps, currentStep, onStepClick, isNewCampaign }) => {
  const handleStepClick = (stepId: string) => {
    if (!isNewCampaign) {
      onStepClick(stepId);
    }
  };

  const renderStepContent = (step: Step, stepIdx: number) => {
    const isClickable = !isNewCampaign || step.status === 'complete' || step.status === 'current';

    switch (step.status) {
      case 'complete':
        return (
          <div className={`group flex w-full items-center ${isClickable ? 'cursor-pointer' : 'opacity-50'}`} onClick={() => handleStepClick(step.id)}>
            <span className="flex items-center px-6 py-4 text-sm font-medium">
              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <span className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-200">{step.name}</span>
            </span>
          </div>
        );
      case 'current':
        return (
          <div aria-current="step" className={`flex items-center px-6 py-4 text-sm font-medium ${isClickable ? 'cursor-pointer' : 'opacity-50'}`} onClick={() => handleStepClick(step.id)}>
            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600 dark:border-indigo-500">
              <span className="text-indigo-600 dark:text-indigo-500">{step.id}</span>
            </span>
            <span className="ml-4 text-sm font-medium text-indigo-600 dark:text-indigo-500">{step.name}</span>
          </div>
        );
      case 'upcoming':
        return (
          <div className={`group flex items-center ${isClickable ? 'cursor-pointer' : 'opacity-50'}`} onClick={() => handleStepClick(step.id)}>
            <span className="flex items-center px-6 py-4 text-sm font-medium">
              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 dark:border-gray-700 group-hover:border-gray-400">
                <span className="text-gray-500 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-gray-200">{step.id}</span>
              </span>
              <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-gray-200">{step.name}</span>
            </span>
          </div>
        );
    }
  };

  return (
    <nav aria-label="Progress" className="mb-8">
      <ol role="list" className="divide-y divide-gray-300 dark:divide-gray-600 rounded-md border border-gray-300 dark:border-gray-600 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {renderStepContent(step, stepIdx)}
            {stepIdx !== steps.length - 1 && (
              <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                <svg
                  className="h-full w-full text-gray-300 dark:text-gray-600"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default CampaignSteps;