import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import { Dax } from "../dax";
import { PublicKey, Keypair, Transaction, VersionedTransaction } from "@solana/web3.js";
import * as secp256k1 from 'secp256k1';
import nacl from "tweetnacl";
import { createAccountDiscriminator } from "./utils";


// export type ReactWallet = {
//     publicKey: PublicKey;
//     signTransaction: (transaction: Transaction | VersionedTransaction) => Promise<Transaction | VersionedTransaction>;
//     signMessage: (message: string) => Promise<string>;
// }


export default class Core {

    private _rollupProgram: Program<Dax>;
    private _baseProgram: Program<Dax>;
    private _keypair: Keypair | undefined;
    private _wallet: anchor.Wallet | undefined;
    private _eventParser: anchor.EventParser;

    constructor( 
        baseConnection: anchor.web3.Connection,
        rollupConnection: anchor.web3.Connection,
        idl: Dax,
        programId: PublicKey,
        keypair?: Keypair,
        wallet?: anchor.Wallet ,
        opts: anchor.web3.ConfirmOptions = {}
    ) {
        this._keypair = keypair;
        this._wallet = wallet;

        // Initialize baseProgram and rollupProgram based on keypair or wallet
        const baseProvider = this.createProvider(baseConnection, opts);
        const rollupProvider = this.createProvider(rollupConnection, opts);

        this._baseProgram = new Program(idl, programId, baseProvider);
        this._rollupProgram = new Program(idl, programId, rollupProvider);

        this._eventParser = new anchor.EventParser(
            programId,
            new anchor.BorshCoder(idl)
        );
    };

    private createProvider(connection: anchor.web3.Connection, opts: anchor.web3.ConfirmOptions): anchor.AnchorProvider {
        if (this._wallet) {
            return new anchor.AnchorProvider(
                connection,
                {
                    publicKey: this._wallet.publicKey,
                    signTransaction: async <T extends Transaction | VersionedTransaction>(transaction: T) => {
                        const signedTx = await this._wallet?.signTransaction(transaction);
                        return signedTx as T;
                    },
                    signAllTransactions: async <T extends Transaction | VersionedTransaction>(transactions: T[]) => {
                        const signedTxs = await Promise.all(transactions.map(async (transaction) => {
                            return await this._wallet?.signTransaction(transaction) as T;
                        }));
                        return signedTxs;
                    },
                },
                opts
            );
        } else if (this._keypair) {
            return new anchor.AnchorProvider(
                connection,
                {
                    publicKey: this._keypair.publicKey,
                    signTransaction: async (transaction) => {
                        if (transaction instanceof Transaction) {
                            transaction.partialSign(this._keypair as Keypair);
                        } else if (transaction instanceof VersionedTransaction) {
                            transaction.sign([this._keypair as Keypair]);
                        }
                        return transaction;
                    },
                    signAllTransactions: async (transactions) => {
                        // Sign all transactions using the keypair
                        for (const transaction of transactions) {
                            if (transaction instanceof Transaction) {
                                transaction.partialSign(this._keypair as Keypair);
                            } else if (transaction instanceof VersionedTransaction) {
                                transaction.sign([this._keypair as Keypair]);
                            }
                        }
                        return transactions;
                    },
                },
                opts
            );
        } else {
            throw new Error("Neither Keypair nor Wallet provided.");
        }
    }

    // async signMessage(
    //     message: Buffer
    // ): Promise<Buffer> {
    //     if (this._keypair) {
    //         return Promise.resolve(
    //             Buffer.from(
    //                 nacl.sign.detached(
    //                     message, 
    //                     Buffer.from(this._keypair!.secretKey)
    //                 )
    //             )
    //         );
    //     } else if (this._wallet) {
    //         return Buffer.from(await this._wallet.signMessage(message.toString('utf8')), 'utf-8');
    //     }
    //     throw new Error("No signing method available");
    // }

    get baseProgram(): Program<Dax> {
        return this._baseProgram;
    }

    get rollupProgram(): Program<Dax> {
        return this._rollupProgram;
    }

    get publicKey(): PublicKey {
        return this._keypair ? this._keypair.publicKey as PublicKey : this._wallet?.publicKey as PublicKey;
    }

    get keypair(): Keypair | undefined {
        return this._keypair;
    }
   
    get balancePubkey(): PublicKey {
        return this.deriveBalancePubkey(this.publicKey);
    }

    get eventParser(): anchor.EventParser {
        return this._eventParser;
    }


    verifyEd25519SignatureIxn(
        message: Buffer,
        signature: Buffer,
        signerPubkey: PublicKey
    ): anchor.web3.TransactionInstruction {
        return anchor.web3.Ed25519Program.createInstructionWithPublicKey({
            publicKey: signerPubkey.toBytes(),
            message,
            signature
        });
    }

    deriveNoncePubkey(
        ownerPubkey: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
              anchor.utils.bytes.utf8.encode("nonce"), 
              ownerPubkey.toBuffer()
            ],
            this.baseProgram.programId
        );
        return pk
    }

    deriveAuctionPubkey(
        placementId: string
    ): PublicKey {
        let placementIdBuffer = anchor.utils.bytes.bs58.decode(placementId);
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
              anchor.utils.bytes.utf8.encode("auction"), 
              placementIdBuffer.slice(0, 32),
              placementIdBuffer.slice(33),
            ],
            this.baseProgram.programId
        );
        return pk
    }

    deriveBidPubkey(
        bidId: Buffer
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
              anchor.utils.bytes.utf8.encode("bid"), 
              bidId.slice(0, 32),
              bidId.slice(33),
            ],
            this.baseProgram.programId
        );
        return pk
    }

    deriveBalancePubkey(
        ownerPubkey: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
              anchor.utils.bytes.utf8.encode("balance"), 
              ownerPubkey.toBuffer(),
            ],
            this.baseProgram.programId
        );
        return pk
    }

    derivePropertyPubkey(
        publisherPubkey: PublicKey,
        propertyId: number
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
              anchor.utils.bytes.utf8.encode("property"), 
              publisherPubkey.toBuffer(),
              new anchor.BN(propertyId).toArrayLike(Buffer, "le", 4)
            ],
            this.baseProgram.programId
        );
        return pk
    }

    deriveCampaignPubkey(
        advertiserPubkey: PublicKey,
        campaignId: number
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
              anchor.utils.bytes.utf8.encode("campaign"), 
              advertiserPubkey.toBuffer(),
              new anchor.BN(campaignId).toArrayLike(Buffer, "le", 4)
            ],
            this.baseProgram.programId
        );
        return pk
    }

    deriveCreativePubkey(
        advertiserPubkey: PublicKey,
        creativeId: number
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
              anchor.utils.bytes.utf8.encode("creative"), 
              advertiserPubkey.toBuffer(),
              new anchor.BN(creativeId).toArrayLike(Buffer, "le", 4)
            ],
            this.baseProgram.programId
        );
        return pk
    }


    deriveBidderDelegatePubkey(
        advertiserPubkey: PublicKey,
        bidderPubkey: PublicKey
      ): PublicKey {
          const [pk, _] = PublicKey.findProgramAddressSync(
              [
                anchor.utils.bytes.utf8.encode("delegate"), 
                advertiserPubkey.toBuffer(),
                bidderPubkey.toBuffer(),
              ],
              this.baseProgram.programId
          );
          return pk
    }

    async fetchAuctionForPlacement(
        placementId: string,
        commitment: anchor.web3.Commitment = "processed"
    ): Promise<anchor.IdlAccounts<Dax>["auction"] | null> {
        const auctionPubkey = this.deriveAuctionPubkey(
            placementId
        );
        return await this.baseProgram.account.auction.fetchNullable(auctionPubkey, commitment);
    }

    async fetchBidByPubkey(
        bidPubkey: PublicKey,
        commitment: anchor.web3.Commitment = "processed"
    ): Promise<anchor.IdlAccounts<Dax>["auctionBid"] | null> {
        return await this.baseProgram.account.auctionBid.fetchNullable(bidPubkey, commitment);
    }

    async fetchBid(
        bidderSignature: Buffer,
        commitment: anchor.web3.Commitment = "processed"    
    ): Promise<anchor.IdlAccounts<Dax>["auctionBid"] | null> {
        const bidPubkey = this.deriveBidPubkey(bidderSignature);
        return await this.fetchBidByPubkey(bidPubkey, commitment);
    }
    


    async fetchAuctionBalanceAccount(
        commitment: anchor.web3.Commitment = "processed"
    ): Promise<anchor.IdlAccounts<Dax>["balance"] | null> {
        return await this.baseProgram.account.balance.fetchNullable(this.balancePubkey, commitment);
    }

    async fetchBalance(
        commitment: anchor.web3.Commitment = "processed"
    ): Promise<number> {
        const balanceAccount = await this.fetchAuctionBalanceAccount(commitment);
        return balanceAccount ? Number(balanceAccount?.credits) : 0
    }
    
    async initializeBalanceIxn(): Promise<anchor.web3.TransactionInstruction> {
        let accounts = {
            payer: this.publicKey,
            authority: this.publicKey,
            balance: this.balancePubkey,
            systemProgram: anchor.web3.SystemProgram.programId,
        }
        const initializeBalanceIxn = await this.baseProgram.methods
            .initBalance({})
            .accounts( accounts).instruction();
        return initializeBalanceIxn;
    }

   
    async depositWithdrawIxn(
        balanceChange: number
    ): Promise<anchor.web3.TransactionInstruction> {
        let accounts = {
            payer: this.publicKey,
            owner: this.publicKey,
            balance: this.balancePubkey,
        }
        const initializeBalanceIxn = await this.baseProgram.methods
            .balanceDepositWithdraw({
                delta: new anchor.BN(balanceChange),
            })
            .accounts(accounts).instruction();
        return initializeBalanceIxn;
    }

    async initializeBalanceAccount(
        initialDeposit: number,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ): Promise<string> {
        if (!this.baseProgram?.provider || !this.publicKey) {
            throw new Error("Public key or provider is not initialized");
        }
        let tx = new anchor.web3.Transaction()
        tx.add(await this.initializeBalanceIxn());
        if (initialDeposit > 0) {
            tx.add(await this.depositWithdrawIxn(initialDeposit));
        }
        tx.feePayer = this.publicKey;
        tx.recentBlockhash = (await this.baseProgram.provider.connection.getLatestBlockhash()).blockhash;
        const provider = this.baseProgram.provider as anchor.AnchorProvider;
        try {
            tx.recentBlockhash = (await this.baseProgram.provider.connection.getLatestBlockhash()).blockhash;
            const txHash = await provider.sendAndConfirm(tx, [], { commitment: commitmentLevel });
            console.log("Transaction signature", tx);
            return txHash
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }

    async deposit(
        amount: number,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ): Promise<string> {
        if (!this.baseProgram?.provider || !this.publicKey) {
            throw new Error("Public key or provider is not initialized");
        }
        let tx = new anchor.web3.Transaction()
        tx.add(await this.depositWithdrawIxn(amount));
        tx.feePayer = this.publicKey;
        const provider = this.baseProgram.provider as anchor.AnchorProvider;
        try {
            tx.recentBlockhash = (await this.baseProgram.provider.connection.getLatestBlockhash()).blockhash;
            const txHash = await provider.sendAndConfirm(tx, [], { commitment: commitmentLevel });
            console.log("Transaction signature", tx);
            return txHash
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }

    async withdraw(
        amount: number,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ): Promise<string> {
        if (!this.rollupProgram?.provider || !this.publicKey) {
            throw new Error("Public key or provider is not initialized");
        }
        let tx = new anchor.web3.Transaction()
        tx.add(await this.depositWithdrawIxn(-amount));
        tx.feePayer = this.publicKey;
        const provider = this.baseProgram.provider as anchor.AnchorProvider;
        try {
            tx.recentBlockhash = (await this.baseProgram.provider.connection.getLatestBlockhash()).blockhash;
            const txHash = await provider.sendAndConfirm(tx, [], { commitment: commitmentLevel });
            console.log("Transaction signature", tx);
            return txHash
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
   
    deriveSharedSecret(
        privateKey: Buffer, 
        publicKey: Buffer
    ): Buffer {
        if (!secp256k1.privateKeyVerify(privateKey)) {
            throw new Error('Invalid private key');
        }
        const sharedSecret = Buffer.from(secp256k1.ecdh(publicKey, privateKey));
        return sharedSecret
    }
    
    extractCommittedPlacementRecord(
        txn: anchor.web3.TransactionResponse
    ): anchor.IdlEvents<Dax>["PlacementSettled"] | null {
        if (!txn?.meta?.err) {
            const events = Array.from(this._eventParser.parseLogs(
                txn!.meta!.logMessages ?? []
            ));
            const placementSettledEvent = events.find(event => event.name === "PlacementSettled");
            if (placementSettledEvent) {
                return placementSettledEvent.data as anchor.IdlEvents<Dax>["PlacementSettled"];
            }
        }
        return null;
    }

    async fetchCommittedPlacementRecord(
        txnHash: string
    ): Promise<anchor.IdlEvents<Dax>["PlacementSettled"] | null> {
        const txn = await this._baseProgram.provider.connection.getTransaction(
            txnHash
        );
        if (txn) {
            return this.extractCommittedPlacementRecord(txn);
        }
        return null
    }

    async fetchRecentCommittedPlacementRecord(
        publicKey?: PublicKey,
        beforeTxnHash?: string,
        limit: number = 100,
        finality: anchor.web3.Finality = "confirmed"
    ): Promise<{ signature: string, record: anchor.IdlEvents<Dax>["PlacementSettled"]}[]> {
        const txnHashes = await this._baseProgram.provider.connection.getSignaturesForAddress(
            publicKey ? publicKey : this.baseProgram.programId,
            {
                before: beforeTxnHash,
                limit: limit
            },
            finality
        );
        console.log(txnHashes)
        const txns = await this._baseProgram.provider.connection.getTransactions(
            txnHashes.map((txn) => txn.signature),
            finality
        );
        console.log(txns)
        const records: { signature: string, record: anchor.IdlEvents<Dax>["PlacementSettled"]}[] = [];
        txns.map((txn) => {
            if (txn) {
                const record = this.extractCommittedPlacementRecord(txn);
                if (record) {
                    records.push({
                        signature: txn?.transaction?.signatures[0],
                        record: record
                    });
                }
            }
        })
        return records
    }

    async fetchLogs(
        beforeTxnHash?: string,
        limit: number = 1000,
        finality: anchor.web3.Finality = "confirmed"
    ): Promise<anchor.web3.TransactionResponse[]> {
        const txnHashes = await this._baseProgram.provider.connection.getSignaturesForAddress(
            this.baseProgram.programId,
            {
                before: beforeTxnHash,
                limit: limit
            },
            finality
        );
        const txns = await this._baseProgram.provider.connection.getTransactions(
            txnHashes.map((txn) => txn.signature),
            finality
        );
        return txns.filter((r)=>{return r!=null}) as anchor.web3.TransactionResponse[]
    }

    async fetchUnknownAccountData(
        accountPubkey: PublicKey
    ): Promise<{
        accountType: keyof anchor.IdlAccounts<Dax> | null,
        data: anchor.IdlAccounts<Dax>[keyof anchor.IdlAccounts<Dax>] | null,
        lamports: number,
        length: number,
        owner: PublicKey
    } | null> { // Updated type
        try {
          // 1. Get account info
          const accountInfo = await this.baseProgram.provider.connection.getAccountInfo(accountPubkey);
          
          if (!accountInfo) {
            console.log("Account not found");
            return null;
          }
      
          // 2. Check if the account is owned by our program
          if (accountInfo.owner.equals(this.baseProgram.programId)) {
            
                // 3. Check if the account has more than 8 bytes
                if (accountInfo.data.length <= 8) {
                    console.log("Account data is too short");
                    return null;
                }
            
                // 4. Get the discriminator (first 8 bytes)
                const discriminator = accountInfo.data.slice(0, 8);
                console.log('discriminator:', anchor.utils.bytes.bs58.encode(discriminator))
            
                // 5. Get all account types from the IDL
                const accountTypes = this.baseProgram.idl.accounts.map((a)=>(a.name))
            
                // 6. Check if the discriminator matches any of our account types
                for (const accountType of accountTypes) {
                    const expectedDiscriminator = createAccountDiscriminator(accountType);
                    console.log(`expectedDiscriminator for ${accountType}: ${anchor.utils.bytes.bs58.encode(expectedDiscriminator)}`);

                    if (Buffer.compare(discriminator, expectedDiscriminator) === 0) {
                    // 7. If it matches, deserialize the account data
                    const deserialized = this.baseProgram.coder.accounts.decode(accountType, accountInfo.data);
                    return {
                            accountType: accountType as keyof anchor.IdlAccounts<Dax>,
                            data: deserialized,
                            lamports: accountInfo.lamports ? accountInfo.lamports/10^9 : 0,
                            length: accountInfo.data.length,
                            owner: accountInfo.owner
                        };
                    }
                }

            } else {
                return {
                    accountType: null,
                    data: null,
                    lamports: accountInfo.lamports ? accountInfo.lamports/10^9 : 0,
                    length: accountInfo.data.length,
                    owner: accountInfo.owner
                };
            }
        
          console.log("No matching account type found");
          return null;
        } catch (error) {
          console.error("Error parsing account data:", error);
          return null;
        }
      }
}