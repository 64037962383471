import React, { useState } from 'react';
import { PublicKey } from '@solana/web3.js';
import { ClipboardIcon, CheckCircleIcon } from '@heroicons/react/24/solid';

interface PubkeyDisplayProps {
  pubkey: PublicKey | string;
}

const PubkeyDisplay: React.FC<PubkeyDisplayProps> = ({ pubkey }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    const pubkeyString = typeof pubkey === 'string' ? pubkey : pubkey.toBase58();
    navigator.clipboard.writeText(pubkeyString).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    });
  };

  const pubkeyString = typeof pubkey === 'string' ? pubkey : pubkey.toBase58();
  const truncatedPubkey = `${pubkeyString.slice(0, 4)}...${pubkeyString.slice(-4)}`;

  return (
    <span className="inline-flex items-center space-x-1 cursor-pointer" onClick={handleCopy}>
      <span>{truncatedPubkey}</span>
      <span className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 text-gray-400 dark:bg-gray:700 dark:text-gray-200">
        {copied ? (
          <CheckCircleIcon className="h-4 w-4" />
        ) : (
          <ClipboardIcon className="h-4 w-4" />
        )}
      </span>
    </span>
  );
};

export default PubkeyDisplay;