import React, { useState, useEffect } from 'react';
import { useDaxProgram } from '../../../context/DaxProgramContext';
import { useBalance } from '../../../context/BalancesContext';
import { useToast } from '../../../context/ToastContext';
import * as anchor from "@coral-xyz/anchor";
import { LAMPORTS_PER_SOL, PublicKey, Transaction, TransactionInstruction, TransactionMessage, VersionedTransaction } from '@solana/web3.js';
import { usePrivy, useSolanaWallets } from '@privy-io/react-auth';
import { useAdvertiser } from '../../../context/AdvertiserContext';
import { DEFAULT_BIDDER_DELEGATE_PUBKEY } from '../../../bindings/advertiser';
import { insertBidderDelegate } from '../../../supabase';

interface BalanceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Spinner = () => (
  <div className="animate-spin rounded-full h-4 w-4 border-2 border-indigo-500 border-t-transparent"></div>
);

const BalanceModal: React.FC<BalanceModalProps> = ({ isOpen, onClose }) => {
  const [amount, setAmount] = useState<string>('0.1');
  const [action, setAction] = useState<'deposit' | 'withdraw'>('deposit');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { program } = useDaxProgram();
  const { walletBalance, creditsBalance, refreshBalances } = useBalance();
  const { showToast } = useToast();

  const {wallets} = useSolanaWallets();
  const { advertiser } = useAdvertiser();
  const {signMessage} = usePrivy();

  const wallet = wallets[0]; 
  
  const [publicKey, setPublicKey] = useState<PublicKey|undefined>(undefined);
  useEffect(() =>{
    if (wallet) {
      setPublicKey(new PublicKey(wallet.address));
    } else {
      setPublicKey(undefined);
    }
  }, [wallet])


  useEffect(() => {
    if (isOpen) {
      refreshBalances();
    }
  }, [isOpen]);

  useEffect(() => {
    validateTransaction();
  }, [amount, action, walletBalance, creditsBalance]);

  const validateTransaction = () => {
    if (!amount || isNaN(parseFloat(amount))) {
      setError('Please enter a valid amount');
      return;
    }

    const lamports = parseFloat(amount) * LAMPORTS_PER_SOL;

    if (action === 'withdraw' && (creditsBalance || 0) < lamports) {
      setError('Insufficient balance for withdrawal');
      return;
    }

    const estimatedTxCost = 5000; // lamports

    if ((walletBalance || 0) < estimatedTxCost) {
      setError('Insufficient balance for transaction fees');
      return;
    }

    setError(null);
  };

  const getExplorerUrl = (txSignature: string) => {
    const cluster = 'devnet';
    return `https://explorer.solana.com/tx/${txSignature}?cluster=${cluster}`;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!publicKey || !program || !amount || error) return;

    const lamports = parseFloat(amount) * LAMPORTS_PER_SOL;

    setIsLoading(true);

    try {
      const ixns: TransactionInstruction[] = [];

      const [accountPda] = PublicKey.findProgramAddressSync(
        [Buffer.from("balance"), publicKey.toBuffer()],
        program.programId
      );

  
      const provider = await wallet.getProvider();

      // Check if the user has a bidderDelegate, and if not add it

      if (action === 'deposit' && creditsBalance === null) {
        ixns.push(
          await program.methods.initBalance({})
            .accounts({
              payer: publicKey,
              authority: publicKey,
              balance: accountPda,
              systemProgram: anchor.web3.SystemProgram.programId,
            })
            .instruction()
        );
      }

      ixns.push(
        await program.methods.balanceDepositWithdraw({
          delta: new anchor.BN(action === 'deposit' ? lamports : -lamports)
        })
        .accounts({
          owner: publicKey,
          balance: accountPda,
          systemProgram: anchor.web3.SystemProgram.programId,
        }).instruction()
      );

      const bidderDelegate = await advertiser?.fetchBidderDelegate(DEFAULT_BIDDER_DELEGATE_PUBKEY);
      if (bidderDelegate == null && advertiser) {
        const [expectedStateAfterUpdate, expectedStateHash] = await advertiser.generateBidderDelegateState(DEFAULT_BIDDER_DELEGATE_PUBKEY, false);

        const { signature } = await provider.request({
            method: 'signMessage',
            params: {
                message: expectedStateHash.toString('base64'),
            }
        });
        const advertiserSignature = Buffer.from(signature, 'base64');
        const ed25519VerifyIxn = advertiser.verifyEd25519SignatureIxn(
          expectedStateHash,
          advertiserSignature,
          new PublicKey(wallet.address)
        );
        const mainIxn = await advertiser.initOrUpdateBidderDelegateIxn(DEFAULT_BIDDER_DELEGATE_PUBKEY, false)
        ixns.push(ed25519VerifyIxn);
        ixns.push(mainIxn);
      }

      const recentBlockhash = (await program.provider.connection.getLatestBlockhash()).blockhash;

      const txn = new Transaction();
      txn.add(...ixns);
      txn.feePayer = new PublicKey(wallet.address);
      txn.recentBlockhash = recentBlockhash; 

      const serializedTransaction = txn.serializeMessage();
      const {signature} = await provider.request({
        method: "signMessage",
        params: {
          // Base64-encode serialized transaction before passing to Privy
          message: serializedTransaction.toString('base64'),
        },
      });
      
      // Add the signature to the transaction. Make sure to base64 decode the signature.
      txn.addSignature(new PublicKey(wallet.address), Buffer.from(signature, 'base64'));
      if (!txn.verifySignatures()) {
        throw new Error('Invalid signature.');
      }
      
      // Serialize the transaction again with the signature, and send it to the network
      const signedTransaction = txn.serialize();
      const txHash = await program.provider.connection.sendRawTransaction(signedTransaction);
      await program.provider.connection.confirmTransaction(txHash);

      if (bidderDelegate == null && advertiser) {
        const id = advertiser.deriveBidderDelegatePubkey(new PublicKey(wallet.address), DEFAULT_BIDDER_DELEGATE_PUBKEY);
        console.log('id', id.toBase58());
        insertBidderDelegate(id.toBase58(), wallet.address, DEFAULT_BIDDER_DELEGATE_PUBKEY.toBase58());
      }

//       // Add the signature to the transaction. Make sure to base64 decode the signature.
//       tx.addSignature(sender, Buffer.from(signature, 'base64'));
//       if (!transaction.verifySignatures()) {
//         throw new Error('Invalid signature.');
//       }

//       // Serialize the transaction again with the signature, and send it to the network
//       const signedTransaction = transaction.serialize();
//       const txHash = await connection.sendRawTransaction(signedTransaction);
// `


      
      // const txnSerialized = Buffer.from(txn.serialize()).toString('base64');
      // // const txnSignature = await signMessage(txnSerialized);
      // const { signature: txnSignature  } = await provider.request({
      //   method: 'signMessage',
      //   params: {
      //       message: txnSerialized,
      //   }
      // });
      // const txSignatureB64 = anchor.utils.bytes.bs58.encode(Buffer.from(txnSignature, 'base64'));
      // const txSignatureUint8 = anchor.utils.bytes.base64.decode(txnSignature);
      // txn.addSignature(new PublicKey(wallet.address), txSignatureUint8)


      // // const txSignature = await tx.rpc();
      // // tx.recentBlockhash = recentBlockhash;
      // // tx.feePayer = new PublicKey(wallet.address);
      // // const { signature } = await provider.request({
      // //   method: 'signMessage',
      // //   params: {
      // //       message: txnSerialized,
      // //   }
      // // });
      // // console.log('txSignature', txSignature);
      // // tx.addSignature( new PublicKey(wallet.address), Buffer.from(signature, 'base64'));

      // await program.provider.connection.simulateTransaction(txn);
      // await program.provider.connection.sendTransaction(txn);

      // await program.provider.connection.confirmTransaction(txSignatureB64, "processed");

      const explorerLink = getExplorerUrl(txHash);
      showToast(
        <>
          Successfully {action}ed {amount} SOL. 
          <a href={explorerLink} target="_blank" rel="noopener noreferrer" className="underline">
            View transaction
          </a>
        </>, 
        'success'
      );

      await refreshBalances();
      setAmount('0.1');
    } catch (error: any) {
      if (error.message.includes("SendTransactionError")) {
        console.error("SendTransactionError:", error);
        const logs = await error?.getLogs();
        console.error(logs);
      }
      console.error("Transaction error:", error);

      if (error.message.includes("Simulated")) {
        showToast(`Transaction simulation failed: ${error.message}`, 'error');
      } else if (error.signature) {
        const explorerLink = getExplorerUrl(error.signature);
        showToast(
          <>
            Transaction failed. 
            <a href={explorerLink} target="_blank" rel="noopener noreferrer" className="underline">
              View error details
            </a>
          </>, 
          'error'
        );
      } else {
        showToast(`Transaction failed: ${error.message}`, 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl w-96 text-gray-900 dark:text-gray-100">
        <h2 className="text-2xl font-bold mb-4">
          Account Balance
        </h2>
        <div className="mb-4 text-gray-700 dark:text-gray-300">
          <p>SOL Balance: {walletBalance !== null ? (walletBalance / LAMPORTS_PER_SOL).toFixed(4) : 'Loading...'} SOL</p>
          <p>Credits: {creditsBalance !== null ? (creditsBalance / LAMPORTS_PER_SOL).toFixed(4) : '0.0000'} SOL</p>
        </div>
        <div className="flex mb-4">
          <button
            onClick={() => setAction('deposit')}
            className={`flex-1 py-2 text-center transition-colors ${
              action === 'deposit'
                ? 'bg-indigo-600 dark:bg-indigo-500 text-white'
                : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300'
            } rounded-tl-md rounded-bl-md`}
          >
            Deposit
          </button>
          <button
            onClick={() => setAction('withdraw')}
            className={`flex-1 py-2 text-center transition-colors ${
              action === 'withdraw'
                ? 'bg-indigo-600 dark:bg-indigo-500 text-white'
                : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300'
            } rounded-tr-md rounded-br-md`}
          >
            Withdraw
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Amount (SOL)
            </label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount"
              className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-indigo-500 dark:focus:border-indigo-400 transition-colors"
              step="0.000000001"
              min="0"
            />
          </div>
          {error && <p className="text-red-500 dark:text-red-400 text-sm">{error}</p>}
          <div className="flex justify-end space-x-2">
            <button
              type="submit"
              disabled={isLoading || !!error}
              className="px-4 py-2 bg-indigo-600 dark:bg-indigo-500 text-white rounded hover:bg-indigo-700 dark:hover:bg-indigo-600 transition-colors disabled:opacity-50 flex items-center justify-center"
            >
              {isLoading ? (
                <>
                  <Spinner />
                  <span className="ml-2">Processing...</span>
                </>
              ) : (
                action === 'deposit' ? 'Deposit' : 'Withdraw'
              )}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-400 dark:hover:bg-gray-500 transition-colors"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BalanceModal;