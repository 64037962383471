import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
// import ThemeToggle from './ThemeToggle';
// import ModeToggle from './ModeToggle';
// import BalanceModal from './BalanceModal';
// import { useMode } from '../context/ModeContext';
// import { useBalance } from '../context/BalancesContext';
// import BalanceWidget from './BalanceWidget';

require('@solana/wallet-adapter-react-ui/styles.css');

interface NavItem {
  name: string;
  to: string;
  public: boolean;
}

interface NavProps {
  isAuthenticated: boolean;
}

const navItems: NavItem[] = [
  { name: 'Explorer', to: '/explorer', public: true },
  { name: 'Dashboard', to: '/dashboard', public: false },
];

const dropdownItems: NavItem[] = [
  { name: 'About DAX', to: '/info', public: true },
  { name: 'For Advertisers', to: '/info', public: true },
  { name: 'For Publishers', to: '/info', public: true },
];

const ChevronIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
      clipRule="evenodd"
    />
  </svg>
);

const Nav: React.FC<NavProps> = ({ isAuthenticated }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  // const { mode } = useMode();
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);

  // ... (previous NavLink and Dropdown components remain the same)

  const handleBalanceWidgetClick = () => {
    setIsBalanceModalOpen(true);
  };

  const NavLink: React.FC<{ item: NavItem; mobile?: boolean }> = ({ item, mobile }) => (
    <Link
      to={item.to}
      className={`
        ${mobile ? 'block w-full' : 'inline-flex items-center'}
        px-3 py-2 text-base font-medium rounded-md
        ${location.pathname === item.to
          ? 'text-indigo-600 dark:text-indigo-400'
          : 'text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400'
        }
      `}
      onClick={() => setIsOpen(false)}
    >
      {item.name}
    </Link>
  );

  const Dropdown: React.FC<{ mobile?: boolean }> = ({ mobile }) => (
    <div className={`relative ${mobile ? 'w-full' : ''} group`}>
      <button
        className={`
          ${mobile ? 'w-full text-left' : 'inline-flex items-center'}
          px-3 py-2 text-base font-medium rounded-md
          text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400
        `}
      >
        <span className="mr-1">Information</span>
        <ChevronIcon className="h-5 w-5 transition-transform group-hover:rotate-180" />
      </button>
      <div className={`
        ${mobile ? 'static mt-0 w-full' : 'absolute mt-2 left-0 w-48 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out'}
        z-10 py-1 bg-white dark:bg-gray-800 rounded-md ${!mobile && 'shadow-lg'}
      `}>
        {dropdownItems.map((item) => (
          <NavLink key={item.name} item={item} mobile={mobile} />
        ))}
      </div>
    </div>
  );

  return (
    <nav className="bg-white dark:bg-gray-800 shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="/" className="text-xl font-bold text-indigo-600 dark:text-indigo-400">DAX</Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-4">
              <Dropdown />
              {navItems.map((item) => (
                (item.public || isAuthenticated) && <NavLink key={item.name} item={item} />
              ))}
            </div>
          </div>
          <div className="hidden sm:flex sm:items-center sm:space-x-4">
            {/* <ThemeToggle />
            { isAuthenticated ? 
              <>
                <ModeToggle />
                <BalanceWidget onClick={handleBalanceWidgetClick} /> 
              </>
            : "" }
            <WalletMultiButton className="!bg-indigo-600 hover:!bg-indigo-700 dark:!bg-indigo-500 dark:hover:!bg-indigo-600" /> */}
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 dark:hover:bg-gray-700"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state. */}
      <div className={`sm:hidden ${isOpen ? 'block' : 'hidden'}`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Dropdown mobile />
          {navItems.map((item) => (
            (item.public || isAuthenticated) && (
              <div key={item.name}>
                <NavLink item={item} mobile />
              </div>
            )
          ))}
        </div>
        <div className="px-4 pt-4 pb-3 space-y-1">
          {/* <Link to="/campaigns/create" className="block px-3 py-2 rounded-md text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600">
            Create Campaign
          </Link> */}
          {/* <ModeToggle />
          { isAuthenticated ? <BalanceWidget onClick={handleBalanceWidgetClick} /> : "" }
          <WalletMultiButton className="!bg-indigo-600 hover:!bg-indigo-700 dark:!bg-indigo-500 dark:hover:!bg-indigo-600 w-full" /> */}
        </div>
      </div>

      {/* {isAuthenticated && (
        <BalanceModal
          isOpen={isBalanceModalOpen}
          onClose={() => setIsBalanceModalOpen(false)}
        />
      )} */}

    </nav>
  );
};

export default Nav;