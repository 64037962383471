import { CpuChipIcon, FingerPrintIcon, LockOpenIcon, ScaleIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import React, { useRef, useEffect, useState } from 'react';
import { SiSolana } from 'react-icons/si';

const points = [
  {
      id: 0,
      heading: "Transparent, open & decentralized",
      body: <div className="flex flex-col gap-y-2">
        <p>AdX is an open and transparent protocol built on top of the Solana Virtual Machine (SVM).</p>
        <p>It makes use of technologies like elliptic curve signatures, symmetric encryption and more to ensure transparent and verifiable auctions.</p>
        <p>Placements are settled back to the Solana L1 for payment settlement, data availability and verification.</p>
      </div>,
      icon: <CpuChipIcon className="h-16"/>
  },
  {
      id: 1,
      heading: "Privacy preservation & data sovereignty",
      body: <div className="flex flex-col gap-y-2">
        <p>Invasive tracking tools are likely to be phased out in the future, as strong rules like like GDPR and CCPA take effect.</p>
        <p>New privacy-preserving platforms, data sovereignty tools and next-gen digital growth platforms will supplant the old.</p>
        <p>AdX attribution and identity models utilize developments in digital identity (DID) and homomorphic encryption to enable attribution and identity in a privacy preserving way.</p>
      </div>,
      icon: <FingerPrintIcon className="h-16"/>
  },
  {
      id: 2,
      heading: "Reduced fees & fairer value attribution",
      body: <div className="flex flex-col gap-y-2">
        <p>Frictional costs in the existing web2 ad exchange model can be as high as 50%. With an open model these costs can be greatly reduced.</p>
        <p>Beyond the core exchange, AdX.so intends to ship DePIN-inspired tools (browser and mobile plugins) enabling consumers to opt-in to participate in the benefits.</p>
        <p>Choosing to securely and privately make more targetting data available, or opting-in having more ads served will give users the opportunity to earn more rewards.</p>
      </div>,
      icon: <ScaleIcon className="h-16"/>
  },
]

const WhatIsAdX: React.FC = () => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const scrollToCard = (index: number) => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const cardWidth = carousel.offsetWidth * 0.8; // 80% of the container width
    carousel.scrollTo({
      left: index * cardWidth,
      behavior: 'smooth'
    });
    setActiveIndex(index);
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const handleScroll = () => {
      const scrollPosition = carousel.scrollLeft;
      const cardWidth = carousel.offsetWidth * 0.8; // 80% of the container width
      const newIndex = Math.round(scrollPosition / cardWidth);
      setActiveIndex(newIndex);
    };

    carousel.addEventListener('scroll', handleScroll);
    return () => carousel.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="flex-row w-full gap-8">
      <div className="w-full md:text-center">
        <h2 className="text-5xl md:text-6xl font-medium mb-12">What makes AdX.so different?</h2>
      </div>

      <div className="w-full flex items-center justify-center mt-4 md:mt-8 relative">
        <div 
          ref={carouselRef}
          className="flex-1 flex md:grid md:grid-cols-3 gap-4 overflow-x-auto snap-x snap-mandatory scrollbar-hide"
          style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          {points.map((p, index) => (
            <div 
              key={p.id} 
              className={`relative rounded-lg overflow-hidden items-center p-5 backdrop-blur-md bg-white/10 flex-shrink-0 w-4/5 md:w-full snap-center cursor-pointer`}
              style={{
                scrollSnapAlign: 'center',
              }}
              onClick={() => scrollToCard(index)}
            >
              <div className="mb-4">
                {p.icon}
              </div>
              <h3 className="text-2xl md:text-3xl font-medium mb-4">
                {p.heading}
              </h3>
              <p className="font-medium text-sm lg:text-lg z-10 text-gray-200">
                {p.body}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-4 md:hidden -mx-4 sm:-mx-6 lg:-mx-8">
        {points.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 rounded-full mx-1 ${
              index === activeIndex ? 'bg-white' : 'bg-gray-500'
            }`}
            onClick={() => scrollToCard(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default WhatIsAdX;