import React, { ReactNode } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useLocation, Link } from 'react-router-dom';

interface HeadingProps {
  pageHeadingName: string;
  children?: ReactNode;
}

const Heading: React.FC<HeadingProps> = ({ pageHeadingName, children }) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const truncate = (text: string): string => {
    if (text.length > 20) {
      return (text.slice(0, 15) + "..." + text.slice(-5))
    }
    return text
  }

  return (
    <div className="transition-colors duration-200 mb-6">
      <div>
        <nav aria-label="Back" className="sm:hidden">
          <a href="#" className="flex items-center text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300">
            <ChevronLeftIcon aria-hidden="true" className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-500" />
            Back
          </a>
        </nav>
        <nav aria-label="Breadcrumb" className="hidden sm:flex">
          <ol role="list" className="flex items-center space-x-4">
            
            {pathnames.map((name, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
              const isLast = index === pathnames.length - 1;
              return (
                <li key={name}>
                  <div className="flex items-center">
                    <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-500" />
                    {isLast ? (
                      <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                        {truncate(name.charAt(0).toUpperCase() + name.slice(1))}
                      </span>
                    ) : (
                      <Link
                        to={routeTo}
                        className="ml-1 text-sm font-medium text-gray-500 hover:text-blue-600 dark:text-gray-400 dark:hover:text-blue-400 md:ml-2"
                      >
                        {truncate(name.charAt(0).toUpperCase() + name.slice(1))}
                      </Link>
                    )}
                  </div>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">
            {pageHeadingName}
          </h2>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Heading;