import dotenv from "dotenv";
import { createClient } from "@supabase/supabase-js";
import { PublicKey } from "@solana/web3.js";

dotenv.config();

const supabase = createClient(
    "https://ikawexchbdvjpzefaeac.supabase.co",
    process.env.REACT_APP_SUPABASE_KEY as string
);

export type Property = {
    id: PublicKey;
    publisher: PublicKey;
    num: number;
    url: string;
    nonce: number;
    created_at: Date;
}

export const getProperties = async (
    filters: {
        id?: string; // PublicKey;
        publisher?: string; // PublicKey;
        url?: string;
    }
): Promise<Property[]> => {
    const query = supabase.from("properties").select().match(filters);
    const { data, error } = await query;
    if (error) {
        console.error("Error fetching properties:", error);
        return [];
    }
    return data || [];
};

export type BidderDelegate = {
    id: PublicKey;
    advertiser: PublicKey;
    delegate: PublicKey;
    nonce: number;
    created_at: Date;
}

export const getBidderDelegates = async (filters: {
    id?: PublicKey;
    advertiser?: string; // PublicKey;
    delegate?: PublicKey;
}): Promise<BidderDelegate[]> => {
    const query = supabase.from("bidder_delegates").select().match(filters);
    const { data, error } = await query;
    if (error) {
        console.error("Error fetching bidder delegates:", error);
        return [];
    }
    return data || [];
};

export const insertBidderDelegate = async (
    id: string, // PublicKey
    advertiser: string, // PublicKey
    delegate: string, // PublicKey
    nonce: number = 1,
): Promise<BidderDelegate | null> => {
    const { data, error } = await supabase.from("bidder_delegates").insert({
        id,
        advertiser,
        delegate,
        nonce
    });
    if (error) {
        console.error("Error inserting bidder delegate:", error);
        return null;
    }
    return data || null;
};

export enum CreativeStatus {
    Uninitialized = 'Uninitialized',
    Active = 'Active',
    Suspended = 'Suspended'
}

export enum Language {
    En = 'En',
    Cn = 'Cn',
    Fr = 'Fr',
    Es = 'Es',
    De = 'De'
}

export enum CreativeAssetType {
    Undefined = 'Undefined',
    Image = 'Image',
    Video = 'Video',
    Copy = 'Copy'
}

export type ImageAsset = {
    uri: string;
    width: number;
    height: number;
    max_width: number;
    max_height: number;
    min_width: number;
    min_height: number;
};

export type VideoAsset = {
    uri: string;
    width: number;
    height: number;
    duration: number;
    max_width: number;
    max_height: number;
    min_width: number;
    min_height: number;
    min_duration: number;
};

export type CopyAsset = {
    title: string;
    body: string;
    cta_text: string;
};

export type CreativeAsset = 
    | { type: CreativeAssetType.Undefined }
    | { type: CreativeAssetType.Image; data: ImageAsset }
    | { type: CreativeAssetType.Video; data: VideoAsset }
    | { type: CreativeAssetType.Copy; data: CopyAsset };

export type Creative = {
    id: PublicKey;
    advertiser: PublicKey;
    num: number;
    nonce: number;
    status: CreativeStatus;
    asset: CreativeAsset;
    cta_url: string;
    languages: Language[];
    created_at: Date;
}

export const getCreatives = async (filters: {
    id?: string; // PublicKey;
    advertiser?: string; // PublicKey;
    status?: CreativeStatus;
    asset?: CreativeAssetType;
    languages?: Language[];
    cta_url?: string;
}): Promise<Creative[]> => {
    const query = supabase.from("creatives").select().match(filters);
    const { data, error } = await query;
    if (error) {
        console.error("Error fetching creatives:", error);
        return [];
    }
    return data || [];
};

export enum CampaignStatus {
    Uninitialized = "Uninitialized",
    Active = "Active",
    Paused = "Paused",
    Completed = "Completed",
    Cancelled = "Cancelled"
}

export enum Budget {
    Undefined = "Undefined",
    Daily = "Daily",
    Total = "Total"
}

export type BudgetDetails = 
    | { type: Budget.Undefined }
    | { type: Budget.Daily; max_spend_per_day: number }
    | { type: Budget.Total; campaign_total: number };

export enum Bidding {
    Undefined = "Undefined",
    CostPerImpression = "CostPerImpression"
}

export type BiddingDetails = 
    | { type: Bidding.Undefined }
    | { type: Bidding.CostPerImpression; amount: number };

export enum Gender {
    Male = "Male",
    Female = "Female",
    Other = "Other"
}

export type Campaign = {
    id: string; // PublicKey;
    advertiser: string; // PublicKey;
    nonce: number;
    campaign_id: number | null;
    status: CampaignStatus | null;
    name: string | null;
    start_timestamp: number | null;
    end_timestamp: number | null;
    budget: BudgetDetails | null;
    bidding: BiddingDetails | null;
    age_range: number[] | null;
    net_worth_range: number[] | null;
    income_range: number[] | null;
    gender_inclusions: Gender[] | null;
    gender_exclusions: Gender[] | null;
    region_inclusions: string[] | null;
    region_exclusions: string[] | null;
    education_inclusions: string[] | null;
    education_exclusions: string[] | null;
    device_type_inclusions: string[] | null;
    device_type_exclusions: string[] | null;
    category_inclusions: string[] | null;
    category_exclusions: string[] | null;
    keyword_inclusions: string[] | null;
    keyword_exclusions: string[] | null;
    image_creatives: Creative[] | null;
    video_creatives: Creative[] | null;
    copy_creatives: Creative[] | null;
    created_at: Date;
}

export const getCampaigns = async (filters: {
    id?: string; // PublicKey;
    advertiser?: string; // PublicKey;
    status?: CampaignStatus;
    name?: string;
    start_timestamp?: number;
    end_timestamp?: number;
    budget?: BudgetDetails;
    bidding?: BiddingDetails;
    age_range?: number[];
    net_worth_range?: number[];
    income_range?: number[];
    gender_inclusions?: Gender[];
    gender_exclusions?: Gender[];
    region_inclusions?: string[];
    region_exclusions?: string[];
    education_inclusions?: string[];
    education_exclusions?: string[];
    device_type_inclusions?: string[];
    device_type_exclusions?: string[];
    category_inclusions?: string[];
    category_exclusions?: string[];
    keyword_inclusions?: string[];
    keyword_exclusions?: string[];
    image_creatives?: Creative[];
    video_creatives?: Creative[];
    copy_creatives?: Creative[];
}): Promise<Campaign[]> => {
    const query = supabase.from("campaigns").select().match(filters);
    const { data, error } = await query;
    if (error) {
        console.error("Error fetching campaigns:", error);
        return [];
    }
    return data || [];
};

export type Placement = {
    id: string; // Requestor Signature
    requestor: string; // PublicKey;
    nonce: number;
    publisher: string; // PublicKey;
    property_id: number;
    el_gamal_public_key: string;
    context_hash: string;
    created_at: string; // Date;
};

export const getPlacements = async (
    filters: {
        id?: string;
        requestor?: PublicKey;
        publisher?: PublicKey;
        property_id?: number;
    }
): Promise<Placement[]> => {
    const query = supabase
        .from("placements")
        .select()
        .match(filters);

    const { data, error } = await query;
    if (error) {
        console.error("Error fetching placements:", error);
        return [];
    }
    return data || [];
};

export type Auction = {
    id: string; // PublicKey;
    authority: string; // PublicKey;
    publisher: string; // PublicKey;
    status: number;
    placement: string;
    requestor_signature: string;
    context: number;
    winning_bid: string; // PublicKey;
    content_id: string;
    advertiser: string; // PublicKey;
    bid_value: number;
    shared_secret: string;
    attestor_signature: string;
    signature: string;
    count_bids: number;
    created_at: string; // Date;
}

export const getAuctions = async (filters: {
    id?: PublicKey;
    authority?: PublicKey;
    publisher?: PublicKey;
    status?: number;
    placement?: string;
}): Promise<Auction[]> => {
    const query = supabase.from("auctions").select().match(filters);
    const { data, error } = await query;
    if (error) {
        console.error("Error fetching auctions:", error);
        return [];
    }
    return data || [];
};

export type Bid = {
    id: string; // PublicKey;
    auction: string; // PublicKey;
    bidder: string; // PublicKey;
    advertiser: string; // PublicKey;
    bid_signature: string;
    created_at: string; // Date;
}

export const getBids = async (filters: {
    id?: string; // PublicKey;
    auction?: string; // PublicKey;
    bidder?: string; // PublicKey;
    advertiser?: string; // PublicKey;
}): Promise<Bid[]> => {
    const query = supabase.from("bids").select().match(filters);
    const { data, error } = await query;
    if (error) {
        console.error("Error fetching bids:", error);
        return [];
    }
    return data || [];
};

export type PlacementWithAuction = Placement & {
    auction: Auction;
}

export const getPlacementsWithAuction = async (
    filters: {
        id?: string;
        requestor?: string; // PublicKey;
        publisher?: string; // PublicKey;
        property_id?: number;
    }
): Promise<PlacementWithAuction[]> => {
    const query = supabase
        .from("placements")
        .select(`
            *,
            auction:auctions (*)
        `)
        .match(filters)
        .order('created_at', { ascending: false });

    const { data, error } = await query;
    if (error) {
        console.error("Error fetching placements:", error);
        return [];
    }
    return data || [];
};

export type PlacementWithAuctionAndBids = Placement & {
    auction: Auction;
    bids: Bid[];
}

export const getPlacementsWithAuctionAndBids = async (
    filters: {
        id?: string;
        requestor?: PublicKey;
        publisher?: PublicKey;
        property_id?: number;
    }
): Promise<PlacementWithAuctionAndBids[]> => {
    const query = supabase
        .from("placements")
        .select(`
            *,
            auction:auctions (*),
            bids:bids (*)
        `)
        .match(filters);

    const { data, error } = await query;
    if (error) {
        console.error("Error fetching placements:", error);
        return [];
    }
    return data || [];
};

export type PlacementContext = {
    id: number;
    creative: Object;
    property: Object;
    userdata: Object;
    created_at: string;
}

export type PlacementWithAuctionAndContext = Placement & {
    auction: Auction;
    context: PlacementContext;
}

export const getPlacementsWithAuctionAndContext = async (
    filters: {
        id?: string;
        requestor?: PublicKey;
        publisher?: PublicKey;
        property_id?: number;
    }
): Promise<PlacementWithAuctionAndContext[]> => {
    const query = supabase
        .from("placements")
        .select(`
            *,
            auction:auctions (*),
            context:placement_contexts (*)
        `)
        .match(filters);

    const { data, error } = await query;
    if (error) {
        console.error("Error fetching placements:", error);
        return [];
    }
    return data || [];
};

export type PlacementWithAuctionContextAndBids = Placement & {
    auction: Auction;
    context: PlacementContext;
    bids: Bid[];
}
