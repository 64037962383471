import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import {
    ClipboardDocumentIcon,
    ArrowRightEndOnRectangleIcon
} from '@heroicons/react/24/outline';
import BalanceWidget from './BalanceWidget';
import BalanceModal from './BalanceModal';
import { usePrivy, Wallet, WalletWithMetadata, useSolanaWallets} from '@privy-io/react-auth';



export default function Profile() {

    const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
    const [wallet, setWallet] = useState<Wallet|undefined>(undefined);

    const handleBalanceWidgetClick = () => {
      setIsBalanceModalOpen(true);
    };

    const {authenticated, user, login, logout} = usePrivy();
    const {createWallet} = useSolanaWallets();



    useEffect(() => {

        if (!authenticated) {
            setWallet(undefined)
            return
        }

        const wallet = user?.linkedAccounts.find(
            (account): account is WalletWithMetadata =>
            account.type === 'wallet' &&
            account.walletClientType === 'privy' &&
            account.chainType === 'solana',
        );
    
        if (!wallet) {
            createWallet().then((w)=>{
                setWallet(wallet)
            });
        } else {
            setWallet(wallet)
        }
        
    })
    


  

    return (
        <>
            <div className="flex items-center gap-x-4 lg:px-6 py-3 text-sm font-semibold leading-6 text-gray-900 dark:text-white transition-colors duration-200">
                {authenticated ? (
                    <div className="w-full space-y-4">
                        <BalanceWidget onClick={handleBalanceWidgetClick} />
                        <div className="flex justify-between w-full">
                            <div className="flex mr-2">
                                {wallet?.address.slice(0, 5)+"..."+
                                wallet?.address.slice(wallet?.address.length-5, wallet?.address.length)}
                            </div>
                            <div className="flex gap-x-2">
                                <button 
                                    className="flex h-7 w-7 cursor-pointer shrink-0 items-center justify-center rounded-lg border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 text-[0.625rem] font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200"
                                    onClick={() => navigator.clipboard.writeText(wallet?.address ?? '')}
                                >
                                    <ClipboardDocumentIcon className="h-4 w-4 shrink-0" />
                                </button>
                                <button 
                                    className="flex h-7 w-7 cursor-pointer shrink-0 items-center justify-center rounded-lg border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 text-[0.625rem] font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200"
                                    // onClick={()=>{disconnect()}}
                                    onClick={()=>{logout()}}
                                >
                                    <ArrowRightEndOnRectangleIcon className="h-4 w-4 shrink-0" />
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <button 
                        // onClick={()=>{setModalVisible(true)}} 
                        onClick={login}
                        className="rounded-md bg-indigo-600 hover:bg-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-400 w-full p-2 text-center text-white transition-colors duration-200"
                    >
                        Connect
                    </button>
                )}
            </div>
            {authenticated && (
                <>
                <BalanceModal
                    isOpen={isBalanceModalOpen}
                    onClose={() => setIsBalanceModalOpen(false)}
                />
                </>
            )}
        </>
    );
}