import { sha256 } from "js-sha256";

export function stringToFixedLengthArray(input: string, length: number = 32): number[] {
    // Convert the string to a Uint8Array
    const encoder = new TextEncoder();
    const uint8Array = encoder.encode(input);
  
    // Create a fixed-length array, initialized with zeros
    const result = new Array(length).fill(0);
  
    // Copy the Uint8Array values to the result array
    for (let i = 0; i < Math.min(uint8Array.length, length); i++) {
      result[i] = uint8Array[i];
    }
  
    return result;
}


export function createAccountDiscriminator(name: string): Buffer {
  const capped = name.slice(0, 1).toUpperCase() + name.slice(1)
  return Buffer.from(sha256.digest("account:"+capped)).slice(0, 8);

}
