import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import Heading from '../Heading';
import ExplorerHome from './ExplorerHome';
import ExplorerSearchBar from './ExplorerSearchBar';
import ExplorerPlacement from './ExplorerPlacement';
import ExplorerAccount from './ExplorerAccount';


export default function Explorer() {


  const { placementId, accountPubkey } = useParams<{ placementId?: string; accountPubkey?: string }>();
  
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setNotFound(false);

      if (placementId) {
        // Fetch placement data
        try {
          // Replace this with your actual API call
          const response = await fetch(`/api/placement/${placementId}`);
          if (!response.ok) {
            throw new Error('Placement not found');
          }
          // Process the data if needed
        } catch (error) {
          console.error('Error fetching placement:', error);
          setNotFound(true);
        }
      } else if (accountPubkey) {
        // Fetch account data
        try {
          // Replace this with your actual API call
          const response = await fetch(`/api/account/${accountPubkey}`);
          if (!response.ok) {
            throw new Error('Account not found');
          }
          // Process the data if needed
        } catch (error) {
          console.error('Error fetching account:', error);
          setNotFound(true);
        }
      }

      setIsLoading(false);
    };

    fetchData();
  }, [placementId, accountPubkey]);

  const renderContent = () => {
    if (isLoading) {
      return <SkeletonLoader />;
    }

    if (notFound) {
      return <NotFound type={location.pathname.includes('/placement/') ? 'placement' : 'account'} />;
    }

    if (location.pathname.includes('/placement/') && placementId) {
      return <ExplorerPlacement placementId={placementId} />;
    }

    if (location.pathname.includes('/account/') && accountPubkey) {
      return <ExplorerAccount accountPubkey={accountPubkey} />;
    }

    return <ExplorerHome />;
  };

  return (
    <div>
      <Heading pageHeadingName='Explorer' />
      <ExplorerSearchBar />
      {renderContent()}
    </div>
  );
}

const SkeletonLoader = () => (
  <div className="animate-pulse">
    <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
    <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
    <div className="h-4 bg-gray-200 rounded w-5/6 mb-4"></div>
  </div>
);

const NotFound = ({ type }: { type: 'placement' | 'account' }) => (
  <div className="text-center py-8">
    <h2 className="text-2xl font-bold mb-2">Not Found</h2>
    <p>The requested {type} could not be found.</p>
    <Link to="/explorer" className="text-blue-500 hover:underline mt-4 inline-block">
      Return to Explorer Home
    </Link>
  </div>
);