import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import { Dax } from "../dax";
import { keccak_256 } from 'js-sha3';
import { PublicKey, Keypair, MemcmpFilter, Commitment, TransactionInstruction } from "@solana/web3.js";
import * as secp256k1 from 'secp256k1';
import nacl from 'tweetnacl';
import Core  from "./core";
import { stringToFixedLengthArray } from "./utils";

const crypto = window.crypto;

// let crypto: any;
// async function initializeCrypto() {
//   if (typeof window === 'undefined') {
//     // Node.js environment
//     const cryptoTools = await import('crypto');
//     crypto = cryptoTools;
//   } else {
//     // Browser environment
//     crypto = window.crypto;
//   }
// }
// initializeCrypto();

export const DEFAULT_BIDDER_DELEGATE_PUBKEY = new PublicKey("9TVQtierp8DB9DD4gCPXkzDXgzrV53J1mw184V2VPi2f");

type EciesCiphertext = {
  ephemeralPublicKey: number[],
  encryptedData: Buffer,
  authTag: number[]
};

// Define the TypeScript interface for the arguments
export interface InitOrUpdateCampaignArgs {
  status?: CampaignStatus;
  objective?: Objective;
  name?: string;  
  startTimestamp?: number;
  endTimestamp?: number;
  budget?: Budget;
  bidding?: Bidding;
  ageRange?: number[] | null;  // Uint8Array of length 2
  netWorthRange?: number[] | null;  // Uint32Array of length 2
  incomeRange?: number[] | null;  // Uint32Array of length 2
  genderInclusions?: Gender[];
  genderExclusions?: Gender[];
  regionInclusions?: string[];
  regionExclusions?: string[];
  eductionInclusions?: Education[];
  eductionExclusions?: Education[];
  deviceTypeInclusions?: DeviceType[];
  deviceTypeExclusions?: DeviceType[];
  categoryInclusions?: PropertyCategory[];
  categoryExclusions?: PropertyCategory[];
  keywordInclusions?: string[];
  keywordExclusions?: string[];
  imageCreatives?: PublicKey[];
  videoCreatives?: PublicKey[];
  copyCreatives?: PublicKey[];
}


export interface InitOrUpdateCreativeArgs {
  status?: CreativeStatus | null;
  asset?: CreativeAsset | null;
  ctaUrl?: string | null;
  languages?: Language[] | null;
}

// You'll need to define these types based on your Rust enum definitions
export type CreativeStatus = { uninitialized: {}} |  { active: {}} |  { suspended: {}};
export type CreativeAsset = { image: ImageAsset } | { video: VideoAsset } | { copy: CopyAsset };
export type Language = { en: {} } | { cn: {} } | { fr: {} } | { es: {} } | { de: {} };

export interface ImageAsset {
  uri: string;
  width: number;
  height: number;
  maxWidth: number;
  maxHeight: number;
  minWidth: number;
  minHeight: number;
}

export interface VideoAsset {
  uri: string;
  width: number;
  height: number;
  duration: number;
  maxWidth: number;
  maxHeight: number;
  minWidth: number;
  minHeight: number;
  minDuration: number;
}

export interface CopyAsset {
  title: string;
  body: string;
  ctaText: string;
}

export type CampaignStatus = 
  | { uninitialized: {} }
  | { active: {} }
  | { paused: {} }
  | { completed: {} }
  | { cancelled: {} };

export type Objective = 
  | { undefined: {} }
  | { impressions: {} }
  | { conversions: {} };

export type Budget = 
  | { undefined: {} }
  | { daily: { maxSpendPerDay: anchor.BN } }
  | { total: { campaignTotal: anchor.BN } };

export type Bidding = 
  | { undefined: {} }
  | { costPerImpression: { amount: anchor.BN } };
// Additional types used in UserContext and PropertyContext
export type Gender = 
  | { male: {} }
  | { female: {} }
  | { other: {} };

export type Education = 
  | { highSchool: {} }
  | { bachelor: {} }
  | { masters: {} }
  | { phd: {} }
  | { other: {} };

export type DeviceType = 
  | { desktop: {} }
  | { mobileWeb: {} }
  | { nativeApp: {} };

export type PropertyCategory = 
  | { news: {} }
  | { sports: {} }
  | { entertainment: {} }
  | { finance: {} }
  | { lifestyle: {} }
  | { other: {} };


  


export default class Advertiser extends Core {

    private _listenWebsocketId: number | undefined;

    constructor(
        baseConnection: anchor.web3.Connection,
        rollupConnection: anchor.web3.Connection,
        idl: Dax,
        programId: PublicKey,
        keypair?: Keypair,
        wallet?: anchor.Wallet,
        opts: anchor.web3.ConfirmOptions = {}
    ) {
        super(baseConnection, rollupConnection, idl, programId, keypair, wallet, opts);
    }
    


    async initOrUpdateBidderDelegateIxn(
        delegatePubkey: PublicKey,
        revoke: boolean = false,
    ): Promise<anchor.web3.TransactionInstruction> {
          let accounts = {
            payer: this.publicKey,
            advertiser: this.publicKey,
            balance: this.balancePubkey,
            delegate: delegatePubkey,
            bidderDelegate: this.deriveBidderDelegatePubkey(this.publicKey, delegatePubkey),
            systemProgram: anchor.web3.SystemProgram.programId,
            instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
        };
        const ixn = await this.baseProgram.methods
            .initOrUpdateBidderDelegate({
              revoke: revoke
            })
            .accounts( accounts ).instruction();
        return ixn;
    }

    async initOrUpdateBidderDelegate(
      delegatePubkey: PublicKey,
      revoke: boolean,
      nonce?: number
  ): Promise<string> {
    let tx = new anchor.web3.Transaction()
    const [ed25519VerifyIxn, mainIxn] = await this.initOrUpdateBidderDelegateIxns(delegatePubkey, revoke, nonce)
    tx.add(ed25519VerifyIxn);
    tx.add(mainIxn);
    tx.feePayer = this.publicKey;
    const provider = this.rollupProgram.provider as anchor.AnchorProvider;
    tx.recentBlockhash = (await this.rollupProgram.provider.connection.getLatestBlockhash()).blockhash;
    const txHash = await provider.sendAndConfirm(tx);
    return txHash
  }
   

    async initOrUpdateBidderDelegateIxns(
        delegatePubkey: PublicKey,
        revoke: boolean,
        nonce?: number
    ): Promise<TransactionInstruction[]> {
        if (!nonce) {
          const bidderDelegatePubkey = this.deriveBidderDelegatePubkey(this.publicKey, delegatePubkey);
          const bidderDelegateAccount = await this.baseProgram.account.bidderDelegate.fetchNullable(bidderDelegatePubkey);
          if (!bidderDelegateAccount) {
            nonce = 1
          } else {
            nonce = bidderDelegateAccount.nonce
          }
        }
        // Generate the expected property after the update
        const [expectedBidderDelegateStateAfterUpdate, expectedBidderDelegateStateHash, advertiserSignature] = await this.generateBidderDelegateStateAndSignature(
            delegatePubkey,
            revoke,
            nonce
        );
        const ed25519VerifyIxn = this.verifyEd25519SignatureIxn(
            expectedBidderDelegateStateHash,
            advertiserSignature,
            this.publicKey
        );
        const mainIxn = await this.initOrUpdateBidderDelegateIxn(delegatePubkey, revoke);
        return [ed25519VerifyIxn, mainIxn]
    }

    async generateBidderDelegateStateAndSignature(
      delegatePubkey: PublicKey,
      revoke: boolean,
      nonce?: number
    ): Promise<[anchor.IdlAccounts<Dax>["bidderDelegate"], Buffer, Buffer]> {
        if (!nonce) {
          const bidderDelegatePubkey = this.deriveBidderDelegatePubkey(this.publicKey, delegatePubkey);
          const bidderDelegateAccount = await this.baseProgram.account.bidderDelegate.fetchNullable(bidderDelegatePubkey);
          console.log(bidderDelegateAccount);
          if (!bidderDelegateAccount) {
            nonce = 1
          } else {
            nonce = bidderDelegateAccount.nonce + 1
          }
          console.log(nonce);
        }
        const expectedStateAfterUpdate = {
            advertiser: this.publicKey,
            delegate: revoke ? anchor.web3.SystemProgram.programId : delegatePubkey,
            nonce: nonce
        } as anchor.IdlAccounts<Dax>["bidderDelegate"];
        console.log(expectedStateAfterUpdate);
        const expectedStateAfterUpdateBytes = (await this.rollupProgram.coder.accounts
            .encode("bidderDelegate", expectedStateAfterUpdate)).subarray(8); // Remove account discriminator
        const expectedStateHash = Buffer.from(keccak_256(expectedStateAfterUpdateBytes), 'hex');
        const advertiserSignature = Buffer.from(
            nacl.sign.detached(
                expectedStateHash, 
                this.keypair!.secretKey
            )
        ); 
        return [expectedStateAfterUpdate, expectedStateHash, advertiserSignature]
    }

    async generateBidderDelegateState(
      delegatePubkey: PublicKey,
      revoke: boolean,
      nonce?: number
    ): Promise<[anchor.IdlAccounts<Dax>["bidderDelegate"], Buffer]> {
        if (!nonce) {
          const bidderDelegatePubkey = this.deriveBidderDelegatePubkey(this.publicKey, delegatePubkey);
          const bidderDelegateAccount = await this.baseProgram.account.bidderDelegate.fetchNullable(bidderDelegatePubkey);
          console.log(bidderDelegateAccount);
          if (!bidderDelegateAccount) {
            nonce = 1
          } else {
            nonce = bidderDelegateAccount.nonce + 1
          }
          console.log(nonce);
        }
        const expectedStateAfterUpdate = {
            advertiser: this.publicKey,
            delegate: revoke ? anchor.web3.SystemProgram.programId : delegatePubkey,
            nonce: nonce
        } as anchor.IdlAccounts<Dax>["bidderDelegate"];
        console.log(expectedStateAfterUpdate);
        const expectedStateAfterUpdateBytes = (await this.rollupProgram.coder.accounts
            .encode("bidderDelegate", expectedStateAfterUpdate)).subarray(8); // Remove account discriminator
        const expectedStateHash = Buffer.from(keccak_256(expectedStateAfterUpdateBytes), 'hex');
        return [expectedStateAfterUpdate, expectedStateHash]
    }

    async initBidderDelegate(
      delegatePubkey: PublicKey,
      nonce?: number
    ) {
      return await this.initOrUpdateBidderDelegate(delegatePubkey, false, nonce);
    }

    async revokeBidderDelegate(
      delegatePubkey: PublicKey,
      nonce?: number
    ) {
      return await this.initOrUpdateBidderDelegate(delegatePubkey, true, nonce);
    }


    async generateBid(
      placement: anchor.IdlTypes<Dax>["Placement"],
      placementId: Buffer,
      bidAmount: number,
      contentId: PublicKey,
      advertiserPubkey?: PublicKey,
    ): Promise<[anchor.IdlTypes<Dax>["Bid"], Buffer]> {
        const requestorElGamalPublicKey = secp256k1.publicKeyConvert(
          Buffer.from(placement.elGamalPublicKey),
          true
        );
        const [encryptedValue, sharedSecretCommitment] = await this.encryptBid(
          bidAmount,
          contentId,
          requestorElGamalPublicKey
        );
        const bid = {
          placementId: Array.from(placementId),
          advertiser: advertiserPubkey ? advertiserPubkey : this.publicKey,
          sharedSecretCommitment: sharedSecretCommitment,
          encryptedValue: encryptedValue,
        } as anchor.IdlTypes<Dax>["Bid"];
        const bidBytes = this.rollupProgram.coder.types.encode("Bid", bid);
        if (!this.keypair) {
          throw new Error("Keypair not provided");
        }
        const authoritySignature = Buffer.from(
                nacl.sign.detached(
                bidBytes, 
                this.keypair.secretKey
            )
        );
        return [bid, authoritySignature];
    }

      

    async listenForLogs(
        callbackFunction: (logs:any, context:any) => void,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        await this.closeListenForLogs();
        const websocketId = await this.rollupProgram.provider.connection.onLogs(
            this.rollupProgram.programId,
            callbackFunction,
            commitmentLevel,
        );
        this._listenWebsocketId = websocketId;
    };

    async closeListenForLogs() {
      if (this._listenWebsocketId != undefined) {
          await this.rollupProgram.provider.connection.removeOnLogsListener(
              this._listenWebsocketId
          );
          this._listenWebsocketId = undefined;
      }
    }

    async startListening(
      commitmentLevel: anchor.web3.Commitment = "processed",
    ) {
        this.listenForLogs(
            await this.assessPlacement.bind(this),
            commitmentLevel
        );
    }

    async assessPlacement(
      logs: any,
      context: any,
      commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        if (logs.err) {
            // Skip error'd transactions
        } else {
            const events = Array.from(this.eventParser.parseLogs(
                logs.logs ?? []
            ));
            const event = events.find(event => event.name === "AuctionStarted");
            if (event) {
                  console.log("-------------------------------------------------")
                  console.log(`[${(new Date()).toISOString().slice(11, 19)}] NEW AUCTION:  ${event.data.auction}`)
                  console.log(`   User Context: ${JSON.stringify(event.data.placement)}`)
                  console.log(`   User Context: ${JSON.stringify(event.data.context)}`)
                  console.log("   => TODO: Determine whether we're interested in bidding or not...")
                  console.log("-------------------------------------------------")
            }
        };
    }


      async encryptBid(
        bid: number, 
        creativeId: PublicKey, 
        requestorElGamalPublicKey: Uint8Array
    ): Promise<[EciesCiphertext, number[]]> {
        const bytesToEncrypt = Buffer.concat([
          new anchor.BN(bid).toBuffer('le', 8),
          creativeId.toBuffer()
        ]);
    
        // Generate ephemeral key pair
        const ephemeralKeyPair = await crypto.subtle.generateKey(
            { name: 'ECDH', namedCurve: 'P-256' },
            true,
            ['deriveKey', 'deriveBits']
        );
        const ephemeralPublicKey = await crypto.subtle.exportKey('raw', ephemeralKeyPair.publicKey);
    
        // Perform ECDH-like key derivation
        const importedPublicKey = await crypto.subtle.importKey(
            'raw',
            requestorElGamalPublicKey,
            { name: 'ECDH', namedCurve: 'P-256' },
            true,
            []
        );
        const sharedSecret = await crypto.subtle.deriveBits(
            { name: 'ECDH', public: importedPublicKey },
            ephemeralKeyPair.privateKey,
            256
        );
    
        const sharedSecretCommitment = Array.from(new Uint8Array(await crypto.subtle.digest('SHA-256', sharedSecret)));
    
        // Derive AES key
        const aesKey = await crypto.subtle.importKey(
            'raw',
            await crypto.subtle.digest('SHA-256', sharedSecret),
            { name: 'AES-GCM' },
            false,
            ['encrypt']
        );
    
        // Encrypt the bid
        const iv = crypto.getRandomValues(new Uint8Array(12));
        const encryptedData = await crypto.subtle.encrypt(
            { name: 'AES-GCM', iv: iv },
            aesKey,
            bytesToEncrypt
        );
    
        return [
          {
            ephemeralPublicKey: Array.from(new Uint8Array(ephemeralPublicKey)),
            encryptedData: Buffer.from(encryptedData),
            authTag: Array.from(new Uint8Array(encryptedData.slice(-16)))
          } as EciesCiphertext,
          sharedSecretCommitment
        ];
    }
      

    // encryptBid(
    //     bid: number, 
    //     creativeId: PublicKey, 
    //     requestorElGamalPublicKey: Uint8Array
    // ): [EciesCiphertext, number[]] {
    //     const bytesToEncrypt = Buffer.concat([
    //       new anchor.BN(bid).toBuffer('le', 8),
    //       creativeId.toBuffer()
    //     ])
    //     // Generate ephemeral key pair
    //     const ephemeralPrivateKey = cryptoTools.randomBytes(32);
    //     const ephemeralPublicKey = secp256k1.publicKeyCreate(ephemeralPrivateKey, true); 
    //     // Perform ECDH-like
    //     const sharedSecret = this.deriveSharedSecret(
    //       ephemeralPrivateKey, 
    //       Buffer.from(requestorElGamalPublicKey)
    //     );
    //     const sharedSecretCommitment = Array.from(Buffer.from(keccak_256(sharedSecret), 'hex'));
    //     // Derive AES key
    //     const aesKey = Buffer.from(keccak_256(sharedSecret), 'hex');
    //     // Encrypt the bid
    //     const iv = Buffer.from([0,0,0,0,0,0,0,0,0,0,0,0]);
    //     const cipher = cryptoTools.createCipheriv('aes-256-gcm', aesKey, iv);
    //     const encrypted = Buffer.concat([
    //       iv, 
    //       cipher.update(bytesToEncrypt), 
    //       cipher.final()
    //     ]);
    //     const authTag = cipher.getAuthTag();
      
    //     return [
    //       {
    //         ephemeralPublicKey: Array.from(ephemeralPublicKey),
    //         encryptedData: encrypted,
    //         authTag: Array.from(authTag)
    //       } as EciesCiphertext,
    //       sharedSecretCommitment
    //     ];
    // }

    generateUpdateNonceVerification(
      nonce?:number
    ): [Buffer, Buffer] {
      if (!this.keypair) {
        throw new Error("Keypair not provided");
      }
      const noncebytes = new anchor.BN(nonce!).toArrayLike(Buffer, "le", 2);
      const nonceSignature = Buffer.from(
            nacl.sign.detached(
              noncebytes, 
              this.keypair.secretKey
        )
      );
      return [noncebytes, nonceSignature]
    }
    
    async initOrUpdateCampaign(
      campaignId: number,
      args: InitOrUpdateCampaignArgs,
      nonce?: number
    ): Promise<string> {
      if (!nonce) {
        try {
          const campaignState = await this.fetchCampaignById(campaignId);
          nonce = campaignState!.nonce + 1;
        } catch (e) {
          nonce = 1
        }
      }
      const [nonceBytes, nonceSignature] = this.generateUpdateNonceVerification(nonce);
      const ed25519VerifyIxn = this.verifyEd25519SignatureIxn(
        nonceBytes,
        nonceSignature,
        this.publicKey
      );
      const campaignPubkey = this.deriveCampaignPubkey(
        this.rollupProgram.provider.publicKey!,
        campaignId
      );
      // Prepare the accounts
      const accounts = {
        payer: this.rollupProgram.provider.publicKey!,
        advertiser: this.rollupProgram.provider.publicKey!,
        campaign: campaignPubkey,
        systemProgram: anchor.web3.SystemProgram.programId,
        instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
      };
    
      // TODO: Break this up if the package is likely too be too large
      const ixArgs = {
        id: campaignId,
        status: args.status ?? null,
        objective: args.objective ?? null,
        name: args.name !== undefined ? stringToFixedLengthArray(args.name) : null,
        startTimestamp: args.startTimestamp !== undefined ? new anchor.BN(args.startTimestamp) : null,
        endTimestamp: args.endTimestamp !== undefined ? new anchor.BN(args.endTimestamp) : null,
        budget: args.budget ?? null,
        bidding: args.bidding ?? null,
        ageRange: args.ageRange ?? null,
        netWorthRange: args.netWorthRange ?? null,
        incomeRange: args.incomeRange ?? null,
        genderInclusions: args.genderInclusions ?? null,
        genderExclusions: args.genderExclusions ?? null,
        regionInclusions: args.regionInclusions != undefined ? args.regionInclusions.map((r) => stringToFixedLengthArray(r, 2)) : null,
        regionExclusions: args.regionExclusions != undefined ? args.regionExclusions.map((r) => stringToFixedLengthArray(r, 2)) : null,
        eductionInclusions: args.eductionInclusions as any ?? null,
        eductionExclusions: args.eductionExclusions as any  ?? null,
        deviceTypeInclusions: args.deviceTypeInclusions ?? null,
        deviceTypeExclusions: args.deviceTypeExclusions ?? null,
        categoryInclusions: args.categoryInclusions ?? null,
        categoryExclusions: args.categoryExclusions ?? null,
        keywordInclusions: args.keywordInclusions ?? null,
        keywordExclusions: args.keywordExclusions ?? null,
        imageCreatives: args.imageCreatives ?? null,
        videoCreatives: args.videoCreatives ?? null,
        copyCreatives: args.copyCreatives ?? null,
      };
    
      console.log(
        ixArgs
      );
      try {
        // Send the transaction
        const tx = await this.baseProgram.methods
          .initOrUpdateCampaign(ixArgs)
          .accounts(accounts)
          .preInstructions([ed25519VerifyIxn])
          .rpc({
            // skipPreflight: true
          });
    
        console.log("Transaction signature", tx);
        return tx;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }

    async fetchCampaignById(
        campaignId: number,
        commitment: anchor.web3.Commitment = "processed"
    ): Promise<anchor.IdlAccounts<Dax>["campaign"] | null> {
        const campaignPubkey = this.deriveCampaignPubkey(this.publicKey, campaignId);
        return this.fetchCampaignByPubkey(campaignPubkey)
    }

    async fetchCampaignByPubkey(
        campaignPubkey: PublicKey,
        commitment: anchor.web3.Commitment = "processed"
    ): Promise<anchor.IdlAccounts<Dax>["campaign"] | null> {
        const campaignAccount = await this.rollupProgram.account.campaign.fetch(campaignPubkey);
        return campaignAccount 
    }

    async fetchAllCampaigns(
        commitment: anchor.web3.Commitment = "processed"
    ): Promise<any[]> {
        const campaignAccounts = await this.rollupProgram.account.campaign.all(
          [{ memcmp: { offset: 8, bytes: this.publicKey.toBase58() } } as anchor.web3.MemcmpFilter]
        );
        return campaignAccounts 
    }

    async getNextCampaignIdx(): Promise<number> {
      const campaigns = await this.fetchAllCampaigns();
      var max = 0;
      campaigns.forEach((c)=>{ 
          if (c.account.campaignId > max) {
              max = Number(c.account.campaignId)
          }
      })
      return max + 1
  }

  async fetchAllDelegates(
    commitment: anchor.web3.Commitment = "processed"
  ): Promise<any[]> {
      const delegateAccounts = await this.rollupProgram.account.bidderDelegate.all(
        [{ memcmp: { offset: 8, bytes: this.publicKey.toBase58() } } as anchor.web3.MemcmpFilter]
      );
      return delegateAccounts 
  }

  async fetchBidderDelegate(
    delegatePukey: PublicKey,
    commitment: anchor.web3.Commitment = "processed"
  ): Promise<any | null> {
    const bidderDelegatePubkey = this.deriveBidderDelegatePubkey(this.publicKey, delegatePukey);
    return await this.rollupProgram.account.bidderDelegate.fetchNullable(bidderDelegatePubkey, commitment);
  }

    async initOrUpdateCreative(
      creativeId: number,
      args: InitOrUpdateCreativeArgs,
      nonce?: number
    ): Promise<string> {

      if (!nonce) {
        try {
          const creativeState = await this.fetchCreativeById(creativeId);
          nonce = creativeState!.nonce + 1;
        } catch (e) {
          nonce = 1
        }
      }
      const [nonceBytes, nonceSignature] = this.generateUpdateNonceVerification(nonce);
      const ed25519VerifyIxn = this.verifyEd25519SignatureIxn(
        nonceBytes,
        nonceSignature,
        this.publicKey
      );

      const creativePubkey = this.deriveCreativePubkey(
        this.rollupProgram.provider.publicKey!,
        creativeId
      );
  
      // Prepare the accounts
      const accounts = {
        payer: this.rollupProgram.provider.publicKey!,
        advertiser: this.rollupProgram.provider.publicKey!,
        creative: creativePubkey,
        systemProgram: anchor.web3.SystemProgram.programId,
        instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
      };
  
      // Prepare the instruction arguments
      const ixArgs = {
        id: creativeId,
        status: args.status ?? null,
        asset: args.asset ?? null,
        ctaUrl: args.ctaUrl ?? null,
        languages: args.languages ?? null,
      };

      console.log(ixArgs)
  
      try {
        // Send the transaction
        const tx = await this.rollupProgram.methods
          .initOrUpdateCreative(ixArgs as any)
          .accounts(accounts)
          .rpc();
  
        console.log("Transaction signature", tx);
        return tx;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  
    async fetchCreativeById(
      creativeId: number,
      commitment: anchor.web3.Commitment = "processed"
    ): Promise<anchor.IdlAccounts<Dax>["creative"] | null> {
      const creativePubkey = this.deriveCreativePubkey(this.publicKey, creativeId);
      return this.fetchCreativeByPubkey(creativePubkey, commitment);
    }
  
    async fetchCreativeByPubkey(
      creativePubkey: PublicKey,
      commitment: anchor.web3.Commitment = "processed"
    ): Promise<anchor.IdlAccounts<Dax>["creative"] | null> {
      try {
        const creativeAccount = await this.rollupProgram.account.creative.fetch(creativePubkey, commitment);
        return creativeAccount;
      } catch (error) {
        console.error("Error fetching creative:", error);
        return null;
      }
    }
  
    async fetchAllCreatives(
      commitment: anchor.web3.Commitment = "processed"
    ): Promise<any[]> {
      try {
        const creativeAccounts = await this.rollupProgram.account.creative.all(
          [{ memcmp: { offset: 8, bytes: this.publicKey.toBase58() } } as anchor.web3.MemcmpFilter],
        );
        return creativeAccounts
      } catch (error) {
        console.error("Error fetching all creatives:", error);
        return [];
      }
    }
  

    async getNextCreativeIdx(): Promise<number> {
      const creatives = await this.fetchAllCreatives();
      var max = 0;
      creatives.forEach((c)=>{ 
          if (c.account.id > max) {
              max = Number(c.account.id)
          }
      })
      return max + 1
    }

        
    
}

