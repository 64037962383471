import React, { useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Outlet, Navigate } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { WalletProvider, } from '@solana/wallet-adapter-react';
import { WalletModalProvider} from '@solana/wallet-adapter-react-ui';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {PrivyProvider} from '@privy-io/react-auth';
import { clusterApiUrl, PublicKey } from '@solana/web3.js';

import { ThemeProvider } from './context/ThemeContext';
import { ModeProvider } from './context/ModeContext';
import { DaxProgramProvider } from './context/DaxProgramContext';
import { BalanceProvider } from './context/BalancesContext';
import { ToastProvider } from './context/ToastContext';
import { RollupNodeProvider } from './context/RollupNodeContext';
import { RequestorProvider }  from './context/RequestorContext';
import { PublisherProvider }  from './context/PublisherContext';

import ToastContainer from './components/ToastContainer';
import About from './components/About';

import DashboardRoutes from './components/dashboard/DashboardRoutes';
import InfoRoutes from './components/info/InfoRoutes';
import ExplorerRoutes from './components/explorer/ExplorerRoutes';

import './scrollbar-styles.css';
import { AdvertiserProvider } from './context/AdvertiserContext';
import LandingPage from './components/landingPage/LandingPage';
import LogoDarkSquare from 'LogoDarkSquare.png';

// Create a client
const queryClient = new QueryClient();

const network = WalletAdapterNetwork.Devnet;
const programID = new PublicKey("Dm1y7dP2V6ffJ67T65A8zha5vqNXdBCUUq7KkQLS4VVc");
const endpoint = process.env.REACT_APP_BASE_LAYER_ENDPOINT as string;

// Main layout component
const Layout = () => (
  <div className="">
    <Outlet />
  </div>
);






const AppContent = () => {

  return (

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="info/*" element={<InfoRoutes />} />
          <Route path="explorer/*" element={<ExplorerRoutes />} />
          <Route path="dashboard/*" element={<DashboardRoutes />} />
        </Route>
      </Routes>
  );
};

const App = () => {

  // const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  console.log(endpoint);
  
  const wallets = useMemo(
    () => [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter({ network }),
        new TorusWalletAdapter(),
    ],
    [network]
  );

  return (
    <ThemeProvider>
      <ModeProvider>
        <QueryClientProvider client={queryClient}>
          <PrivyProvider
            appId="cm0tyy4c501b9rafffyfyj7hm"
            config={{
              // Display email and wallet as login methods
              loginMethods: ['email', 'wallet'],
              // Customize Privy's appearance in your app
              appearance: {
                theme: 'light',
                accentColor: '#676FFF',
                logo: LogoDarkSquare,
              },
              // Create embedded wallets for users who don't have a wallet
              embeddedWallets: {
                createOnLogin: 'users-without-wallets',
              },
            }}
          >
            <DaxProgramProvider programID={programID} endpoint={endpoint}>
              <PublisherProvider>
                <AdvertiserProvider>
                  <RequestorProvider>
                    <WalletModalProvider>
                      <ToastProvider>
                        <RollupNodeProvider>
                          <BalanceProvider>
                            <Router>
                              <AppContent />
                              <ToastContainer />
                            </Router>
                          </BalanceProvider>
                        </RollupNodeProvider>
                      </ToastProvider>
                    </WalletModalProvider>
                  </RequestorProvider>
                </AdvertiserProvider>
              </PublisherProvider>
            </DaxProgramProvider>
          </PrivyProvider>
        </QueryClientProvider>
      </ModeProvider>
    </ThemeProvider>
  );
};

export default App;