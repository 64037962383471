import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { Program, ProgramAccount } from '@coral-xyz/anchor';
import { useDaxProgram } from './DaxProgramContext'; 
import { Dax } from '../dax';
import { useSolanaWallets} from '@privy-io/react-auth';


interface BalanceState {
  walletBalance: number | null;
  creditsBalance: number | null;
  balancePubkey: PublicKey | null;
  isLoading: boolean;
}

interface BalanceContextType extends BalanceState {
  refreshBalances: () => Promise<void>;
}

const BalanceContext = createContext<BalanceContextType | undefined>(undefined);

export const useBalance = () => {
  const context = useContext(BalanceContext);
  if (context === undefined) {
    throw new Error('useBalance must be used within a BalanceProvider');
  }
  return context;
};

interface BalanceProviderProps {
    children: React.ReactNode;
  }

export const BalanceProvider: React.FC<BalanceProviderProps> = ({ children }) => {
    const [state, setState] = useState<BalanceState>({
    walletBalance: null,
    creditsBalance: null,
    balancePubkey: null,
    isLoading: false,
  });

  const {wallets} = useSolanaWallets();
  const wallet = wallets[0]; 

  // // const wallet = useAnchorWallet();
  // const [wallet, setWallet] = useState<Wallet|undefined>(undefined);
  // const {authenticated, user, login, logout} = usePrivy();
  // const {createWallet} = usewallets();
  // useEffect(() => {
  //     if (!authenticated) {
  //         setWallet(undefined)
  //         return
  //     }
  //     const wallet = user?.linkedAccounts.find(
  //         (account): account is WalletWithMetadata =>
  //         account.type === 'wallet' &&
  //         account.walletClientType === 'privy' &&
  //         account.chainType === 'solana',
  //     );
  //     if (!wallet) {
  //         createWallet().then((w)=>{
  //             setWallet(wallet)
  //         });
  //     } else {
  //         setWallet(wallet)
  //     }
  // })

  const { program } = useDaxProgram();

  const deriveBalancePubkey = useCallback(async (walletPubkey: PublicKey): Promise<PublicKey> => {
    return PublicKey.findProgramAddressSync(
      [Buffer.from('balance'), walletPubkey.toBuffer()],
      program!.programId
    )[0];
  }, [program]);

  const fetchBalances = useCallback(async () => {

    if (!wallet || !program) {
        return;
    }
    setState(prev => ({ ...prev, isLoading: true }));

    try {
      console.log('Getting balances...')
      const walletBalance = await program.provider.connection.getBalance(new PublicKey(wallet.address), "processed");
      if (walletBalance < 0.1) {
        // try {
        console.log('Airdropping')
          await program.provider.connection.requestAirdrop(new PublicKey(wallet.address), LAMPORTS_PER_SOL)
        // } catch (e) {
        //   console.error("Airdrop failed")
        // }
      }
      const balancePubkey = await deriveBalancePubkey(new PublicKey(wallet.address));

      let creditsBalance = null;

      try {
        const balanceAccount = await program.account.balance.fetch(balancePubkey, "processed");
        creditsBalance = balanceAccount.credits.toNumber();
      } catch (error) {
        console.log('Balance account not found');
      }


      setState({
        walletBalance,
        creditsBalance,
        balancePubkey,
        isLoading: false,
      });
    } catch (error) {
      console.error('Error fetching balances:', error);
      setState(prev => ({ ...prev, isLoading: false }));
    }
  }, [wallet, program, deriveBalancePubkey]);

  useEffect(() => {
    if (wallet && program) {
      fetchBalances();
    } 
  }, [wallet, program, fetchBalances]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (wallet && program) {
        fetchBalances();
      }
    }, 15000); // Fetch every 15 seconds
  
    return () => clearInterval(intervalId);
  }, [wallet, program, fetchBalances]);

  const refreshBalances = useCallback(() => {
    return fetchBalances();
  }, [fetchBalances]);

  return (
    <BalanceContext.Provider
      value={{
        ...state,
        refreshBalances,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};