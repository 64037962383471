import { BoltIcon, CurrencyDollarIcon, LockClosedIcon, MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

const points = [
    {
        id: 0,
        heading: "Millisecond latency real-time auctions",
        icon: <BoltIcon className="h-8"/>
    },
    {
        id: 1,
        heading: "Transparent and verifiable bidding",
        icon: <MagnifyingGlassCircleIcon className="h-8"/>
    },
    {
        id: 2,
        heading: "Privacy-preserving user targeting",
        icon: <LockClosedIcon className="h-8"/>
    },
    {
        id: 3,
        heading: "Instant settlement on Solana",
        icon: <CurrencyDollarIcon className="h-8"/>
    },
]

const DecentralizedAdExchange: React.FC = () => {
  return (
    <div className="flex flex-col lg:flex-row w-full md:gap-8">
      {/* First column (1/3 width on desktop) */}
      <div className="w-full lg:w-1/3 flex flex-col items-center justify-center h-full flex">
        {/* Heading and subtext */}
        <div className="flex-1 flex flex-col justify-center mb-4 md:mb-4">
            <h2 className="text-5xl md:text-6xl font-medium mb-4">
                A purpose optimized SVM rollup
            </h2>

          {/* <div className="relative rounded-lg overflow-hidden border border-white/5 p-4 bg-slate-200/10 h-full flex flex-col justify-center">
            <h2 className="text-3xl sm:text-4xl font-bold mb-4 z-10">
                AdX is a use-case optimized SVM rollup
            </h2>
          </div> */}
        </div>
        
        {/* 2x2 grid of square divs */}
        <div className="flex-1 grid grid-cols-2 gap-3 md:gap-4">
          {points.map((p) => (
            <div key={p.id} className="relative rounded-lg overflow-hidden border border-white/5 p-4 bg-slate-200/10 items-center">
              <div className="mb-2">
                {p.icon}
              </div>
              <p className="font-medium text-sm sm:text-base z-10">
                {p.heading}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Second column (2/3 width on desktop) */}
      <div className="w-full lg:w-2/3 flex items-center justify-center mt-4 md:mt-0">
        <div className="w-full h-full rounded-lg border border-white/5 p-4 bg-slate-200/10 flex items-center justify-center">
          <img 
            src="svm-model.svg" 
            alt="SVM Model" 
            className="max-w-full object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default DecentralizedAdExchange;