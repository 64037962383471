import React, { useState, useEffect } from 'react';
import { Objective, CampaignStatus } from '../../../bindings/advertiser';

interface ExistingCampaignInfoStepProps {
  onSubmit: (data: any) => void;
  initialData: any;
}

const ExistingCampaignInfoStep: React.FC<ExistingCampaignInfoStepProps> = ({ onSubmit, initialData }) => {
  console.log(initialData);
  const [campaignId, setCampaignId] = useState(initialData.campaign_id);
  const [name, setName] = useState('');
  const [status, setStatus] = useState<CampaignStatus>({ uninitialized: {} });
  const [objective, setObjective] = useState<Objective>({ undefined: {} });
  const [hasChanges, setHasChanges] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setCampaignId(initialData.campaign_id);
    setName(initialData.name);
    if (initialData.status == "Uninitialized") {
      setStatus({ uninitialized: {} });
    } else if (initialData.status == "Active") {
      setStatus({ active: {} });
    } else if (initialData.status == "Paused") {
      setStatus({ paused: {} });
    } else if (initialData.status == "Completed") {
      setStatus({ completed: {} });
    } else if (initialData.status == "Cancelled") {
      setStatus({ cancelled: {} });
    }
    if (initialData.objective == "Undefined") {
      setObjective({ undefined: {} });
    } else if (initialData.objective == "Impressions") {
      setObjective({ impressions: {} });
    } else if (initialData.objective == "Conversions") {
      setObjective({ conversions: {} });
    }
  }, [initialData]);

  const handleChange = (setter: React.Dispatch<React.SetStateAction<any>>) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setter(e.target.value);
    setHasChanges(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (hasChanges) {
        onSubmit({
          campaignId,
          name,
          status,
          objective,
        });
      } else {
        // If no changes, just move to the next step without submitting
        onSubmit({});
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <h2 className="text-lg font-semibold">Campaign Information</h2>
      
      <div>
        <label htmlFor="campaignId" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Campaign ID
        </label>
        <input
          type="text"
          id="campaignId"
          value={campaignId}
          disabled
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm bg-gray-100 dark:bg-gray-600"
        />
      </div>

      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={handleChange(setName)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
        />
      </div>

      <div>
        <label htmlFor="status" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Status
        </label>
        <select
          id="status"
          value={Object.keys(status)[0]}
          onChange={(e) => {
            setStatus({ [e.target.value]: {} } as CampaignStatus);
            setHasChanges(true);
          }}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
        >
          <option value="uninitialized">Uninitialized</option>
          <option value="active">Active</option>
          <option value="paused">Paused</option>
          <option value="completed">Completed</option>
          <option value="cancelled">Cancelled</option>
        </select>
      </div>

      <div>
        <label htmlFor="objective" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Objective
        </label>
        <select
          id="objective"
          value={Object.keys(objective)[0]}
          onChange={(e) => {
            setObjective({ [e.target.value]: {} } as Objective);
            setHasChanges(true);
          }}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
        >
          <option value="undefined">Undefined</option>
          <option value="impressions">Impressions</option>
          <option value="conversions">Conversions</option>
        </select>
      </div>
      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600 disabled:opacity-50"
      >
        {isSubmitting ? (
          <>
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Submitting...
          </>
        ) : (
          hasChanges ? 'Save & Continue' : 'Next'
        )}
      </button>
    </form>
  );
};

export default ExistingCampaignInfoStep;