import { CpuChipIcon, FingerPrintIcon, LockOpenIcon, ScaleIcon, UserCircleIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import React, { useRef, useEffect, useState } from 'react';
import { SiSolana } from 'react-icons/si';

const points = [
  {
      id: 0,
      heading: "Nikhil",
      location: "India",
      body: <div className="flex flex-col gap-y-2">
        <ul className=" list-disc list-outside ml-5">
          <li>10+ years of software development experience</li>
          <li>3+ years in the Solana ecosystem across DeFi, NFTs and more</li>
          <li>Built and led software teams at both big tech and web3 startups</li>
        </ul>
      </div>,
  },
  {
      id: 1,
      heading: "Cian",
      location: "Ireland",
      body: <div className="flex flex-col gap-y-2 list-disc list-outside">
        <ul className=" list-disc list-outside ml-5">
          <li>Qualified actuary - expertise in risk, markets and statistics</li>
          <li>3+ years building in Solana risk and matching systems </li>
          <li>Built and led multi-faceted teams in traditional finance and web3 startups</li>
        </ul>
      </div>,
  },
]



const Team: React.FC = () => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const scrollToCard = (index: number) => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const cardWidth = carousel.offsetWidth * 0.8; // 80% of the container width
    carousel.scrollTo({
      left: index * cardWidth,
      behavior: 'smooth'
    });
    setActiveIndex(index);
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const handleScroll = () => {
      const scrollPosition = carousel.scrollLeft;
      const cardWidth = carousel.offsetWidth * 0.8; // 80% of the container width
      const newIndex = Math.round(scrollPosition / cardWidth);
      setActiveIndex(newIndex);
    };

    carousel.addEventListener('scroll', handleScroll);
    return () => carousel.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="flex-row w-full gap-8">
      <div className="w-full md:text-center">
        <h2 className="text-5xl md:text-6xl font-medium mb-12">
          Meet the team
        </h2>
      </div>

      <div className="w-full flex items-center justify-center mt-4 md:mt-8 relative">
        <div 
          ref={carouselRef}
          className="flex-1 flex md:grid md:grid-cols-2 gap-4 overflow-x-auto snap-x snap-mandatory scrollbar-hide"
          style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          {points.map((p, index) => (
            <div 
              key={p.id} 
              className={`relative rounded-lg overflow-hidden items-center p-5 backdrop-blur-md bg-white/10 flex-shrink-0 w-4/5 md:w-full snap-center cursor-pointer`}
              style={{
                scrollSnapAlign: 'center',
              }}
              onClick={() => scrollToCard(index)}
            >
              <div className="mb-4 flex">
                <UserCircleIcon className="h-20 lg:h-24 mr-5" />
                <h3 className="text-2xl md:text-3xl font-medium py-5 h-full">
                  {p.heading}
                </h3>
              </div>
              
              <p className="font-medium text-sm lg:text-lg z-10 text-gray-200">
                {p.body}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-4 md:hidden -mx-4 sm:-mx-6 lg:-mx-8">
        {points.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 rounded-full mx-1 ${
              index === activeIndex ? 'bg-white' : 'bg-gray-500'
            }`}
            onClick={() => scrollToCard(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Team;