import React, { useEffect, useState } from 'react';
import { Budget, Bidding } from '../../../bindings/advertiser';
import { BN } from "@coral-xyz/anchor";

interface BudgetStepProps {
  onSubmit: (data: any) => void;
  initialData?: any;
}

const BudgetStep: React.FC<BudgetStepProps> = ({ onSubmit, initialData }) => {
  const [budgetType, setBudgetType] = useState<'daily' | 'total'>('daily');
  const [budgetAmount, setBudgetAmount] = useState('');
  const [biddingAmount, setBiddingAmount] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [initialBudgetType, setInitialBudgetType] = useState<'daily' | 'total'>('daily');
  const [initialBudgetAmount, setInitialBudgetAmount] = useState('');
  const [initialBiddingAmount, setInitialBiddingAmount] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (initialData) {
      let initialType: 'daily' | 'total' = 'daily';
      let initialAmount = '';

      if (initialData.budget) {
        if (initialData.budget.Daily) {
          initialType = 'daily';
          initialAmount = initialData.budget.Daily.max_spend_per_day.toString();
        } else if (initialData.budget.Total) {
          initialType = 'total';
          initialAmount = initialData.budget.Total.campaign_total.toString();
        }
      }

      setBudgetType(initialType);
      setBudgetAmount(initialAmount);
      setInitialBudgetType(initialType);
      setInitialBudgetAmount(initialAmount);

      if (initialData.bidding && initialData.bidding.CostPerImpression) {
        const initialBidding = initialData.bidding.CostPerImpression.amount.toString();
        setBiddingAmount(initialBidding);
        setInitialBiddingAmount(initialBidding);
      }
    }
  }, [initialData]);

  useEffect(() => {
    const hasChanges = 
      budgetType !== initialBudgetType ||
      budgetAmount !== initialBudgetAmount ||
      biddingAmount !== initialBiddingAmount;
    setHasChanges(hasChanges);
  }, [budgetType, budgetAmount, biddingAmount, initialBudgetType, initialBudgetAmount, initialBiddingAmount]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (!hasChanges) {
        onSubmit({}); // No changes, just move to next step
        return;
      }
      const budget: Budget = budgetType === 'daily'
        ? { daily: { maxSpendPerDay: new BN(budgetAmount) } }
        : { total: { campaignTotal: new BN(budgetAmount) } };
      const bidding: Bidding = { costPerImpression: { amount: new BN(biddingAmount) } };
      onSubmit({ budget, bidding });
    } finally {
      setIsSubmitting(false);
    }
    
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Budget Type
        </label>
        <div className="mt-1 space-x-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              value="daily"
              checked={budgetType === 'daily'}
              onChange={() => setBudgetType('daily')}
              className="form-radio text-indigo-600 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
            />
            <span className="ml-2 text-gray-700 dark:text-gray-300">Daily</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              value="total"
              checked={budgetType === 'total'}
              onChange={() => setBudgetType('total')}
              className="form-radio text-indigo-600 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
            />
            <span className="ml-2 text-gray-700 dark:text-gray-300">Total</span>
          </label>
        </div>
      </div>
      <div>
        <label htmlFor="budgetAmount" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Budget Amount
        </label>
        <input
          type="number"
          id="budgetAmount"
          value={budgetAmount}
          onChange={(e) => setBudgetAmount(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
          required
          min="1"
        />
      </div>
      <div>
        <label htmlFor="biddingAmount" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Bidding Amount (Cost per Impression)
        </label>
        <input
          type="number"
          id="biddingAmount"
          value={biddingAmount}
          onChange={(e) => setBiddingAmount(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
          required
          min="1"
        />
      </div>
      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600 disabled:opacity-50"
      >
        {isSubmitting ? (
          <>
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Submitting...
          </>
        ) : (
          hasChanges ? 'Save & Continue' : 'Next'
        )}
      </button>
    </form>
  );
};

export default BudgetStep;