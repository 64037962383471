import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { NavLink, useLocation, Link} from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { usePublisher } from '../../../context/PublisherContext';
import Heading from '../Heading';
import PropertyTable from './PropertyTable';
import { useSolanaWallets } from '@privy-io/react-auth';
import { getProperties } from '../../../supabase';

export default function Properties() {

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const { publisher } = usePublisher();
  
  const {wallets} = useSolanaWallets();
  const wallet = wallets[0]; 

  const [properties, setProperties] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchProperties = useCallback(async () => {
    if (!wallet || !wallet.address) {
        return;
        setProperties([])
        setIsLoading(false)
    }
    try {
      console.log('Getting properties...')
      // const ps = await publisher.fetchAllProperties();
      const ps = await getProperties({publisher: wallet.address});
      console.log(ps);
      setProperties(ps);
      setIsLoading(false)
      console.log(ps);
    } catch (error) {
      console.error('Error fetching properties:', error);
      setProperties([])
      setIsLoading(false)
    }
  }, [wallet]);

  useEffect(() => {
    if (wallet && wallet.address) {
      fetchProperties();
    } 
  }, [wallet]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (publisher) {
  //       fetchProperties();
  //     }
  //   }, 15000); // Fetch every 15 seconds
  
  //   return () => clearInterval(intervalId);
  // }, [publisher, fetchProperties]);

  
  return (
    <div>
      <Heading pageHeadingName='Properties'>
          <Link to="/dashboard/properties/new" className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">New</Link>
      </Heading>
      <PropertyTable properties={properties} isLoading={isLoading} />
    </div>
  )
}
