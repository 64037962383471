import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Switch } from '@headlessui/react';

interface RangeSelectorProps {
  label: string;
  min: number;
  max: number;
  step?: number;
  initialRange: [number, number] | null;
  onChange: (range: [number, number] | null) => void;
  unit?: string;
  scale?: number;
  formatLabel?: (value: number) => string;
}

const RangeSelector: React.FC<RangeSelectorProps> = ({
  label,
  min,
  max,
  step = 1,
  initialRange,
  onChange,
  unit = '',
  scale = 1,
  formatLabel = (value) => value.toString(),
}) => {
  const [isEnabled, setIsEnabled] = useState(initialRange !== null);
  const [range, setRange] = useState<[number, number]>(initialRange || [min, max]);

  useEffect(() => {
    if (initialRange) {
      setRange(initialRange);
      setIsEnabled(true);
    } else {
      setRange([min, max]);
      setIsEnabled(false);
    }
  }, [initialRange, min, max]);

  const handleToggle = (checked: boolean) => {
    setIsEnabled(checked);
    if (checked) {
      onChange(range);
    } else {
      onChange(null);
    }
  };

  const handleRangeChange = (newRange: number | number[]) => {
    if (Array.isArray(newRange) && newRange.length === 2) {
      const typedRange: [number, number] = [newRange[0], newRange[1]];
      setRange(typedRange);
      if (isEnabled) {
        onChange(typedRange);
      }
    }
  };

  const formatValue = (value: number) => {
    const scaledValue = value / scale;
    return `${formatLabel(scaledValue)}${unit}`;
  };

  const previewText = isEnabled
    ? `${formatValue(range[0])} - ${formatValue(range[1])}`
    : `0 → ∞`;

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200">
            {previewText}
          </span>
        </div>
        <Switch
          checked={isEnabled}
          onChange={handleToggle}
          className={`${
            isEnabled ? 'bg-indigo-600' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
        >
          <span
            className={`${
              isEnabled ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
      <div className={isEnabled ? '' : 'opacity-50 pointer-events-none'}>
        <Slider
          range
          min={min}
          max={max}
          step={step}
          value={range}
          onChange={handleRangeChange}
          className={`rc-slider-custom ${isEnabled ? '' : 'rc-slider-disabled'}`}
          railStyle={{ backgroundColor: isEnabled ? undefined : 'rgb(229, 231, 235)' }}
          trackStyle={[{ backgroundColor: isEnabled ? undefined : 'rgb(229, 231, 235)' }]}
          handleStyle={[
            { borderColor: isEnabled ? undefined : 'rgb(229, 231, 235)' },
            { borderColor: isEnabled ? undefined : 'rgb(229, 231, 235)' }
          ]}
        />
      </div>
    </div>
  );
};

export default RangeSelector;