import React from 'react';
import { motion } from 'framer-motion';

interface MacOSWindowProps {
  heading: string;
  children: React.ReactNode;
}

const MacOSWindow: React.FC<MacOSWindowProps> = ({ heading, children }) => {
  return (
    <motion.div 
      className="bg-gray-800 rounded-lg overflow-hidden shadow-2xl opacity-90"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      {/* macOS-style window header */}
      <div className="bg-gray-700 px-2 sm:px-4 py-1 sm:py-2 flex items-center">
        <div className="flex space-x-1 sm:space-x-2">
          <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-red-500"></div>
          <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-yellow-500"></div>
          <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-green-500"></div>
        </div>
        <div className="text-white text-xs sm:text-sm ml-2 sm:ml-4 text-center w-full mr-6 sm:mr-10 truncate">{heading}</div>
      </div>

      {/* Application content */}
      <div className="">
        {children}
      </div>
    </motion.div>
  );
};

export default MacOSWindow;