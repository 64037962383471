import React from 'react';
import { useNavigate } from 'react-router-dom';
import PubkeyDisplay from '../../common/PubkeyDisplay';
import { Campaign } from '../../../supabase';

interface CampaignTableProps {
  campaigns: Campaign[];
  isLoading: boolean;
}

const CampaignTable: React.FC<CampaignTableProps> = ({ campaigns, isLoading }) => {
  const navigate = useNavigate();

  const handleManageCampaign = (campaignId: string) => {
    navigate(`/dashboard/campaigns/manage/${campaignId}`);
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tbody>
          {[...Array(3)].map((_, idx) => (
            <tr key={`skeleton-${idx}`}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4 animate-pulse"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2 animate-pulse"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right">
                <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-20 ml-auto animate-pulse"></div>
              </td>
            </tr>
          ))}
        </tbody>
      );
    }

    if (campaigns.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={4} className="px-6 py-4 text-center text-gray-500 dark:text-gray-400">
              No campaigns found. Add a campaign to get started.
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody>
        {campaigns.map((campaign, idx) => (
          <tr key={campaign.campaign_id}>
            <td
              className={
                (idx === 0 ? '' : 'border-t border-transparent') +
                ' relative py-4 pl-4 pr-3 text-sm sm:pl-6'
              }
            >
              <div className="font-medium text-gray-900 dark:text-white">
                {campaign.campaign_id}
              </div>
              {idx !== 0 ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-200 dark:bg-gray-600" /> : null}
            </td>
            <td
              className={
                (idx === 0 ? '' : 'border-t border-gray-200 dark:border-gray-600') +
                ' hidden px-3 py-3.5 text-sm text-gray-500 dark:text-gray-400 lg:table-cell'
              }
            >
              {campaign.name}
            </td>
            <td
              className={
                (idx === 0 ? '' : 'border-t border-gray-200 dark:border-gray-600') +
                ' hidden px-3 py-3.5 text-sm text-gray-500 dark:text-gray-400 lg:table-cell'
              }
            >
              {campaign.status?.toUpperCase()}
            </td>
            <td
              className={
                (idx === 0 ? '' : 'border-t border-gray-200 dark:border-gray-600') +
                ' hidden px-3 py-3.5 text-sm text-gray-500 dark:text-gray-400 lg:table-cell'
              }
            >
              {JSON.stringify(campaign.budget)}
            </td>
            <td
              className={
                (idx === 0 ? '' : 'border-t border-gray-200 dark:border-gray-600') +
                ' hidden px-3 py-3.5 text-sm text-gray-500 dark:text-gray-400 lg:table-cell'
              }
            >
              <PubkeyDisplay pubkey={campaign.id} />
            </td>
            <td
              className={
                (idx === 0 ? '' : 'border-t border-transparent') +
                ' relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'
              }
            >
              <button
                type="button"
                onClick={() => handleManageCampaign(campaign.id)}
                className="inline-flex items-center rounded-md bg-white dark:bg-gray-700 px-2.5 py-1.5 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-700 transition-colors duration-200"
              >
                Manage
                <span className="sr-only">, {campaign.campaign_id}</span>
              </button>
              {idx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-200 dark:bg-gray-600" /> : null}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className="transition-colors duration-200">
      <div className="-mx-4 mt-10 ring-1 ring-gray-300 dark:ring-gray-700 sm:mx-0 sm:rounded-lg bg-white dark:bg-gray-800">
        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
          <thead className="bg-gray-200 dark:bg-gray-900 rounded-t-lg">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                ID
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white lg:table-cell"
              >
                Name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white lg:table-cell"
              >
                Status
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white lg:table-cell"
              >
                Budget
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white lg:table-cell"
              >
                Pubkey
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          {renderTableBody()}
        </table>
      </div>
    </div>
  );
}

export default CampaignTable;