import React, { useCallback, useEffect, useState } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import TimePeriodSelector from '../../common/TimePeriodSelector';
import { getPlacementsWithAuction, PlacementWithAuction } from '../../../supabase';
import { useSolanaWallets } from '@privy-io/react-auth';

// Mock data - replace with actual data fetching logic
const mockData = {
  earnings: [
    { date: '2023-01-01', amount: 1000 },
    { date: '2023-01-02', amount: 1200 },
    { date: '2023-01-03', amount: 800 },
    { date: '2023-01-04', amount: 1500 },
    { date: '2023-01-05', amount: 2000 },
  ],
  earningsByProperty: [
    { date: '2023-01-01', property1: 500, property2: 300, property3: 200 },
    { date: '2023-01-02', property1: 600, property2: 400, property3: 200 },
    { date: '2023-01-03', property1: 400, property2: 200, property3: 200 },
    { date: '2023-01-04', property1: 700, property2: 500, property3: 300 },
    { date: '2023-01-05', property1: 1000, property2: 600, property3: 400 },
  ],
  placementsByProperty: [
    { name: 'Property 1', placements: 50 },
    { name: 'Property 2', placements: 30 },
    { name: 'Property 3', placements: 20 },
  ],
  tableData: [
    { id: 1, property: 'Property 1', impressions: 10000, clicks: 500, revenue: 1000 },
    { id: 2, property: 'Property 2', impressions: 8000, clicks: 400, revenue: 800 },
    { id: 3, property: 'Property 3', impressions: 6000, clicks: 300, revenue: 600 },
    { id: 4, property: 'Property 4', impressions: 4000, clicks: 200, revenue: 400 },
    { id: 5, property: 'Property 5', impressions: 2000, clicks: 100, revenue: 200 },
  ],
};

const PublisherDashboard = () => {
  const [tableFilter, setTableFilter] = useState('');

  const { wallets } = useSolanaWallets();
  const wallet = wallets[0];

  const [records, setRecords] = useState<PlacementWithAuction[]>([]);
  const [aggregatedData, setAggregatedData] = useState<any>({});
  const [revenueByDay, setRevenueByDay] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string|null>(null);

  const fetchRecords = useCallback(async () => {
    if (!wallet || !wallet.address) return;
    
    try {
      setLoading(true);
      // const fetchedRecords = await rollupNode.fetchRecentCommittedPlacementRecord();
      const fetchedRecords = await getPlacementsWithAuction({ publisher: wallet.address });
      
      // aggregate data by property
      const aggregatedData = fetchedRecords.reduce((acc: any, curr: PlacementWithAuction) => {
        const property = curr.property_id;
        if (!acc[property]) {
          acc[property] = { revenue: 0, count: 0  };
        }
        acc[property].revenue += curr.auction.bid_value;
        acc[property].count += 1;
        return acc;
      }, {} as Record<string, { revenue: number }>);

      // Aggregate revenue by day
      const revenueByDay = fetchedRecords.reduce((acc: Record<string, number>, curr: PlacementWithAuction) => {
        const date = new Date(curr.created_at).toISOString().split('T')[0]; // Get YYYY-MM-DD format
        if (!acc[date]) {
          acc[date] = 0;
        }
        acc[date] += curr.auction.bid_value;
        return acc;
      }, {});
      // Sort revenue by day
      const sortedRevenueByDay = Object.entries(revenueByDay)
        .sort(([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime())
        .reduce((acc, [date, revenue]) => {
          acc[date] = revenue;
          return acc;
        }, {} as Record<string, number>);

      setRevenueByDay(sortedRevenueByDay);

      setRecords(fetchedRecords);
      setAggregatedData(aggregatedData);
    } catch (err) {
      setError('Failed to fetch records');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [wallet]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);


  const filteredTableData = mockData.tableData.filter(item =>
    item.property.toLowerCase().includes(tableFilter.toLowerCase())
  );

  return (
    <div className="min-h-screen">
      <div className="">
        
        {/* High-level Statistics */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          {[
            { title: 'Total Revenue', value: `$${records.reduce((sum, record) => sum + record.auction.bid_value, 0).toFixed(2)}` },
            { title: 'Total Placements', value: records.length.toString() },
            { title: 'Average Bid', value: `$${records.length > 0 ? (records.reduce((sum, record) => sum + record.auction.bid_value, 0) / records.length).toFixed(2): 0}` },
            { title: 'Unique Properties', value: Object.keys(aggregatedData).length.toString() },
          ].map((stat, index) => (
            <div key={index} className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">{stat.title}</h3>
              <p className="text-2xl font-bold text-gray-900 dark:text-gray-100">{stat.value}</p>
            </div>
          ))}
        </div>

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Total Earnings Chart */}
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-gray-100">Total Earnings</h2>
            {loading ? (
              <div className="flex justify-center items-center h-[300px]">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={Object.entries(revenueByDay).map(([date, revenue]) => ({ date, revenue }))}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>

          {/* Earnings by Property Chart */}
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-gray-100">Earnings by Property</h2>
            {loading ? (
              <div className="flex justify-center items-center h-[300px]">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={Object.values(aggregatedData)}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="property" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="revenue" fill="#8884d8" />
                  <Bar dataKey="count" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>

          {/* Placements by Property */}
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-gray-100">Placements by Property</h2>
            {loading ? (
              <div className="flex justify-center items-center h-[200px]">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <div className="space-y-2">
                {Object.entries(aggregatedData).map(([property, data]: any) => (
                  <div key={property} className="flex justify-between items-center">
                    <span className="text-gray-700 dark:text-gray-300">Property {property}</span>
                    <span className="font-semibold text-gray-900 dark:text-gray-100">{data.count}</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Filterable Table */}
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-gray-100">Property Performance</h2>
            <input
              type="text"
              placeholder="Filter by property name"
              className="w-full p-2 mb-4 border rounded dark:bg-gray-700 dark:text-gray-100"
              value={tableFilter}
              onChange={(e) => setTableFilter(e.target.value)}
            />
            {loading ? (
              <div className="flex justify-center items-center h-[200px]">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-gray-900">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Property</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Revenue</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                    {Object.entries(aggregatedData).map(([property, data]: any) => (
                      <tr key={property}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">Property {property}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">${data.revenue.toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublisherDashboard;