import { useState } from 'react';
import TimePeriodSelector from '../../common/TimePeriodSelector';
import Heading from '../Heading';
import PublisherData from './PublisherData';


export default function Overview() {

  
  const [dateRange, setDateRange] = useState({ name: 'Last Month', value: 'month' });
  const [customStartDate, setCustomStartDate] = useState<Date | undefined>(undefined);
  const [customEndDate, setCustomEndDate] = useState<Date | undefined>(undefined);

  const handleDateRangeChange = (range: { name: string; value: string }, startDate?: Date, endDate?: Date) => {
    setDateRange(range);
    if (range.value === 'custom' && startDate && endDate) {
      setCustomStartDate(startDate);
      setCustomEndDate(endDate);
      // Here you would typically fetch data for the custom date range
      console.log('Custom date range:', startDate, endDate);
    } else {
      setCustomStartDate(undefined);
      setCustomEndDate(undefined);
      // Here you would typically fetch data for the selected preset range
      console.log('Preset range:', range.value);
    }
  };

  return (
    <div>
      <Heading pageHeadingName='Overview'>
         <div className="mb-6">
          <TimePeriodSelector selectedRange={dateRange} onRangeChange={handleDateRangeChange}  />
        </div>
      </Heading>
      <PublisherData />
     
    </div>

  )
  
}


