import React, { createContext, useState, useContext, useEffect } from 'react';

type Mode = 'publisher' | 'advertiser';

interface ModeContextType {
  mode: Mode;
  toggleMode: () => void;
}

const ModeContext = createContext<ModeContextType | undefined>(undefined);

export const ModeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [mode, setMode] = useState<Mode>('publisher');

  useEffect(() => {
    const savedMode = localStorage.getItem('advertiser') as Mode | null;
    if (savedMode) {
      setMode(savedMode);
    }
  }, []);

  useEffect(() => {
    if (mode === 'advertiser') {
      document.documentElement.classList.add('advertiser');
    } else {
      document.documentElement.classList.remove('advertiser');
    }
    localStorage.setItem('mode', mode);
  }, [mode]);

  const toggleMode = () => {
    setMode(prevMode => prevMode === 'publisher' ? 'advertiser' : 'publisher');
  };

  return (
    <ModeContext.Provider value={{ mode, toggleMode }}>
      {children}
    </ModeContext.Provider>
  );
};

export const useMode = () => {
  const context = useContext(ModeContext);
  if (context === undefined) {
    throw new Error('useModee must be used within a ModeProvider');
  }
  return context;
};