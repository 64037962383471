import React, { useState, useEffect } from 'react';
import { Gender, Education } from '../../../bindings/advertiser';
import TargetingSelect from '../../common/TargetingSelect';
import RangeSelector from '../../common/RangeSelector';

interface UserTargetingStepProps {
  onSubmit: (data: any) => void;
  initialData?: any;
}

const UserTargetingStep: React.FC<UserTargetingStepProps> = ({ onSubmit, initialData }) => {
  const [ageRange, setAgeRange] = useState<[number, number] | null>(null);
  const [netWorthRange, setNetWorthRange] = useState<[number, number] | null>(null);
  const [incomeRange, setIncomeRange] = useState<[number, number] | null>(null);
  const [genderInclusions, setGenderInclusions] = useState<string[]>([]);
  const [genderExclusions, setGenderExclusions] = useState<string[]>([]);
  const [educationInclusions, setEducationInclusions] = useState<string[]>([]);
  const [educationExclusions, setEducationExclusions] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (initialData) {
      setAgeRange(initialData.ageRange || null);
      setNetWorthRange(initialData.netWorthRange || null);
      setIncomeRange(initialData.incomeRange || null);
      setGenderInclusions(initialData.genderInclusions?.map((g: Gender) => Object.keys(g)[0]) || []);
      setGenderExclusions(initialData.genderExclusions?.map((g: Gender) => Object.keys(g)[0]) || []);
      setEducationInclusions(initialData.educationInclusions?.map((e: Education) => Object.keys(e)[0]) || []);
      setEducationExclusions(initialData.educationExclusions?.map((e: Education) => Object.keys(e)[0]) || []);
    }
  }, [initialData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await onSubmit({
        ageRange,
        netWorthRange,
        incomeRange,
        genderInclusions: genderInclusions.map(g => ({ [g]: {} } as Gender)),
        genderExclusions: genderExclusions.map(g => ({ [g]: {} } as Gender)),
        educationInclusions: educationInclusions.map(e => ({ [e]: {} } as Education)),
        educationExclusions: educationExclusions.map(e => ({ [e]: {} } as Education)),
      });
    } finally {
      setIsSubmitting(false);
    }
   
  };


 

  const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' },
  ];

  const educationOptions = [
    { value: 'highSchool', label: 'High School' },
    { value: 'bachelor', label: 'Bachelor' },
    { value: 'masters', label: 'Masters' },
    { value: 'phd', label: 'PhD' },
    { value: 'other', label: 'Other' },
  ];

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <RangeSelector
        label="Age Range"
        min={13}
        max={100}
        initialRange={ageRange}
        onChange={setAgeRange}
      />
      <RangeSelector
        label="Net Worth Range"
        min={0}
        max={10000000}
        step={10000}
        initialRange={netWorthRange}
        onChange={setNetWorthRange}
        unit="k"
        scale={1000}
        formatLabel={(value) => `$${value}`}
      />
      <RangeSelector
        label="Income Range"
        min={0}
        max={1000000}
        step={1000}
        initialRange={incomeRange}
        onChange={setIncomeRange}
        unit="k"
        scale={1000}
        formatLabel={(value) => `$${value}`}
      />
      <TargetingSelect
        label="Gender"
        options={genderOptions}
        onInclusionsChange={setGenderInclusions}
        onExclusionsChange={setGenderExclusions}
      />
      <TargetingSelect
        label="Education"
        options={educationOptions}
        onInclusionsChange={setEducationInclusions}
        onExclusionsChange={setEducationExclusions}
      />
      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600 disabled:opacity-50"
      >
        {isSubmitting ? (
          <>
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Submitting...
          </>
        ) : (
          'Next'
        )}
      </button>
    </form>
  );
};

export default UserTargetingStep;