import React, { useState, useCallback, useEffect } from 'react';
import { SiSolana } from 'react-icons/si';
import { useRollupNode } from '../../../context/RollupNodeContext';
import { Link } from 'react-router-dom';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import ExplorerRecentPlacements from './ExplorerRecentPlacements';
import { PublicKey } from '@solana/web3.js';



const ExplorerAccount: React.FC<{ accountPubkey: string }> = ({ accountPubkey }) => {

  const { rollupNode } = useRollupNode();
  const [accountData, setAccountData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchAccountData = useCallback(async (accountPubkey: string) => {
    if (!rollupNode) return;
    
    try {
      setLoading(true);
      // Replace this with your actual account data fetching logic
      const fetchedData = await rollupNode.fetchUnknownAccountData(new PublicKey(accountPubkey));

      setAccountData(fetchedData);
      setError(null);
    } catch (err) {
      setError('Failed to fetch account data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [rollupNode]);

  useEffect(() => {
    fetchAccountData(accountPubkey);
  }, [fetchAccountData, accountPubkey]);

  if (loading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return <NotFound type="account" />;
  }

  return (
    <div className="w-full space-y-6 mt-6">
      {/* Account Identifier and Balance */}
      <AccountInfoDisplay accountPubkey={accountPubkey} accountData={accountData} />

      {/* Account Details */}
      <CollapsibleCard title="Account Info">
        <p>Account Type: {accountData?.accountType ? accountData?.accountType : "Unknown"}</p>
        <pre className="p-2">
          { accountData?.data ? JSON.stringify(accountData?.data, null, 2) : "{}" }
        </pre>
      </CollapsibleCard>

      <ExplorerRecentPlacements accountFilter={accountPubkey} />


    </div>
  );
};

const SkeletonLoader = () => (
  <div className="animate-pulse space-y-6 mt-6">
    <div className="h-20 bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
    <div className="h-40 bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
    <div className="h-40 bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
    <div className="h-40 bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
  </div>
);

const AccountInfoDisplay: React.FC<{ accountPubkey: string, accountData: any}> = ({ accountPubkey, accountData }) => {
  const infoItems = [
    { label: "Account Public Key", value: accountPubkey },
    { label: "Balance", value: `${accountData?.lamports || "0"} SOL` },
    { label: "Owner", value: accountData?.owner.toString() },
  ];

  return (
    <div className="bg-white dark:bg-gray-900 dark:text-white rounded-lg shadow overflow-hidden dark:divide-gray-700 p-4">
      <div className="space-y-3 mb-4">
        {infoItems.map((item, index) => (
          <div key={index} className="flex items-start">
            <div className="w-1/3 text-sm text-gray-800 dark:text-gray-200 font-medium">
              {item.label}:
            </div>
            <div className="w-2/3 text-sm break-all">
              {item.value}
            </div>
          </div>
        ))}
      </div>
      <div className="flex">
        <a
          className="flex items-center bg-indigo-600 dark:bg-indigo-700 text-white hover:bg-indigo-500 dark:hover:bg-indigo-600 rounded px-3 py-2"
          target="_blank"
          href={`https://explorer.solana.com/address/${accountPubkey}?cluster=devnet`}
          rel="noopener noreferrer"
        >
          <SiSolana className="mr-2 flex-shrink-0" />
          View on Solana Explorer
        </a>
      </div>
    </div>
  );
};

const NotFound = ({ type }: { type: 'placement' | 'account' }) => (
  <div className="text-center py-8">
    <h2 className="text-2xl font-bold mb-2">Not Found</h2>
    <p>The requested {type} could not be found.</p>
    <Link to="/explorer" className="text-blue-500 hover:underline mt-4 inline-block">
      Return to Explorer Home
    </Link>
  </div>
);

interface CollapsibleCardProps {
  title: string;
  children: React.ReactNode;
}

const CollapsibleCard: React.FC<CollapsibleCardProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-white dark:bg-gray-900 dark:text-white rounded-lg shadow overflow-hidden dark:divide-gray-700">
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={toggleCard}
      >
        <h2 className="text-xl font-semibold">{title}</h2>
        {isOpen ? (
          <ChevronUpIcon className="w-6 h-6" />
        ) : (
          <ChevronDownIcon className="w-6 h-6" />
        )}
      </div>
      {isOpen && (
        <div className="p-4 border-t border-gray-300">
          {children}
        </div>
      )}
    </div>
  );
};

export default ExplorerAccount;