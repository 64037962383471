import React from 'react';
import { motion } from 'framer-motion';
import { SectionProps } from './types';

interface ExtendedSectionProps extends SectionProps {
  id: string;
}

const Section = React.forwardRef<HTMLDivElement, ExtendedSectionProps>(
  ({ index, currentSection, scrollDirection, children, id }, ref) => {
    const isVisible = index === currentSection;

    return (
      <motion.section
        ref={ref}
        id={id}
        initial={{ opacity: 0, y: scrollDirection === 'down' ? 50 : -50 }}
        animate={{ opacity: isVisible ? 1 : 0, y: 0 }}
        exit={{ opacity: 0, y: scrollDirection === 'down' ? -50 : 50 }}
        transition={{ duration: 0.5 }}
        className="relative h-screen flex items-center justify-center snap-start overflow-y z-20"
      >
        <div className="w-full h-full max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 py-16 z-20 flex items-center justify-center">
          <div className="w-full">
            {children}
          </div>
        </div>
      </motion.section>
    );
  }
);

export default Section;