import React, { useState, useEffect } from 'react';
import KeywordInput from './KeywordInput';

interface KeywordTargetingSelectProps {
  label: string;
  onInclusionsChange: (inclusions: string[]) => void;
  onExclusionsChange: (exclusions: string[]) => void;
}

type TargetingMode = 'none' | 'include' | 'exclude';

const KeywordTargetingSelect: React.FC<KeywordTargetingSelectProps> = ({
  label,
  onInclusionsChange,
  onExclusionsChange,
}) => {
  const [mode, setMode] = useState<TargetingMode>('none');
  const [keywords, setKeywords] = useState<string[]>([]);

  useEffect(() => {
    if (mode === 'none') {
      onInclusionsChange([]);
      onExclusionsChange([]);
    } else if (mode === 'include') {
      onInclusionsChange(keywords);
      onExclusionsChange([]);
    } else if (mode === 'exclude') {
      onInclusionsChange([]);
      onExclusionsChange(keywords);
    }
  }, [mode, keywords, onInclusionsChange, onExclusionsChange]);

  const handleModeChange = (newMode: TargetingMode) => {
    setMode(newMode);
    setKeywords([]);
  };

  return (
    <div className="space-y-4">
      <div>
        <label htmlFor={`targeting-mode-${label}`} className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {label} Targeting
        </label>
        <select
          id={`targeting-mode-${label}`}
          value={mode}
          onChange={(e) => handleModeChange(e.target.value as TargetingMode)}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        >
          <option value="none">No targeting by {label.toLowerCase()}</option>
          <option value="include">Target specific {label.toLowerCase()}</option>
          <option value="exclude">Exclude specific {label.toLowerCase()}</option>
        </select>
      </div>
      {mode !== 'none' && (
        <KeywordInput
          label={`${mode === 'include' ? 'Include' : 'Exclude'} ${label}`}
          keywords={keywords}
          onChange={setKeywords}
        />
      )}
    </div>
  );
};

export default KeywordTargetingSelect;