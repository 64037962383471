import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { ArrowUpCircleIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useIntersectionObserver } from './useIntersectionObserver';
import { HeaderProps } from './types';

const Header = forwardRef<HTMLDivElement, HeaderProps>(({ currentSection, scrollDirection, onChevronClick }, ref) => {
  const [isIntersecting] = useIntersectionObserver({ threshold: 0.5 });

  return (
    <motion.header
      ref={ref}
      initial={{ opacity: 0, y: scrollDirection === 'down' ? 50 : -50 }}
      animate={{ opacity: isIntersecting ? 1 : 0, y: 0 }}
      exit={{ opacity: 0, y: scrollDirection === 'down' ? -50 : 50 }}
      transition={{ duration: 0.5 }}
      className="relative h-screen flex items-center justify-center snap-start z-20"
    >
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center" id="top">
        <img 
          src="/LogoWhiteTransparent.svg" 
          className="lg:w-3/4 mx-auto lg:-mb-20 -mb-8 -mt-8 fade-in"
          alt="Logo"
        />
        <div className="px-8 lg:mx-0">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold tracking-tight mb-4">
            Next generation ad exchange
          </h1>
          <p className="text-xl sm:text-2xl lg:text-3xl max-w-3xl mx-auto">
            Transparent, Open & Privacy Preserving
          </p>
        </div>
        {/* <ChevronDownIcon 
          className="w-8 h-8 mx-auto mt-24 animate-bounce cursor-pointer" 
          onClick={onChevronClick}
        /> */}

        {/* Chevron at the bottom of the viewport */}
        <motion.div
            className="absolute bottom-8  left-1/2 transform -translate-x-1/2 z-50 cursor-pointer"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1 }}
            onClick={onChevronClick}
        >
            <ChevronDownIcon className="h-10 w-10 text-white animate-bounce mx-auto" />
        </motion.div>
      </div>

    </motion.header>   
  );
});

export default Header;