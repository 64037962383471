import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MagnifyingGlassIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Combobox } from '@headlessui/react';
import bs58 from 'bs58';

const isValidBase58 = (input: string): boolean => {
  try {
    return bs58.decode(input).length > 0;
  } catch {
    return false;
  }
};

const isValidSignature = (input: string): boolean => {
  try {
    return bs58.decode(input).length === 64;
  } catch {
    return false;
  }
};

const isValidPubkey = (input: string): boolean => {
  try {
    return bs58.decode(input).length === 32;
  } catch {
    return false;
  }
};

const ExplorerSearchBar: React.FC = () => {
  const [query, setQuery] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [validationType, setValidationType] = useState<'signature' | 'pubkey' | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isValidSignature(query)) {
      setIsValid(true);
      setValidationType('signature');
    } else if (isValidPubkey(query)) {
      setIsValid(true);
      setValidationType('pubkey');
    } else {
      setIsValid(false);
      setValidationType(null);
    }
  }, [query]);

  // Clear search bar when location changes
  useEffect(() => {
    setQuery('');
    setIsValid(false);
    setValidationType(null);
  }, [location]);

  const handleSearch = () => {
    if (validationType === 'signature') {
      navigate(`/dashboard/explorer/placement/${query}`);
    } else if (validationType === 'pubkey') {
      navigate(`/dashboard/explorer/account/${query}`);
    }
  };

  return (
    <div className="relative">
      <Combobox onChange={handleSearch}>
        <Combobox.Input
          type="text"
          placeholder="Search transactions..."
          className="w-full p-4 pl-10 pr-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:bg-gray-900 dark:border-gray-700 dark:text-white dark:placeholder-gray-400"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: string) => item}
          value={query}
        />
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 dark:text-gray-500" />
        </div>
        {isValid && (
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <Combobox.Option
              value={query}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? 'bg-blue-600 text-white' : 'text-gray-900 dark:text-white'
                }`
              }
            >
              {({ selected, active }) => (
                <>
                  <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                    {query}
                  </span>
                  {selected ? (
                    <span
                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                        active ? 'text-white' : 'text-blue-600'
                      }`}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Combobox.Option>
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  );
};

export default ExplorerSearchBar;